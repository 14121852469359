import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Grid,
  Box,
  Tooltip
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { useState, useEffect } from "react";
import AxiosInstance from "../apis/AxiosInstance";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1B4242",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function VideoList() {
  const [video_list, setVideoList] = useState([]);

  const [page, setPage] = useState(1);
  const [totalVideo, setTotalVideo] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    let fetchVideo = async (_) => {
      let videoData = await AxiosInstance.get(`/videos?page=${page}`);
      setTotalVideo(videoData.data.filtered_count);
      let finalVideoData = videoData.data.data;
      if (finalVideoData !== null) {
        setVideoList(finalVideoData);
      }
    };
    fetchVideo();
  }, [page]);

  const totalPages = Math.ceil(totalVideo / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <main >
      <Grid container style={{ marginTop: "4.5%" }}> 
        {/* <h1 style={{ marginLeft: "45%", marginTop: "0%", color: "#081F37" }}>
          Total Video List : {totalVideo} &nbsp;&nbsp;
        </h1> */}
          <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
    
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin:"0 auto"
          }}
        >
          Video List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}  
        >
          Total Count : {totalVideo}
        </h1>
        
      </Box>
      </Grid>

      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 680 : 480 }}
          sx={{
            width: "95%",
            alignItems: "center",
            justifyContent: "center",
            margin: " 0 auto",
            overflowY: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Table  sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Sl.No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Title
                </StyledTableCell>
                {/* <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Edit Video
                </StyledTableCell> */}
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Video Type
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Video Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Date and Time
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Degree Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Stream Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {video_list.length > 0 ? (
                video_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.title === null || val.title === ""
                        ? "N/A"
                        : val.title}
                    </StyledTableCell>
                    {/* <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title="Edit">
                        <Link to="/edit_video" state={val}>
                          <FaEdit
                            fontSize={18}
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell> */}
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.video_type
                        ? val.video_type === 1
                          ? "Addressing"
                          : "Post Addressing"
                        : "NA"}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.video === null || val.video === ""
                        ? "N/A"
                        : val.video}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.date_time === null || val.date_time === ""
                        ? "N/A"
                        : moment(val.date_time)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >                
                      {val.details &&
                      val.details.degree &&
                      val.details.degree.length
                        ? val.details.degree.map((degree) => (
                            <Box style={{ height: "22px" }}>
                              {degree?.["value"]}
                            </Box>
                          ))
                        : ["NA"]}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >                    
                      {val.details &&
                      val.details.stream &&
                      val.details.stream.length
                        ? val.details.stream.map((stream) => (
                            <Box style={{ height: "22px" }}>
                              {stream?.["value"]}
                            </Box>
                          ))
                        : ["NA"]}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={13}>
                    {/* <LinearProgress sx={{ color: "Red" }} /> */}
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "50%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
