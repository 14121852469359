import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDownloadDone } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import Tooltip from "@mui/material/Tooltip";

//====================================

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDE6ED",
    color: "#111",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function GDCreation() {
  const Navigate = useNavigate();
  const [noOfCandidate, setNoOfCandidate] = useState("");
  const [start_date_time, setStart_date_time] = useState("");
  const [questions_per_group, setQuestions_per_group] = useState("");
  const [gd_duration_per_group, setGd_duration_per_group] = useState("");
  const [interval_per_group, setInterval_per_group] = useState("");
  const [is_shortlisted_candidates, setIs_shortlisted_candidates] =
    useState(false);
  const [isShortlistEnabled, setIsShortlistEnabled] = useState(false);
  const [assign_interviewer, setAssign_interviewer] = useState(false);
  const [assign_date, setAssign_date] = useState(false);
  // const[is_Manual,setIs_Manual] = useState(false);
  // const[is_Automatic,setIs_Automatic] = useState(false);
  const [colgOptions, setColgOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColgOptions, setSelectedColgOptions] = useState(null);

  const [addInterviewerOptions, setInterviewerOptions] = useState([]);
  const [interviewer, setInerviewer] = useState([]);
  const [searchInterviewer, setSearchInterviewer] = useState("");

  //Select Rounds
  const [roundsOption, setRoundsOption] = useState([]);
  const [rounds, setRounds] = useState(null);

  //select collegeWise

  const [selectedCollegeWise, setSelectedCollegeWise] = useState([]);
  const [collegeWiseTerm, setCollegeWiseTerm] = useState("");
  const [collegeWiseOptions, setCollegeWiseOptions] = useState([]);

  //college

  const [selectedCollege, setSelectedCollege] = useState([]);
  const [collegeTerm, setCollegeTerm] = useState("");
  const [collegeOption, setCollegeOption] = useState("");

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState("");

  const [gd_list, setGdList] = useState(null);

  // for filtering
  const [appointmentDateFilter, setAppointmentDateFilter] = useState("");

  let handleAppointmentDateFilter = (e) => {
    setAppointmentDateFilter(e.target.value);
  };

  ///Criteria Table
  const [gdCriteria, setGdCriteria] = useState([]);

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  // Rounds
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/college_appointment_rounds`);
      let finalExamTypeData = examTypeData.data.data;
      setRoundsOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      if (collegeWiseTerm !== "") {
        let data = await AxiosInstance.get(
          `/gd_booleans?search_keyword=${collegeWiseTerm}`
        );
        let finalData = data.data.data;
        setCollegeWiseOptions(finalData);
      } else {
        let data = await AxiosInstance.get(`/gd_booleans`);
        let finalData = data.data.data;
        setCollegeWiseOptions(finalData);
      }
    };
    fetchData();
  }, [collegeWiseTerm]);

  //college appointment rounds
  const [criteria, setCriteria] = useState(null);
  useEffect(() => {
    let fetchData = async () => {
      try {
        let gdData = await AxiosInstance.get(
          `/college_appointment_rounds?id=3`
        );
        let finalGdData = gdData.data.data;
        setGdCriteria(finalGdData);
        const criteriaObj = (e) => {
          const obj = {};
          e.forEach((el, ex) => {
            obj[ex] = {
              text: el[0],
              marks: el[1].marks,
              checked: false,
              disabled: true,
              hide: Object.keys(el[1].status)[0] == 2,
            };
          });
          return obj;
        };
        setCriteria(
          finalGdData &&
            finalGdData.length > 0 &&
            finalGdData[0].criteria &&
            Object.keys(finalGdData[0].criteria).length > 0
            ? criteriaObj(Object.entries(finalGdData[0].criteria))
            : null
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleShortListCandidates = (event) => {
    setIs_shortlisted_candidates(event.target.checked);
  };
  const handleRoundsChange = (e) => {
    const selectedRound = e.target.value;
    setRounds(selectedRound);
    setIsShortlistEnabled(selectedRound !== "");
  };

  const handleAssignInterviewer = (event) => {
    setAssign_interviewer(event.target.checked);
  };
  const handleAssignDate = (event) => {
    setAssign_date(event.target.checked);
  };

  // useEffect(() => {
  //   let fetchData2 = async () => {
  //     try {
  //       if (appointmentDateFilter !== "") {
  //         // get all appointments based on addressing date
  //         let allAddressingAppointments = await AxiosInstance.get(
  //           `appointment_mini?appointment_date=${appointmentDateFilter}&search_keyword=${searchTerm}`
  //         );
  //         let finalAppointmentsDate = allAddressingAppointments.data.data;
  //         setColgOptions(finalAppointmentsDate ? finalAppointmentsDate : []);
  //       } else {
  //         // get all appointments based on addressing date
  //         let allAddressingAppointments = await AxiosInstance.get(
  //           `appointment_mini`
  //         );
  //         let finalAppointmentsDate = allAddressingAppointments.data.data;
  //         setColgOptions(finalAppointmentsDate ? finalAppointmentsDate : []);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData2();
  // }, [searchTerm, appointmentDateFilter]);

  useEffect(() => {
    let fetchData2 = async () => {
      try {
        if (appointmentDateFilter !== "") {
          let apiUrl;

          if (searchTerm !== "") {
            // get appointments based on addressing date and search keyword
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&search_keyword=${searchTerm}&limit=10`;
          } else {
            // get appointments based only on addressing date
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&limit=10`;
          }

          let allAddressingAppointments = await AxiosInstance.get(apiUrl);
          let finalAppointmentsDate = allAddressingAppointments.data.data;
          setColgOptions(finalAppointmentsDate ? finalAppointmentsDate : []);
        } else if (searchTerm !== "") {
          // get appointments based only on search keyword
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?search_keyword=${searchTerm}&limit=10`
          );
          let finalAppointmentsSearch = allAddressingAppointments.data.data;
          setColgOptions(
            finalAppointmentsSearch ? finalAppointmentsSearch : []
          );
        } else {
          // get all appointments when neither date nor search term is selected
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?limit=10`
          );
          let finalAppointmentsAll = allAddressingAppointments.data.data;
          setColgOptions(finalAppointmentsAll ? finalAppointmentsAll : []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [searchTerm, appointmentDateFilter]);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchInterviewer !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&search_keyword=${searchInterviewer}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setInterviewerOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setInterviewerOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchInterviewer]);

  useEffect(() => {
    let fetchData2 = async () => {
      if (selectedColgOptions) {
        let degreeData = await AxiosInstance.get(
          // `/appointment_mini?id=${selectedColgOptions.map(
          //   (val) => val.id
          // )}&limit=10`
          `/appointment_mini?id=${selectedColgOptions.id}&limit=10`
        );
        let finaldegreeData = degreeData.data.data;
        if (
          finaldegreeData &&
          finaldegreeData.length > 0 &&
          finaldegreeData[0].colleges &&
          Object.keys(finaldegreeData[0].colleges).length > 0
        ) {
          setCollegeOption(
            Object.entries(finaldegreeData[0].colleges).map((e) => ({
              id: Number(e[0]),
              name: e[1].name,
            }))
          );
        }
      }
    };

    fetchData2();
  }, [selectedColgOptions]);

  useEffect(() => {
    let fetchGD = async (_) => {
      if (selectedColgOptions) {
        try {
          let gdData = await AxiosInstance.get(
            `/gd_group?appointment_id=${selectedColgOptions.id}`
          );
          let finalGdData = gdData.data.data;
          if (finalGdData !== null) {
            const newData = finalGdData[0]?.criteria || null;
            setGdList(newData);
          } else {
            setGdList(null);
          }
        } catch (error) {
          setGdList(null);
          console.log(error);
        }
      }
    };
    fetchGD();
  }, [selectedColgOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    // const isCriteriaSelected = Object.values(criteria).some(
    //   (item) => item.checked
    // );
    // if (!isCriteriaSelected) {
    //   toast.warning("Criteria is required");
    //   return;
    // }

    if (!gd_list) {
      const isCriteriaSelected = Object.values(criteria).some(
        (item) => item.checked
      );
      if (!isCriteriaSelected) {
        toast.warning("Criteria is required");
        return;
      }
    }

    try {
      const gdtDateTime = moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
      // let appointmentData = selectedColgOptions.map(({ id }) => id);
      let collegeData = selectedCollege.map(({ id }) => id);

      const payload = {
        no_of_candidates: Number.parseInt(noOfCandidate),
        interviewer: interviewer.map((value) => value.id),
        start_date_time: assign_date === true ? gdtDateTime : "",
        assign_date: assign_date,
        // appointment_id: appointmentData,
        appointment_id: [selectedColgOptions.id],
        college_id: collegeData,
        questions_per_group: Number.parseInt(questions_per_group),
        is_shortlisted_candidates: is_shortlisted_candidates,
        assign_interviewer: assign_interviewer,
        gd_duration_per_group:
          !assign_date || examTypeList == 1
            ? 0
            : Number.parseInt(gd_duration_per_group),
        interval_per_group:
          !assign_date || examTypeList == 1
            ? 0
            : Number.parseInt(interval_per_group),
        is_offline: examTypeList == 1 ? true : false,
        // all_students: selectedCollegeWise.map(val=>val.id===1?true:false),
        // other_students: selectedCollegeWise.map(val=>val.id===2?true:false),
        // is_college_registered:selectedCollegeWise.map(val=>val.id===3?true:false),
        all_students:
          selectedCollegeWise.find((val) => val.id === 1) !== undefined,
        other_students:
          selectedCollegeWise.find((val) => val.id === 2) !== undefined,
        is_college_registered:
          selectedCollegeWise.find((val) => val.id === 3) !== undefined,

        is_quiz: rounds ? true : false,
        criteria: {},
      };

      // Collect criteria data
      if (!gd_list) {
        Object.keys(criteria).forEach((key) => {
          if (criteria[key].checked) {
            payload.criteria[criteria[key].text.toLowerCase()] = {
              marks: parseInt(criteria[key].marks, 10),
            };
          }
        });
      } else {
        Object.entries(gd_list).forEach((key) => {
          payload.criteria[key[0].toLowerCase()] = {
            marks: parseInt(key[1].marks, 10),
          };
        });
      }

      await AxiosInstance.post("/gd_group", payload, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("GD Created Successfully");
            setTimeout(() => {
              Navigate("/gd_creation_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setRounds(null);
    setIsShortlistEnabled(false);
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "98%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4%",
          background: "#efefef",
        }}
      >
        <Paper
          variant="outlined"
          style={{ border: "2px solid #9BA4B5", borderRadius: "10px" }}
        >
          {/* <Box
              sx={{
                margin: "0 auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                background: "#DDE6ED",
                padding: "8px",
                borderBottom: "2px solid #9BA4B5",
              }}
            >
              <Box
                component={"article"}
                sx={{ fontSize: "22px", fontWeight: "bold" }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: 2,
                    color: "#081f37",
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "center",
                    padding: "10px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                  }}
                >
                  GD Creation
                </Typography>
              </Box>
            </Box> */}
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#081f37",
                fontSize: {
                  xs: "20px", // Smaller font size on small screens
                  sm: "25px", // Medium font size on small to medium screens
                  md: "30px", // Larger font size on medium to large screens
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "1.02px",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              GD CREATION
            </Box>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "1%",
            }}
          >
            <Grid container spacing={1} item={12}>
              <Grid md={2} xs={12}>
                <Item>
                  <TextField
                    label="No Of Candidate"
                    type="tel"
                    fullWidth
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 4 }}
                    name="noOfCandidate"
                    value={noOfCandidate}
                    required
                    onChange={(e) => setNoOfCandidate(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>
              <Grid md={2} xs={12}>
                <Item>
                  <TextField
                    type="datetime-local"
                    fullWidth
                    id="addressingDate"
                    label="Appointment Date"
                    name="addressing_date"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    autoFocus
                    value={appointmentDateFilter}
                    onChange={handleAppointmentDateFilter}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>
              <Grid md={3.65} xs={12}>
                <Item
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                >
                  <Autocomplete
                    options={colgOptions ? colgOptions : []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props} style={{ width: "250%" }}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Appointments"
                        placeholder="Select Appointments"
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                    value={selectedColgOptions}
                    onChange={(e, value) => {
                      setSelectedColgOptions(value);
                    }}
                  />
                </Item>
              </Grid>

              <Grid md={2} xs={12}>
                <Item
                  onChange={(e) => {
                    setCollegeWiseTerm(e.target.value);
                  }}
                >
                  <Autocomplete
                    multiple
                    options={collegeWiseOptions ? collegeWiseOptions : []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props} style={{ width: "100%" }}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={selectedCollegeWise.length === 0}
                        variant="outlined"
                        label="Student Criteria "
                        placeholder="Student Criteria "
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                    value={selectedCollegeWise}
                    onChange={(e, value) => {
                      setSelectedCollegeWise(value);
                    }}
                  />
                </Item>
              </Grid>

              <Grid md={2.3} xs={12}>
                <Item
                  onChange={(e) => {
                    setCollegeTerm(e.target.value);
                  }}
                >
                  <Autocomplete
                    multiple
                    value={selectedCollege}
                    onChange={(event, newValue) => {
                      setSelectedCollege([...newValue]);
                    }}
                    options={collegeOption || []}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          collegeOption && collegeOption.length === 0
                            ? "No options available"
                            : "Select College"
                        }
                        color="warning"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                    disabled={selectedCollegeWise.some(
                      (option) => option.id === 1
                    )}
                  />
                </Item>
              </Grid>

              <Grid
                item
                md={1.95}
                xs={12}
                // style={{ marginTop: "0.5%", marginLeft: "0.7%" }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Rounds</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    value={rounds}
                    label="Rounds"
                    onChange={handleRoundsChange}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 10)", // Default border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                      },
                    }}
                  >
                    {roundsOption.slice(0, 1).map((round) => (
                      <MenuItem key={round.ID} value={round.ID}>
                        {round.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {rounds !== null ? (
                    <Tooltip title="Clear Rounds">
                      <div
                        style={{
                          position: "absolute",
                          top: "17px",
                          left: "170px",
                          color: "#CC7351",
                          cursor: "pointer",
                        }}
                        // className={Styles.refreshTool1}
                      >
                        <TiDelete
                          fontSize={24}
                          title="Reset Status"
                          onClick={resetAppStatus}
                        />
                      </div>
                    </Tooltip>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item md={2.05} xs={12}>
                <Checkbox
                  name="is_shortlisted_candidates"
                  checked={is_shortlisted_candidates}
                  onChange={handleShortListCandidates}
                  disabled={!isShortlistEnabled}
                  id="is_shortlisted_candidates"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="assignInterviewer" style={{ fontSize: "16px" }}>
                  ShortListed Candidates
                </label>
              </Grid>

              <Grid item md={1.8} xs={12}>
                <TextField
                  label="Question Per Group"
                  type="tel"
                  fullWidth
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 3 }}
                  name="questions_per_group"
                  value={questions_per_group}
                  required
                  onChange={(e) => setQuestions_per_group(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item md={1.8} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Mode Of GD
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    value={examTypeList}
                    label="Mode Of GD"
                    required
                    onChange={(e) => {
                      setExamTypeList(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 10)", // Default border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                      },
                    }}
                  >
                    {Object.entries(examTypeListOption).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <Checkbox
                  name="assign_interviewer"
                  required={assign_date}
                  checked={assign_interviewer}
                  onChange={handleAssignInterviewer}
                  id="assign_interviewer"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="assignInterviewer" style={{ fontSize: "16px" }}>
                  Assign Interviewer
                </label>
              </Grid>
              <Grid md={2.4} xs={12}>
                <Item
                  onChange={(e) => {
                    setSearchInterviewer(e.target.value);
                  }}
                >
                  <Autocomplete
                    multiple
                    options={addInterviewerOptions ? addInterviewerOptions : []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.full_name}
                    renderOption={(props, option) => (
                      <li {...props}>{option.full_name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Multiple Interviewer"
                        placeholder="Select Multiple Interviewer"
                        required={interviewer.length === 0}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                    value={interviewer}
                    onChange={(e, value) => {
                      setInerviewer(value);
                    }}
                    disabled={!assign_interviewer}
                  />
                </Item>
              </Grid>

              <Grid item md={2} xs={12}>
                <Checkbox
                  name="assign_date"
                  checked={assign_date}
                  onChange={handleAssignDate}
                  id="assign_date"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="assignInterviewer" style={{ fontSize: "16px" }}>
                  Assign Date
                </label>
              </Grid>

              <Grid item md={2} xs={12}>
                <Item>
                  <TextField
                    name="start_date_time"
                    fullWidth
                    type="datetime-local"
                    id="start_date_time"
                    required
                    value={start_date_time}
                    onChange={(e) => setStart_date_time(e.target.value)}
                    disabled={!assign_date}
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>
              <Grid item md={3.65} xs={12}>
                <Item>
                  <TextField
                    label="GD Duration Per group Enter In Minutes"
                    type="tel"
                    fullWidth
                    disabled={!assign_date || examTypeList == 1}
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    name="gd_duration_per_group"
                    value={gd_duration_per_group}
                    required
                    onChange={(e) => setGd_duration_per_group(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>

              <Grid item md={4.3} xs={12}>
                <Item>
                  <TextField
                    label="Interval per Group Enter In Minutes"
                    type="tel"
                    fullWidth
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    name="interval_per_group"
                    value={interval_per_group}
                    required
                    disabled={!assign_date || examTypeList == 1}
                    onChange={(e) => setInterval_per_group(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                      minWidth: 210,
                    }}
                  />
                </Item>
              </Grid>

              {/* <Grid item md={4}>
                  <Item
                    onChange={(e) => {
                      setSearchInterviewer(e.target.value);
                    }}
                  >
                    <Autocomplete
                      multiple                      
                      options={
                        addInterviewerOptions ? addInterviewerOptions : []
                      }
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.full_name}
                      renderOption={(props, option) => (
                        <li {...props}>{option.full_name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Multiple Interviewer"
                          placeholder="Select Multiple Interviewer"
                          required={interviewer.length === 0}
                        />
                      )}
                      value={interviewer}
                      onChange={(e, value) => {
                        setInerviewer(value);
                      }}
                      disabled={!assign_interviewer}
                    />
                  </Item>
                </Grid> */}

              <TableContainer
                component={Paper}
                sx={{
                  width: "90%",
                  overflowY: "auto",
                  whiteSpace: "nowrap",
                  marginTop: "1%",
                  marginLeft: "5%",
                  // border: "2px solid #9BA4B5",
                  // borderRadius: "10px",
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {!gd_list && (
                        <StyledTableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Select
                        </StyledTableCell>
                      )}
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Criteria
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Marks
                      </StyledTableCell>
                      {!gd_list && (
                        <StyledTableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Edit
                        </StyledTableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gd_list ? (
                      Object.entries(gd_list).map((value, index) => {
                        return (
                          <StyledTableRow key={index + "default criteria"}>
                            {" "}
                            <StyledTableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              <TextField
                                name="criteriaName"
                                fullWidth
                                id="criteriaName"
                                required
                                label="Criteria Name"
                                value={value[0]}
                                disabled
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              <TextField
                                name="criteriaMarks"
                                type="tel"
                                required
                                fullWidth
                                id="criteriaMarks"
                                label="Marks"
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 3 }}
                                value={value[1].marks}
                                disabled
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : criteria ? (
                      Object.entries(criteria).map((value, ind) => (
                        <StyledTableRow
                          sx={{ display: criteria[ind].hide ? "none" : "" }}
                        >
                          <StyledTableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            <Checkbox
                              disableRipple
                              name={criteria[ind].text + ind}
                              checked={criteria[ind].checked}
                              value={ind}
                              onChange={(event) =>
                                setCriteria({
                                  ...criteria,
                                  [ind]: {
                                    ...criteria[ind],
                                    checked: event.target.checked,
                                  },
                                })
                              }
                              style={{
                                padding: "3px 10px 3px 20px",
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            <TextField
                              name="criteriaName"
                              fullWidth
                              id="criteriaName"
                              required
                              label="Criteria Name"
                              value={criteria[ind].text}
                              disabled={criteria[ind].disabled}
                              onChange={(e) => {
                                setCriteria({
                                  ...criteria,
                                  [ind]: {
                                    ...criteria[ind],
                                    text: e.target.value,
                                  },
                                });
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            <TextField
                              name="criteriaMarks"
                              type="tel"
                              required
                              fullWidth
                              id="criteriaMarks"
                              label="Marks"
                              onKeyPress={preventMinus}
                              onPaste={preventPasteNegative}
                              inputProps={{ minLength: 1, maxLength: 3 }}
                              value={criteria[ind].marks}
                              disabled={criteria[ind].disabled}
                              onChange={(e) => {
                                setCriteria({
                                  ...criteria,
                                  [ind]: {
                                    ...criteria[ind],
                                    marks: e.target.value,
                                  },
                                });
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {criteria[ind].disabled ? (
                              <FaEdit
                                fontSize={18}
                                color="#537FE7"
                                style={{
                                  cursor: "pointer",
                                  width: "50px",
                                }}
                                onClick={() => {
                                  setCriteria({
                                    ...criteria,
                                    [ind]: {
                                      ...criteria[ind],
                                      disabled: !criteria[ind].disabled,
                                    },
                                  });
                                }}
                              />
                            ) : (
                              <MdOutlineDownloadDone
                                color="green"
                                fontSize={18}
                                style={{
                                  cursor: "pointer",
                                  width: "50px",
                                }}
                                onClick={() => {
                                  setCriteria({
                                    ...criteria,
                                    [ind]: {
                                      ...criteria[ind],
                                      disabled: !criteria[ind].disabled,
                                    },
                                  });
                                }}
                              />
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={3} align="center">
                          No Data Found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid
                xs={12}
                item
                md={12}
                spacing={2}
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    width: "10%",
                    color: "#F5F5F5",
                    backgroundColor: "#1976d2",
                    fontWeight: "bold",
                  }}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </article>
    </section>
  );
}
