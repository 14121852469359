import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { CgListTree } from "react-icons/cg";
import { PostContext } from "../../bdTree/ContextApi";
import { Box, Button } from "@mui/material";
import { useRef } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import TreeRoundsToShortlist from "../TreeRoundsToShortlist";
import Styles from "./counts.module.css";
import AxiosInstance from "../../../apis/AxiosInstance";
import {
  Grid,
  TextField,
  Autocomplete,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { RiFilterOffFill } from "react-icons/ri";
import Cookies from "js-cookie";
import QuizAttendedList from "../../bd/appointmentList/QuizAttendedList";
import QuizAttendedInAppointment from "../../bd/appointmentList/QuizAttendedInAppointment";
import QuizShortlistedList from "../../bd/appointmentList/QuizShortlistedList";
import GDRejectedListInTree from "../../bd/gdCreation/GDRejectedListInTree";
import GDShortListInTree from "../../bd/gdCreation/GDShortListInTree";
import GDRegisteredListInTree from "../../bd/gdCreation/GDRegisteredListInTree";
import GDJoinedListInTree from "../../bd/gdCreation/GDJoinedListInTree";
import GDNotJoinedListInTree from "../../bd/gdCreation/GDNotJoinedListInTree";
import QuizNotAttendedList from "../../bd/appointmentList/QuizNotAttendedList";
import QuizNotAttendedInAppointment from "../../bd/appointmentList/QuizNotAttendedInAppointment";
import QuizNotSlectedList from "../../bd/appointmentList/QuizNotSlectedList";
import QuizNotSelectedInAppointment from "../../bd/appointmentList/QuizNotSelectedInAppointment";
import QuizShortlistedListInAppointment from "../../bd/appointmentList/QuizShortlistedListInAppointment";
import GDRegisteredListInAppointment from "../../bd/gdCreation/GDRegisteredListInAppointment";
import GDHoldListInTree from "../../bd/gdCreation/GDHoldListInTree";
import GDGroupCountListTree from "../../bd/gdCreation/GDGroupCountListTree";
import GDJoinedListInAppointment from "../../bd/gdCreation/GDJoinedListInAppointment";
import GDShortListInAppointment from "../../bd/gdCreation/GDShortListInAppointment";
import GDRejectedListInAppointmnet from "../../bd/gdCreation/GDRejectedListInAppointmnet";
import GDHoldListInAppointment from "../../bd/gdCreation/GDHoldListInAppointment";
import QuizAttendedOtherCollegeList from "../../bd/appointmentList/QuizAttendedOtherCollegeList";
import QuizAttendedOtherCollegeInAppointment from "../../bd/appointmentList/QuizAttendedOtherCollegeInAppointment";
import GDGroupCountListInAppointment from "../../bd/gdCreation/GDGroupCountListInAppointment";
import FinalShortlist from "../../bd/gdCreation/FinalShortlist";
import FinalRejectedList from "../../bd/gdCreation/FinalRejectedList";
import InterviewRegisteredList from "../../bd/interviewDetails/InterviewRegisteredList";
import InterviewJoinedList from "../../bd/interviewDetails/InterviewJoinedList";
import InterviewNotJoinedList from "../../bd/interviewDetails/InterviewNotJoinedList";
import InterviewOtherCollegeCountList from "../../bd/interviewDetails/InterviewOtherCollegeCountList";
import QuizAttendedPaidList from "../../bd/appointmentList/QuizAttendedPaidList";
import QuizAttendedNotPaidList from "../../bd/appointmentList/QuizAttendedNotPaidList";
import QuizAttendedNotPaidInAppointment from "../../bd/appointmentList/QuizAttendedNotPaidInAppointment";
import QuizRejectedList from "../../bd/appointmentList/QuizRejectedList";
import TreeOrganaisation from "../TreeOrganaisation";
import CollegeTree from "../CollegeTree";
import ContactModal from "../../bd/appointmentList/ContactModal";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:
      role === "1" ? "#EAFDFC" : role === "5" ? "#EDEDED" : "#EAFDFC",
    color: "black",
    border: "none",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

const toIsoFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
};

const CountRoundsToShortlist = () => {
  const meetingID_Ref = useRef(null);
  const {
    expanded,
    country,
    states,
    district,
    colleges,
    setCountry,
    setStates,
    setDistrict,
    setColleges,
    appointments,
    setAppointments,
    setStateURL,
    setExpanded,
    setDistrictURL,
    setCollegeURL,
    setAppointmentDistrictFilter,
    setAppointmentCollegeFilter,
    setCountryURL,
    appointmentStateFilter,
    setAppointmentStateFilter,
    collegeFilter,
    setCollegeFilter,
    setAppointmentURL,
  } = React.useContext(PostContext);

  //=========================================Search Section==============================

  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [collegeData, setCollegeData] = useState(null);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [searchSuccess, setSearchSuccess] = useState(false);

  const ShowSearchError = ($) => {
    let emptySearchTerm =
      selectedColgState !== null ||
      selectedColgDistrict !== null ||
      collegeData !== null ||
      collegeAid !== "" ||
      searchYOP !== "" ||
      unitNameData !== null
        ? false
        : true;
    if (emptySearchTerm) {
      toast.error("Please Select the Items to be filtered", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (searchSuccess) {
      toast.info("Filters applied successfully expand the tree for results", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // dropdown search
  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState("");

  const [collegeAid, setCollegeAid] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [searchYOP, setSearchYOP] = useState("");

  useEffect(() => {
    let fetchData = async (e) => {
      if (stateSearch !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${country[0].id}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
        setSearchSuccess(true);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${country[0].id}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
        setSearchSuccess(true);
      }
    };
    if (country && country.length > 0) {
      fetchData();
    }
  }, [stateSearch, country]);

  const [defaultClg, setDefaultClg] = useState([]);
  //! for college district
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        let colgDistrictData = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        let finalcolgDistrictData = colgDistrictData.data.data;
        setcolgDistrictOptions(finalcolgDistrictData);
        setDefaultClg(finalcolgDistrictData);
        setSearchSuccess(true);
      }
    };
    fetchData();
  }, [selectedColgState]);
  const serachClg = async (e) => {
    try {
      let colgDistrictData = await AxiosInstance.get(
        `/district_mini?search_keyword=${e}&state_id=${selectedColgState.id}&limit=10`
      );
      let finalcolgDistrictData = colgDistrictData.data.data;
      setcolgDistrictOptions(finalcolgDistrictData);
      setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };
  //! for college

  const [defaultdistClg, setDefaultdistClg] = useState([]);
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setCollegeOptions(finalcolgData);
        setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  const searchdistClg = async (e) => {
    try {
      let colgData = await AxiosInstance.get(
        `/college_mini?search_keyword=${e}&district_id=${selectedColgDistrict.id}&limit=10`
      );
      let finalcolgData = colgData.data.data;
      setCollegeOptions(finalcolgData);
      setSearchSuccess(true);
    } catch (error) {
      setCollegeOptions(defaultdistClg);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      window.location.reload();
    });
  }, []);

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    fetchData();
  }, [searchUnitName]);

  // search ends

  //reset  starts
  const resetFilters = async (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setCollegeData(null);
    setAppointmentCollegeFilter(false);
    setAppointmentDistrictFilter(false);
    window.location.href = "/tree_roundsto_shortlist";
  };

  // =======================================================================

  const [columns, setColumns] = React.useState([
    "Appointment Date and Time",
    "Year Of Passout",
    "Spoc Details",
    "Quiz Attended",
    "Quiz Attended Paid List",
    "Quiz Attended Not-Paid List",
    "Quiz Not-Attended",
    "Quiz Shortlisted",
    "Quiz Not-Selected",
    "Quiz Attended Other College Students",
    "Quiz Rejected",
    "Quiz Follow-up",
    "Quiz follow-up Not done",
    "GD Group Count",
    "GD Registered",
    "GD Joined",
    "GD Not Joined",
    "GD ShortListed",
    "GD Rejected",
    "GD Hold",
    "GD Follow-up",
    "GD Follow-up not done",
    "ShortList Follow-up",
    "ShortList Follow-up not done",
    // "Interview Registered",
    // "Interview Joined",
    // "Interview Not-Joined",
    // "Interview Other College Count",
    "Final ShortListed",
    "Final Rejected",
  ]);

  const [expandedState, setExpandedState] = useState([]);
  const [expandedDistrict, setExpandedDistrict] = useState([]);
  const [expandedCollege, setExpandedCollege] = useState([]);
  const [expandedAppointment, setExpandedAppointment] = useState([]);

  useEffect(() => {
    if (expanded.length > 0) {
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let stateArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          !ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (stateArr.length > 0) {
        stateArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr1.push(xid);
        });
        setExpandedState(arr1);
      } else {
        setExpandedState(arr1);
      }
      let distArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (distArr.length > 0) {
        distArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr2.push(xid);
        });
        setExpandedDistrict(arr2);
      } else {
        setExpandedDistrict(arr2);
      }
      let collegeArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (collegeArr.length > 0) {
        collegeArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr3.push(xid);
        });
        setExpandedCollege(arr3);
      } else {
        setExpandedCollege(arr3);
      }
      setExpandedAppointment(
        expanded.filter(
          (ex) =>
            ex.includes("state") &&
            ex.includes("district") &&
            ex.includes("college") &&
            ex.includes("appointment")
        ).length > 0
          ? expanded
              .filter(
                (ex) =>
                  ex.includes("state") &&
                  ex.includes("district") &&
                  ex.includes("college") &&
                  ex.includes("appointment")
              )
              .map((element) => parseInt(element.match(/\d+/)[0]))
          : []
      );
    }
  }, [expanded]);

  // ===============search button====================
  const searchFiltersData = () => {
    if (
      (selectedColgState && selectedColgState.id === 0) ||
      (selectedColgDistrict && selectedColgDistrict.id === 0) ||
      (collegeData && collegeData.id === 0)
    ) {
      toast("Select proper data");
      return;
    }

    const params = [];

    if (selectedColgState) {
      params.push(`state_id=${selectedColgState.id}`);
    }

    if (selectedColgDistrict) {
      params.push(`district_id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params.push(`appointment_yop=${searchYOP}`);
    }

    let queryString = params.length > 0 ? `&${params.join("&")}` : "";

    if (queryString) {
      setCountryURL(`/country?name=india${queryString}`);
    }

    if (
      (selectedColgState && selectedColgState.id === 0) ||
      (selectedColgDistrict && selectedColgDistrict.id === 0) ||
      (collegeData && collegeData.id === 0)
    ) {
      // Display toast message
      toast("Select proper data");
      return;
    }

    const params1 = [];

    if (selectedColgState) {
      params1.push(`id=${selectedColgState.id}`);
    }

    if (selectedColgDistrict) {
      params1.push(`district_id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params1.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params1.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params1.push(`appointment_yop=${searchYOP}`);
    }

    let queryString1 = params1.length > 0 ? `${params1.join("&")}` : "";

    if (queryString1) {
      setStateURL(`/state?${queryString1}`);
    }

    if (
      (selectedColgDistrict && selectedColgDistrict.id === 0) ||
      (collegeData && collegeData.id === 0)
    ) {
      // Display toast message
      toast("Select proper data");
      return;
    }

    const params2 = [];

    if (selectedColgDistrict) {
      params2.push(`id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params2.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params2.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params2.push(`appointment_yop=${searchYOP}`);
    }

    let queryString2 = params2.length > 0 ? `${params2.join("&")}` : "";

    if (queryString2) {
      setDistrictURL(`/district?${queryString2}`);
    }

    if (collegeData && collegeData.id === 0) {
      toast("Select proper data");
      return;
    }

    const params3 = [];

    if (collegeData) {
      params3.push(`id=${collegeData.id}`);
    }

    if (unitNameData) {
      params3.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params3.push(`appointment_yop=${searchYOP}`);
    }

    let queryString3 = params3.length > 0 ? `${params3.join("&")}` : "";

    if (queryString3) {
      setCollegeURL(`/college?${queryString3}`);
    }

    const params4 = [];

    if (unitNameData) {
      params4.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params4.push(`yop=${searchYOP}`);
    }

    let queryString4 = params4.length > 0 ? `${params4.join("&")}` : "";

    if (queryString4) {
      setAppointmentURL(`/appointment?${queryString4}`);
    }
  };

  const searchCollegeData = async () => {
    try {
      const { data } = await AxiosInstance.get(`/college?aid=${collegeAid}`);
      if (data && data.length > 0) {
        setColleges(data.data);
        setAppointments([]);
        setExpanded([]);
        setCountry([]);
        setStates([]);
        setDistrict([]);
        setCollegeFilter(true);
      } else {
        toast.error("No college Data Found");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || "No college Data Found");
    }
  };

  const filterRef = useRef();
  const [margin, setMargin] = useState("");
  useEffect(() => {
    // Function to update element height
    const updateElementHeight = () => {
      if (filterRef.current) {
        const { height } = filterRef.current.getBoundingClientRect();
        setMargin(height);
      }
    };
    updateElementHeight();
    window.addEventListener("resize", updateElementHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateElementHeight);
    };
  }, []);

  return (
    <>
      <section style={{ marginTop: "3.41%" }}>
        <Grid style={{ width: "98%" }} id="searchBlock">
          <Grid
            spacing={1}
            container
            ref={filterRef}
            style={{
              width: "100%",
              alignItems: "center",
              margin: "0 auto",
              gap: "5px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              padding: "15px",
              height: "auto",
              position: "fixed",
              zIndex: "10",
              backgroundColor: "#EEEDEB",
              borderBottom: "2px solid gray",
            }}
          >
            <Grid
              item
              md={1.8}
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={colgStateOptions}
                value={selectedColgState}
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setCollegeData(null);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={selectedColgState ? colgDistrictOptions : []}
                value={selectedColgDistrict}
                name="district_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setCollegeData(null);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    variant="outlined"
                    value={distSearch}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={selectedColgDistrict ? collegeOptions : []}
                value={collegeData}
                name="college_name"
                onChange={(event, newColgdis) => {
                  setCollegeData(newColgdis);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdistClg(e);
                  } else {
                    setCollegeOptions(defaultdistClg);
                  }
                  setCollSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="College"
                    variant="outlined"
                    value={collSearch}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              {selectedColgState || unitNameData ? (
                <TextField
                  size="small"
                  fullWidth
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              ) : (
                <TextField
                  size="small"
                  fullWidth
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              md={1.5}
              onChange={(e) => setSearchUnitName(e.target.value)}
            >
              <Autocomplete
                options={unitNameOption}
                disabled={collegeAid}
                value={unitNameData}
                size="small"
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unit Name"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                size="small"
                fullWidth
                disabled={collegeAid}
                name="searchYOP"
                label="Search YOP"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 4, maxLength: 4 }}
                id="searchYOP"
                value={searchYOP}
                onChange={(e) => {
                  setSearchYOP(e.target.value);
                }}
                InputLabelProps={{
                  style: { fontSize: 16 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderColor: "rgba(0, 0, 0, 10)",
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 5)",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                style={{
                  backgroundColor: "#003285",
                  color: "#fff",
                }}
                onClick={() => {
                  ShowSearchError();
                  if (collegeAid) searchCollegeData();
                  else searchFiltersData();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "0.7%",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={resetFilters}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section
        style={{ width: "100%", marginTop: "3.5%", height: margin }}
      ></section>
      <section
        id="treeStructureRounds"
        style={{ width: "100%", minHeight: "100vh", overflowY: "auto" }}
      >
        <article
          style={{
            width: "100%",
            display: "flex",
            minHeight: "100vh",
            position: "relative",
            // top: "10vh",
          }}
        >
          <aside
            style={{
              width: "40%",
              height: "auto",
            }}
            className="treeBlock"
          >
            <Box
              style={{
                backgroundColor:
                  role === "1"
                    ? "#e2eafc"
                    : role === "5"
                    ? "#F5EDDC"
                    : "#e2eafc",
                padding: "7px",
              }}
            >
              <CgListTree fontSize={35} style={{ marginLeft: "30px" }} />{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#0D4C92",
                  marginLeft: "20px",
                }}
              >
                Rounds To Shortlist
              </span>
            </Box>
            <div>
              {/* <TreeRoundsToShortlist /> */}
              {collegeFilter ? <CollegeTree /> : <TreeRoundsToShortlist />}
              {/* <TreeOrganaisation/> */}
            </div>
          </aside>
          <aside
            style={{
              width: "45%",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
            }}
            className="tableBlock"
          >
            <div
              style={{ width: "100%", minHeight: "100%", whiteSpace: "nowrap" }}
            >
              <TableContainer component={Paper} style={{ minHeight: "99vh" }}>
                <Table sx={{ minWidth: 700 }} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <StyledTableCell key={index}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            id="innerChild"
                          >
                            {column}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {!collegeFilter && country && country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <BootstrapTooltip title="Appointment Date and Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <BootstrapTooltip title="Year Of Passout">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <BootstrapTooltip title="Spoc Details">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_attended_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizAttendedList
                                    // college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    quizAttendedLevel="1"
                                  ></QuizAttendedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_attended_paid_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizAttendedPaidList
                                    appointment_idx={coun.id}
                                    quizAttendedPaidLevel="1"
                                  ></QuizAttendedPaidList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>
                                  {coun.quiz_attended_not_paid_student_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizAttendedNotPaidList
                                    appointment_idx={coun.id}
                                    quizAttendedNotPaidLevel="1"
                                  ></QuizAttendedNotPaidList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_not_attended_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizNotAttendedList
                                    appointment_idx={coun.id}
                                    quizNotAttendedLevel="1"
                                  ></QuizNotAttendedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_shortlisted_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizShortlistedList
                                    appointment_idx={coun.id}
                                    quizShortListLevel="1"
                                  ></QuizShortlistedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_not_selected_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizNotSlectedList
                                    appointment_idx={coun.id}
                                    quizNotSelectedLevel="1"
                                  ></QuizNotSlectedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>
                                  {
                                    coun.quiz_attended_other_college_student_count
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizAttendedOtherCollegeList
                                    appointment_idx={coun.id}
                                    quizAttendedOtherCollege="1"
                                  ></QuizAttendedOtherCollegeList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_rejected_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizRejectedList
                                    appointment_idx={coun.id}
                                    quizRejectedList="1"
                                  ></QuizRejectedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_count === null ||
                                  coun.gd_count === ""
                                    ? 0
                                    : coun.gd_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDGroupCountListTree
                                    appointment_idx={coun.id}
                                    gdGroupCount="1"
                                  ></GDGroupCountListTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_registered_count === null ||
                                  coun.gd_registered_count === ""
                                    ? 0
                                    : coun.gd_registered_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDRegisteredListInTree
                                    appointment_idx={coun.id}
                                    gdRegistered="1"
                                  ></GDRegisteredListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_joined_count === null ||
                                  coun.gd_joined_count === ""
                                    ? 0
                                    : coun.gd_joined_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDJoinedListInTree
                                    appointment_idx={coun.id}
                                    gdJoined="1"
                                  ></GDJoinedListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_not_joined_count === null ||
                                  coun.gd_not_joined_count === ""
                                    ? 0
                                    : coun.gd_not_joined_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDNotJoinedListInTree
                                    appointment_idx={coun.id}
                                    gdNotJoined="1"
                                  ></GDNotJoinedListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_shortlisted_count === null ||
                                  coun.gd_shortlisted_count === ""
                                    ? 0
                                    : coun.gd_shortlisted_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDShortListInTree
                                    appointment_idx={coun.id}
                                    gdShortlist="1"
                                  ></GDShortListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_rejected_count === null ||
                                  coun.gd_rejected_count === ""
                                    ? 0
                                    : coun.gd_rejected_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDRejectedListInTree
                                    appointment_idx={coun.id}
                                    gdRejected="1"
                                  ></GDRejectedListInTree>
                                </p>
                              </p>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_hold_count === null ||
                                  coun.gd_hold_count === ""
                                    ? 0
                                    : coun.gd_hold_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDHoldListInTree
                                    appointment_idx={coun.id}
                                    gdHold="1"
                                  ></GDHoldListInTree>
                                </p>
                              </p>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              -----
                            </StyledTableCell>
                            {/* <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.interview_registered_count === null ||
                                  coun.interview_registered_count === ""
                                    ? 0
                                    : coun.interview_registered_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <InterviewRegisteredList
                                    appointment_idx={coun.id}
                                    interviewRegistered="1"
                                  ></InterviewRegisteredList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.interview_joined_count === null ||
                                  coun.interview_joined_count === ""
                                    ? 0
                                    : coun.interview_joined_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <InterviewJoinedList
                                    appointment_idx={coun.id}
                                    interviewJoined="1"
                                  ></InterviewJoinedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.interview_not_joined_count === null ||
                                  coun.interview_not_joined_count === ""
                                    ? 0
                                    : coun.interview_not_joined_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <InterviewNotJoinedList
                                    appointment_idx={coun.id}
                                    interviewNotJoined="1"
                                  ></InterviewNotJoinedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.interview_other_college_count ===
                                    null ||
                                  coun.interview_other_college_count === ""
                                    ? 0
                                    : coun.interview_other_college_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <InterviewOtherCollegeCountList
                                    appointment_idx={coun.id}
                                    interviewOtherCollege="1"
                                  ></InterviewOtherCollegeCountList>
                                </p>
                              </p>
                            </StyledTableCell> */}
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.final_shortlisted_count === null ||
                                  coun.final_shortlisted_count === ""
                                    ? 0
                                    : coun.final_shortlisted_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <FinalShortlist
                                    appointment_idx={coun.id}
                                    finalShortlist="1"
                                  ></FinalShortlist>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.final_rejected_count === null ||
                                  coun.final_rejected_count === ""
                                    ? 0
                                    : coun.final_rejected_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <FinalRejectedList
                                    appointment_idx={coun.id}
                                    finalReject="1"
                                  ></FinalRejectedList>
                                </p>
                              </p>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}

                      {expanded.length > 0
                        ? states.map((state, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Appointment Date and Time">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Year Of Passout">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Spoc Details">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {state.quiz_attended_student_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizAttendedList
                                            appointment_idx={state.id}
                                            quizAttendedLevel="2"
                                          ></QuizAttendedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {
                                            state.quiz_attended_paid_student_count
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizAttendedPaidList
                                            appointment_idx={state.id}
                                            quizAttendedPaidLevel="2"
                                          ></QuizAttendedPaidList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {
                                            state.quiz_attended_not_paid_student_count
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizAttendedNotPaidList
                                            appointment_idx={state.id}
                                            quizAttendedNotPaidLevel="2"
                                          ></QuizAttendedNotPaidList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>{state.quiz_not_attended_count}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizNotAttendedList
                                            appointment_idx={state.id}
                                            quizNotAttendedLevel="2"
                                          ></QuizNotAttendedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {state.quiz_shortlisted_student_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizShortlistedList
                                            appointment_idx={state.id}
                                            quizShortListLevel="2"
                                          ></QuizShortlistedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <p>
                                        {state.quiz_not_selected_student_count}
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        {" "}
                                        <QuizNotSlectedList
                                          appointment_idx={state.id}
                                          quizNotSelectedLevel="2"
                                        ></QuizNotSlectedList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <p>
                                        {
                                          state.quiz_attended_other_college_student_count
                                        }
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        {" "}
                                        <QuizAttendedOtherCollegeList
                                          appointment_idx={state.id}
                                          quizAttendedOtherCollege="2"
                                        ></QuizAttendedOtherCollegeList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <p>{state.quiz_rejected_student_count}</p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        {" "}
                                        <QuizRejectedList
                                          appointment_idx={state.id}
                                          quizRejectedList="2"
                                        ></QuizRejectedList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Quiz Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Quiz follow-up Not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_count === null ||
                                          state.gd_count === ""
                                            ? 0
                                            : state.gd_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDGroupCountListTree
                                            appointment_idx={state.id}
                                            gdGroupCount="2"
                                          ></GDGroupCountListTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_registered_count === null ||
                                          state.gd_registered_count === ""
                                            ? 0
                                            : state.gd_registered_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDRegisteredListInTree
                                            appointment_idx={state.id}
                                            gdRegistered="2"
                                          ></GDRegisteredListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_joined_count === null ||
                                          state.gd_joined_count === ""
                                            ? 0
                                            : state.gd_joined_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDJoinedListInTree
                                            appointment_idx={state.id}
                                            gdJoined="2"
                                          ></GDJoinedListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_not_joined_count === null ||
                                          state.gd_not_joined_count === ""
                                            ? 0
                                            : state.gd_not_joined_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDNotJoinedListInTree
                                            appointment_idx={state.id}
                                            gdNotJoined="2"
                                          ></GDNotJoinedListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_shortlisted_count ===
                                            null ||
                                          state.gd_shortlisted_count === ""
                                            ? 0
                                            : state.gd_shortlisted_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDShortListInTree
                                            appointment_idx={state.id}
                                            gdShortlist="2"
                                          ></GDShortListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_rejected_count === null ||
                                          state.gd_rejected_count === ""
                                            ? 0
                                            : state.gd_rejected_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDRejectedListInTree
                                            appointment_idx={state.id}
                                            gdRejected="2"
                                          ></GDRejectedListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_hold_count === null ||
                                          state.gd_hold_count === ""
                                            ? 0
                                            : state.gd_hold_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDHoldListInTree
                                            appointment_idx={state.id}
                                            gdHold="2"
                                          ></GDHoldListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="GD Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="GD Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="ShortList Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="ShortList Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.interview_registered_count ===
                                            null ||
                                          state.interview_registered_count ===
                                            ""
                                            ? 0
                                            : state.interview_registered_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <InterviewRegisteredList
                                            appointment_idx={state.id}
                                            interviewRegistered="2"
                                          ></InterviewRegisteredList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.interview_joined_count ===
                                            null ||
                                          state.interview_joined_count === ""
                                            ? 0
                                            : state.interview_joined_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <InterviewJoinedList
                                            appointment_idx={state.id}
                                            interviewJoined="2"
                                          ></InterviewJoinedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.interview_not_joined_count ===
                                            null ||
                                          state.interview_not_joined_count ===
                                            ""
                                            ? 0
                                            : state.interview_not_joined_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <InterviewNotJoinedList
                                            appointment_idx={state.id}
                                            interviewNotJoined="2"
                                          ></InterviewNotJoinedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.interview_other_college_count ===
                                            null ||
                                          state.interview_other_college_count ===
                                            ""
                                            ? 0
                                            : state.interview_other_college_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <InterviewOtherCollegeCountList
                                            appointment_idx={state.id}
                                            interviewOtherCollege="2"
                                          ></InterviewOtherCollegeCountList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell> */}
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.final_shortlisted_count ===
                                            null ||
                                          state.final_shortlisted_count === ""
                                            ? 0
                                            : state.final_shortlisted_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <FinalShortlist
                                            appointment_idx={state.id}
                                            finalShortlist="2"
                                          ></FinalShortlist>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.final_rejected_count ===
                                            null ||
                                          state.final_rejected_count === ""
                                            ? 0
                                            : state.final_rejected_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <FinalRejectedList
                                            appointment_idx={state.id}
                                            finalReject="2"
                                          ></FinalRejectedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == state.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Appointment Date and Time">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Year Of Passout">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Spoc Details">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_attended_student_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizAttendedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizAttendedLevel="3"
                                                      ></QuizAttendedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_attended_paid_student_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizAttendedPaidList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizAttendedPaidLevel="3"
                                                      ></QuizAttendedPaidList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_attended_not_paid_student_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizAttendedNotPaidList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizAttendedNotPaidLevel="3"
                                                      ></QuizAttendedNotPaidList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_not_attended_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizNotAttendedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizNotAttendedLevel="3"
                                                      ></QuizNotAttendedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_shortlisted_student_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizShortlistedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizShortListLevel="3"
                                                      ></QuizShortlistedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <p>
                                                    {
                                                      dist.quiz_not_selected_student_count
                                                    }
                                                  </p>
                                                  &nbsp;&nbsp;
                                                  <p
                                                    style={{ fontSize: "1px" }}
                                                  >
                                                    {" "}
                                                    <QuizNotSlectedList
                                                      appointment_idx={dist.id}
                                                      quizNotSelectedLevel="3"
                                                    ></QuizNotSlectedList>
                                                  </p>
                                                </p>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <p>
                                                    {
                                                      dist.quiz_attended_other_college_student_count
                                                    }
                                                  </p>
                                                  &nbsp;&nbsp;
                                                  <p
                                                    style={{ fontSize: "1px" }}
                                                  >
                                                    {" "}
                                                    <QuizAttendedOtherCollegeList
                                                      appointment_idx={dist.id}
                                                      quizAttendedOtherCollege="3"
                                                    ></QuizAttendedOtherCollegeList>
                                                  </p>
                                                </p>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <p>
                                                    {
                                                      dist.quiz_rejected_student_count
                                                    }
                                                  </p>
                                                  &nbsp;&nbsp;
                                                  <p
                                                    style={{ fontSize: "1px" }}
                                                  >
                                                    {" "}
                                                    <QuizRejectedList
                                                      appointment_idx={dist.id}
                                                      quizRejectedList="3"
                                                    ></QuizRejectedList>
                                                  </p>
                                                </p>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Quiz Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Quiz follow-up Not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_count === null ||
                                                      dist.gd_count === ""
                                                        ? 0
                                                        : dist.gd_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDGroupCountListTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdGroupCount="3"
                                                      ></GDGroupCountListTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_registered_count ===
                                                        null ||
                                                      dist.gd_registered_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_registered_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDRegisteredListInTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdRegistered="3"
                                                      ></GDRegisteredListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_joined_count ===
                                                        null ||
                                                      dist.gd_joined_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_joined_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDJoinedListInTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdJoined="3"
                                                      ></GDJoinedListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_not_joined_count ===
                                                        null ||
                                                      dist.gd_not_joined_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_not_joined_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDNotJoinedListInTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdNotJoined="3"
                                                      ></GDNotJoinedListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_shortlisted_count ===
                                                        null ||
                                                      dist.gd_shortlisted_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_shortlisted_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDShortListInTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdShortlist="3"
                                                      ></GDShortListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_rejected_count ===
                                                        null ||
                                                      dist.gd_rejected_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_rejected_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDRejectedListInTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdRejected="3"
                                                      ></GDRejectedListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_hold_count ===
                                                        null ||
                                                      dist.gd_hold_count === ""
                                                        ? 0
                                                        : dist.gd_hold_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDHoldListInTree
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdHold="3"
                                                      ></GDHoldListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="GD Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="GD Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="ShortList Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="ShortList Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              {/* <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.interview_registered_count ===
                                                        null ||
                                                      dist.interview_registered_count ===
                                                        ""
                                                        ? 0
                                                        : dist.interview_registered_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <InterviewRegisteredList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        interviewRegistered="3"
                                                      ></InterviewRegisteredList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.interview_joined_count ===
                                                        null ||
                                                      dist.interview_joined_count ===
                                                        ""
                                                        ? 0
                                                        : dist.interview_joined_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <InterviewJoinedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        interviewJoined="3"
                                                      ></InterviewJoinedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.interview_not_joined_count ===
                                                        null ||
                                                      dist.interview_not_joined_count ===
                                                        ""
                                                        ? 0
                                                        : dist.interview_not_joined_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <InterviewNotJoinedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        interviewNotJoined="3"
                                                      ></InterviewNotJoinedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.interview_other_college_count ===
                                                        null ||
                                                      dist.interview_other_college_count ===
                                                        ""
                                                        ? 0
                                                        : dist.interview_other_college_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <InterviewOtherCollegeCountList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        interviewOtherCollege="3"
                                                      ></InterviewOtherCollegeCountList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell> */}
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.final_shortlisted_count ===
                                                        null ||
                                                      dist.final_shortlisted_count ===
                                                        ""
                                                        ? 0
                                                        : dist.final_shortlisted_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <FinalShortlist
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        finalShortlist="3"
                                                      ></FinalShortlist>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.final_rejected_count ===
                                                        null ||
                                                      dist.final_rejected_count ===
                                                        ""
                                                        ? 0
                                                        : dist.final_rejected_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <FinalRejectedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        finalReject="3"
                                                      ></FinalRejectedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )
                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Date and Time">
                                                              <p>----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Year Of Passout">
                                                              <p>----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Spoc Details">
                                                              <p>----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_attended_student_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  <QuizAttendedList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizAttendedLevel="4"
                                                                  ></QuizAttendedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_attended_paid_student_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  <QuizAttendedPaidList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizAttendedPaidLevel="4"
                                                                  ></QuizAttendedPaidList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_attended_not_paid_student_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  <QuizAttendedNotPaidList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizAttendedNotPaidLevel="4"
                                                                  ></QuizAttendedNotPaidList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_not_attended_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  <QuizNotAttendedList
                                                                    // college_idx={
                                                                    //   coll?.ids
                                                                    //     ?.college
                                                                    //     .id || 0
                                                                    // }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizNotAttendedLevel="4"
                                                                  ></QuizNotAttendedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_shortlisted_studen_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <QuizShortlistedList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizShortListLevel="4"
                                                                  ></QuizShortlistedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              {" "}
                                                              <p>
                                                                {
                                                                  coll.quiz_not_selected_student_count
                                                                }
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <QuizNotSlectedList
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  quizNotSelectedLevel="4"
                                                                ></QuizNotSlectedList>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              {" "}
                                                              <p>
                                                                {
                                                                  coll.quiz_attended_other_college_student_count
                                                                }
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <QuizAttendedOtherCollegeList
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  quizAttendedOtherCollege="4"
                                                                ></QuizAttendedOtherCollegeList>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              {" "}
                                                              <p>
                                                                {
                                                                  coll.quiz_rejected_student_count
                                                                }
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <QuizRejectedList
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  quizRejectedList="4"
                                                                ></QuizRejectedList>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Quiz Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Quiz follow-up Not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <p>
                                                                {coll.gd_count ===
                                                                  null ||
                                                                coll.gd_count ===
                                                                  ""
                                                                  ? 0
                                                                  : coll.gd_count}
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <GDGroupCountListTree
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  gdGroupCount="4"
                                                                ></GDGroupCountListTree>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_registered_count ===
                                                                    null ||
                                                                  coll.gd_registered_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_registered_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDRegisteredListInTree
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdRegistered="4"
                                                                  ></GDRegisteredListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_joined_count ===
                                                                    null ||
                                                                  coll.gd_joined_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_joined_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDJoinedListInTree
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdJoined="4"
                                                                  ></GDJoinedListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_not_joined_count ===
                                                                    null ||
                                                                  coll.gd_not_joined_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_not_joined_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDNotJoinedListInTree
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdNotJoined="4"
                                                                  ></GDNotJoinedListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_shortlisted_count ===
                                                                    null ||
                                                                  coll.gd_shortlisted_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_shortlisted_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDShortListInTree
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdShortlist="4"
                                                                  ></GDShortListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_rejected_count ===
                                                                    null ||
                                                                  coll.gd_rejected_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_rejected_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDRejectedListInTree
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdRejected="4"
                                                                  ></GDRejectedListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <p>
                                                                {coll.gd_hold_count ===
                                                                  null ||
                                                                coll.gd_hold_count ===
                                                                  ""
                                                                  ? 0
                                                                  : coll.gd_hold_count}
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <GDHoldListInTree
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  gdHold="4"
                                                                ></GDHoldListInTree>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="GD Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="GD Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="ShortList Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="ShortList Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          {/* <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.interview_registered_count ===
                                                                    null ||
                                                                  coll.interview_registered_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.interview_registered_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <InterviewRegisteredList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    interviewRegistered="4"
                                                                  ></InterviewRegisteredList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.interview_joined_count ===
                                                                    null ||
                                                                  coll.interview_joined_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.interview_joined_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <InterviewJoinedList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    interviewJoined="4"
                                                                  ></InterviewJoinedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.interview_not_joined_count ===
                                                                    null ||
                                                                  coll.interview_not_joined_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.interview_not_joined_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <InterviewNotJoinedList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    interviewNotJoined="4"
                                                                  ></InterviewNotJoinedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.interview_other_college_count ===
                                                                    null ||
                                                                  coll.interview_other_college_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.interview_other_college_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <InterviewOtherCollegeCountList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    interviewOtherCollege="4"
                                                                  ></InterviewOtherCollegeCountList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell> */}
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.final_shortlisted_count ===
                                                                    null ||
                                                                  coll.final_shortlisted_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.final_shortlisted_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <FinalShortlist
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    finalShortlist="4"
                                                                  ></FinalShortlist>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.final_rejected_count ===
                                                                    null ||
                                                                  coll.final_rejected_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.final_rejected_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <FinalRejectedList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    finalReject="4"
                                                                  ></FinalRejectedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>

                                                        {appointments != null &&
                                                        expandedCollege.length >
                                                          0 &&
                                                        appointments.filter(
                                                          (aph) =>
                                                            aph.ids.college
                                                              .id == coll.id
                                                        ).length > 0
                                                          ? appointments
                                                              .filter(
                                                                (aph) =>
                                                                  aph.ids
                                                                    .college
                                                                    .id ==
                                                                  coll.id
                                                              )
                                                              .map(
                                                                (
                                                                  apps,
                                                                  indApps
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <TableRow
                                                                        key={
                                                                          indApps +
                                                                          1
                                                                        }
                                                                      >
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Appointment Date and Time">
                                                                            <p>
                                                                              {apps.appointment_date ===
                                                                                null ||
                                                                              apps.appointment_date ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                moment
                                                                                  .utc(
                                                                                    toIsoFormat(
                                                                                      apps.appointment_date
                                                                                    )
                                                                                  )
                                                                                  .local()
                                                                                  .format(
                                                                                    "DD-MM-yyyy hh:mm A"
                                                                                  )
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Year Of Passout">
                                                                            <p>
                                                                              {apps &&
                                                                              apps.year_of_passout
                                                                                ? apps.year_of_passout
                                                                                : "NA"}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p>
                                                                              {" "}
                                                                              {Object.keys(
                                                                                apps.college_spocs
                                                                              )
                                                                                .length <
                                                                              1 ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  No
                                                                                  Spoc
                                                                                </Box>
                                                                              ) : (
                                                                                <>
                                                                                  <ContactModal
                                                                                    mobile={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .mobile
                                                                                    }
                                                                                    email={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .email
                                                                                    }
                                                                                    name={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .name
                                                                                    }
                                                                                  />
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_attended_student_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizAttendedInAppointment
                                                                                  // college_idx={
                                                                                  //   apps
                                                                                  //     .ids
                                                                                  //     ?.college
                                                                                  //     .id
                                                                                  // }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizAttendedInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_attended_paid_student_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizAttendedPaidList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  quizAttendedPaidLevel="5"
                                                                                ></QuizAttendedPaidList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_attended_not_paid_student_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizAttendedNotPaidInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizAttendedNotPaidInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_not_attended_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizNotAttendedInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizNotAttendedInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_shortlisted_student_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                {/* <QuizShortlistedList
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  quizShortListLevel="5"
                                                                                ></QuizShortlistedList> */}
                                                                                <QuizShortlistedListInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizShortlistedListInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_not_selected_student_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizNotSelectedInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizNotSelectedInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.quiz_attended_other_college_student_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizAttendedOtherCollegeInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizAttendedOtherCollegeInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            <p>
                                                                              {apps
                                                                                ?.counts
                                                                                ?.quiz_rejected_student_count ||
                                                                                0}
                                                                            </p>
                                                                            &nbsp;&nbsp;
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "1px",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <QuizRejectedList
                                                                                appointment_idx={
                                                                                  apps.id
                                                                                }
                                                                                quizRejectedList="5"
                                                                              ></QuizRejectedList>
                                                                            </p>
                                                                          </p>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Quiz Follow-up">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Quiz follow-up Not done">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <p>
                                                                              {apps
                                                                                ?.counts
                                                                                ?.group_count ||
                                                                                0}
                                                                            </p>
                                                                            &nbsp;&nbsp;
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "1px",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <GDGroupCountListInAppointment
                                                                                appointment_idx={
                                                                                  apps.id
                                                                                }
                                                                                gdGroupCount="5"
                                                                              ></GDGroupCountListInAppointment>
                                                                            </p>
                                                                          </p>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.gd_registered_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDRegisteredListInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdRegistered="5"
                                                                                ></GDRegisteredListInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.gd_joined_students_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                <GDJoinedListInAppointment
                                                                                  // appointment_idx={
                                                                                  //   apps.group_id &&
                                                                                  //   Object.keys(
                                                                                  //     apps.group_id
                                                                                  //   )
                                                                                  //     ? Object.keys(
                                                                                  //         apps.group_id
                                                                                  //       )[0]
                                                                                  //     : null
                                                                                  // }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdJoined="5"
                                                                                ></GDJoinedListInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.gd_not_joined_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDNotJoinedListInTree
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdNotJoined="5"
                                                                                ></GDNotJoinedListInTree>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.gd_shortlisted_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDShortListInAppointment
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdShortlist="5"
                                                                                ></GDShortListInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.gd_rejected_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDRejectedListInAppointmnet
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                ></GDRejectedListInAppointmnet>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <p>
                                                                              {apps
                                                                                ?.counts
                                                                                ?.gd_hold_count ||
                                                                                0}
                                                                            </p>
                                                                            &nbsp;&nbsp;
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "1px",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <GDHoldListInAppointment
                                                                                appointment_idx={
                                                                                  apps.id
                                                                                }
                                                                                gdHold="5"
                                                                              ></GDHoldListInAppointment>
                                                                            </p>
                                                                          </p>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="GD Follow-up">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="GD Follow-up not done">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="ShortList Follow-up">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="ShortList Follow-up not done">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        {/* <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.interview_registered_count ===
                                                                                  null ||
                                                                                apps.interview_registered_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.interview_registered_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <InterviewRegisteredList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  interviewRegistered="5"
                                                                                ></InterviewRegisteredList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.interview_joined_students_count ===
                                                                                  null ||
                                                                                apps.interview_joined_students_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.interview_joined_students_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                <InterviewJoinedList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  interviewJoined="5"
                                                                                ></InterviewJoinedList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.interview_not_joined_count ===
                                                                                  null ||
                                                                                apps.interview_not_joined_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.interview_not_joined_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                <InterviewNotJoinedList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  interviewNotJoined="5"
                                                                                ></InterviewNotJoinedList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.interview_other_college_count ===
                                                                                  null ||
                                                                                apps.interview_other_college_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.interview_other_college_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                <InterviewOtherCollegeCountList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  interviewOtherCollege="5"
                                                                                ></InterviewOtherCollegeCountList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell> */}
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps &&
                                                                                apps.counts
                                                                                  ? apps
                                                                                      .counts
                                                                                      .final_shortlist_count !==
                                                                                    undefined
                                                                                    ? apps
                                                                                        .counts
                                                                                        .final_shortlist_count
                                                                                    : 0
                                                                                  : 0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <FinalShortlist
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  finalShortlist="5"
                                                                                ></FinalShortlist>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps &&
                                                                                apps.counts
                                                                                  ? apps
                                                                                      .counts
                                                                                      .final_reject_count !==
                                                                                    undefined
                                                                                    ? apps
                                                                                        .counts
                                                                                        .final_reject_count
                                                                                    : 0
                                                                                  : 0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <FinalRejectedList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  finalReject="5"
                                                                                ></FinalRejectedList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                      </TableRow>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : collegeFilter && colleges.length > 0 ? (
                    colleges.map((coll, indColl) => {
                      return (
                        <>
                          <TableRow key={indColl + 1}>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Appointment Date and Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Year Of Passout">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Spoc Details">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coll.quiz_attended_student_count}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    <QuizAttendedList
                                      appointment_idx={coll.id}
                                      quizAttendedLevel="4"
                                    ></QuizAttendedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coll.quiz_attended_paid_student_count}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    <QuizAttendedPaidList
                                      appointment_idx={coll.id}
                                      quizAttendedPaidLevel="4"
                                    ></QuizAttendedPaidList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>
                                    {coll.quiz_attended_not_paid_student_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    <QuizAttendedNotPaidList
                                      appointment_idx={coll.id}
                                      quizAttendedNotPaidLevel="4"
                                    ></QuizAttendedNotPaidList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coll.quiz_not_attended_count}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    <QuizNotAttendedList
                                      // college_idx={
                                      //   coll?.ids
                                      //     ?.college
                                      //     .id || 0
                                      // }
                                      appointment_idx={coll.id}
                                      quizNotAttendedLevel="4"
                                    ></QuizNotAttendedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coll.quiz_shortlisted_studen_count}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <QuizShortlistedList
                                      appointment_idx={coll.id}
                                      quizShortListLevel="4"
                                    ></QuizShortlistedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.collegeStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coll.quiz_not_selected_student_count}</p>
                                &nbsp;&nbsp;
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  {" "}
                                  <QuizNotSlectedList
                                    appointment_idx={coll.id}
                                    quizNotSelectedLevel="4"
                                  ></QuizNotSlectedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.collegeStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>
                                  {
                                    coll.quiz_attended_other_college_student_count
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  {" "}
                                  <QuizAttendedOtherCollegeList
                                    appointment_idx={coll.id}
                                    quizAttendedOtherCollege="4"
                                  ></QuizAttendedOtherCollegeList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coll.quiz_rejected_student_count}</p>
                                &nbsp;&nbsp;
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  {" "}
                                  <QuizRejectedList
                                    appointment_idx={coll.id}
                                    quizRejectedList="4"
                                  ></QuizRejectedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Quiz Follow-up">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Quiz follow-up Not done">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.collegeStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coll.gd_count === null ||
                                  coll.gd_count === ""
                                    ? 0
                                    : coll.gd_count}
                                </p>
                                &nbsp;&nbsp;
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  {" "}
                                  <GDGroupCountListTree
                                    appointment_idx={coll.id}
                                    gdGroupCount="4"
                                  ></GDGroupCountListTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.gd_registered_count === null ||
                                    coll.gd_registered_count === ""
                                      ? 0
                                      : coll.gd_registered_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <GDRegisteredListInTree
                                      appointment_idx={coll.id}
                                      gdRegistered="4"
                                    ></GDRegisteredListInTree>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.gd_joined_count === null ||
                                    coll.gd_joined_count === ""
                                      ? 0
                                      : coll.gd_joined_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <GDJoinedListInTree
                                      appointment_idx={coll.id}
                                      gdJoined="4"
                                    ></GDJoinedListInTree>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.gd_not_joined_count === null ||
                                    coll.gd_not_joined_count === ""
                                      ? 0
                                      : coll.gd_not_joined_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <GDNotJoinedListInTree
                                      appointment_idx={coll.id}
                                      gdNotJoined="4"
                                    ></GDNotJoinedListInTree>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.gd_shortlisted_count === null ||
                                    coll.gd_shortlisted_count === ""
                                      ? 0
                                      : coll.gd_shortlisted_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <GDShortListInTree
                                      appointment_idx={coll.id}
                                      gdShortlist="4"
                                    ></GDShortListInTree>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.gd_rejected_count === null ||
                                    coll.gd_rejected_count === ""
                                      ? 0
                                      : coll.gd_rejected_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <GDRejectedListInTree
                                      appointment_idx={coll.id}
                                      gdRejected="4"
                                    ></GDRejectedListInTree>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell className={Styles.collegeStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coll.gd_hold_count === null ||
                                  coll.gd_hold_count === ""
                                    ? 0
                                    : coll.gd_hold_count}
                                </p>
                                &nbsp;&nbsp;
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  {" "}
                                  <GDHoldListInTree
                                    appointment_idx={coll.id}
                                    gdHold="4"
                                  ></GDHoldListInTree>
                                </p>
                              </p>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="GD Follow-up">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="GD Follow-up not done">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="ShortList Follow-up">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="ShortList Follow-up not done">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            {/* <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.interview_registered_count === null ||
                                    coll.interview_registered_count === ""
                                      ? 0
                                      : coll.interview_registered_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <InterviewRegisteredList
                                      appointment_idx={coll.id}
                                      interviewRegistered="4"
                                    ></InterviewRegisteredList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.interview_joined_count === null ||
                                    coll.interview_joined_count === ""
                                      ? 0
                                      : coll.interview_joined_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <InterviewJoinedList
                                      appointment_idx={coll.id}
                                      interviewJoined="4"
                                    ></InterviewJoinedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.interview_not_joined_count === null ||
                                    coll.interview_not_joined_count === ""
                                      ? 0
                                      : coll.interview_not_joined_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <InterviewNotJoinedList
                                      appointment_idx={coll.id}
                                      interviewNotJoined="4"
                                    ></InterviewNotJoinedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.interview_other_college_count ===
                                      null ||
                                    coll.interview_other_college_count === ""
                                      ? 0
                                      : coll.interview_other_college_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <InterviewOtherCollegeCountList
                                      appointment_idx={coll.id}
                                      interviewOtherCollege="4"
                                    ></InterviewOtherCollegeCountList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell> */}
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.final_shortlisted_count === null ||
                                    coll.final_shortlisted_count === ""
                                      ? 0
                                      : coll.final_shortlisted_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <FinalShortlist
                                      appointment_idx={coll.id}
                                      finalShortlist="4"
                                    ></FinalShortlist>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.final_rejected_count === null ||
                                    coll.final_rejected_count === ""
                                      ? 0
                                      : coll.final_rejected_count}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <FinalRejectedList
                                      appointment_idx={coll.id}
                                      finalReject="4"
                                    ></FinalRejectedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>

                          {appointments != null && appointments.length > 0
                            ? appointments.map((apps, indApps) => {
                                return (
                                  <>
                                    <TableRow key={indApps + 1}>
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Appointment Date and Time">
                                          <p>
                                            {apps.appointment_date === null ||
                                            apps.appointment_date === "" ? (
                                              <Box
                                                sx={{
                                                  color: "#EB455F",
                                                }}
                                              >
                                                NA
                                              </Box>
                                            ) : (
                                              moment
                                                .utc(
                                                  toIsoFormat(
                                                    apps.appointment_date
                                                  )
                                                )
                                                .local()
                                                .format("DD-MM-yyyy hh:mm A")
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Year Of Passout">
                                          <p>
                                            {apps && apps.year_of_passout
                                              ? apps.year_of_passout
                                              : "NA"}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p>
                                            {" "}
                                            {Object.keys(apps.college_spocs)
                                              .length < 1 ? (
                                              <Box
                                                sx={{
                                                  color: "#EB455F",
                                                }}
                                              >
                                                No Spoc
                                              </Box>
                                            ) : (
                                              <>
                                                <ContactModal
                                                  mobile={
                                                    Object.entries(
                                                      Object.values(
                                                        apps.college_spocs
                                                      )
                                                    )[0][1].mobile
                                                  }
                                                  email={
                                                    Object.entries(
                                                      Object.values(
                                                        apps.college_spocs
                                                      )
                                                    )[0][1].email
                                                  }
                                                  name={
                                                    Object.entries(
                                                      Object.values(
                                                        apps.college_spocs
                                                      )
                                                    )[0][1].name
                                                  }
                                                />
                                              </>
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_attended_student_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <QuizAttendedInAppointment
                                                // college_idx={
                                                //   apps
                                                //     .ids
                                                //     ?.college
                                                //     .id
                                                // }
                                                appointment_idx={apps.id}
                                                addressing_idx={
                                                  apps.addressing_id
                                                }
                                              ></QuizAttendedInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_attended_paid_student_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <QuizAttendedPaidList
                                                appointment_idx={apps.id}
                                                quizAttendedPaidLevel="5"
                                              ></QuizAttendedPaidList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_attended_not_paid_student_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <QuizAttendedNotPaidInAppointment
                                                appointment_idx={apps.id}
                                                addressing_idx={
                                                  apps.addressing_id
                                                }
                                              ></QuizAttendedNotPaidInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_not_attended_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <QuizNotAttendedInAppointment
                                                appointment_idx={apps.id}
                                                addressing_idx={
                                                  apps.addressing_id
                                                }
                                              ></QuizNotAttendedInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_shortlisted_student_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              {/* <QuizShortlistedList
                                                    college_idx={
                                                      apps
                                                        .ids
                                                        ?.college
                                                        .id
                                                    }
                                                    appointment_idx={
                                                      apps.id
                                                    }
                                                    quizShortListLevel="5"
                                                  ></QuizShortlistedList> */}
                                              <QuizShortlistedListInAppointment
                                                appointment_idx={apps.id}
                                                addressing_idx={
                                                  apps.addressing_id
                                                }
                                              ></QuizShortlistedListInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.appointmentStyle}
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_not_selected_student_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <QuizNotSelectedInAppointment
                                                appointment_idx={apps.id}
                                                addressing_idx={
                                                  apps.addressing_id
                                                }
                                              ></QuizNotSelectedInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.appointmentStyle}
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.quiz_attended_other_college_student_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <QuizAttendedOtherCollegeInAppointment
                                                appointment_idx={apps.id}
                                                addressing_idx={
                                                  apps.addressing_id
                                                }
                                              ></QuizAttendedOtherCollegeInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {" "}
                                          <p>
                                            {apps?.counts
                                              ?.quiz_rejected_student_count ||
                                              0}
                                          </p>
                                          &nbsp;&nbsp;
                                          <p
                                            style={{
                                              fontSize: "1px",
                                            }}
                                          >
                                            {" "}
                                            <QuizRejectedList
                                              appointment_idx={apps.id}
                                              quizRejectedList="5"
                                            ></QuizRejectedList>
                                          </p>
                                        </p>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Quiz Follow-up">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Quiz follow-up Not done">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <p>
                                            {apps?.counts?.group_count || 0}
                                          </p>
                                          &nbsp;&nbsp;
                                          <p
                                            style={{
                                              fontSize: "1px",
                                            }}
                                          >
                                            {" "}
                                            <GDGroupCountListInAppointment
                                              appointment_idx={apps.id}
                                              gdGroupCount="5"
                                            ></GDGroupCountListInAppointment>
                                          </p>
                                        </p>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.gd_registered_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <GDRegisteredListInAppointment
                                                appointment_idx={apps.id}
                                                gdRegistered="5"
                                              ></GDRegisteredListInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.gd_joined_students_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              <GDJoinedListInAppointment
                                                // appointment_idx={
                                                //   apps.group_id &&
                                                //   Object.keys(
                                                //     apps.group_id
                                                //   )
                                                //     ? Object.keys(
                                                //         apps.group_id
                                                //       )[0]
                                                //     : null
                                                // }
                                                appointment_idx={apps.id}
                                                gdJoined="5"
                                              ></GDJoinedListInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.gd_not_joined_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <GDNotJoinedListInTree
                                                appointment_idx={apps.id}
                                                gdNotJoined="5"
                                              ></GDNotJoinedListInTree>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.gd_shortlisted_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <GDShortListInAppointment
                                                appointment_idx={apps.id}
                                                gdShortlist="5"
                                              ></GDShortListInAppointment>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.gd_rejected_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <GDRejectedListInAppointmnet
                                                appointment_idx={apps.id}
                                              ></GDRejectedListInAppointmnet>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <p>
                                            {apps?.counts?.gd_hold_count || 0}
                                          </p>
                                          &nbsp;&nbsp;
                                          <p
                                            style={{
                                              fontSize: "1px",
                                            }}
                                          >
                                            {" "}
                                            <GDHoldListInAppointment
                                              appointment_idx={apps.id}
                                              gdHold="5"
                                            ></GDHoldListInAppointment>
                                          </p>
                                        </p>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="GD Follow-up">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="GD Follow-up not done">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="ShortList Follow-up">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="ShortList Follow-up not done">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      {/* <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps.interview_registered_count ===
                                                null ||
                                              apps.interview_registered_count ===
                                                ""
                                                ? 0
                                                : apps.interview_registered_count}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <InterviewRegisteredList
                                                appointment_idx={apps.id}
                                                interviewRegistered="5"
                                              ></InterviewRegisteredList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps.interview_joined_students_count ===
                                                null ||
                                              apps.interview_joined_students_count ===
                                                ""
                                                ? 0
                                                : apps.interview_joined_students_count}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              <InterviewJoinedList
                                                appointment_idx={apps.id}
                                                interviewJoined="5"
                                              ></InterviewJoinedList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps.interview_not_joined_count ===
                                                null ||
                                              apps.interview_not_joined_count ===
                                                ""
                                                ? 0
                                                : apps.interview_not_joined_count}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              <InterviewNotJoinedList
                                                appointment_idx={apps.id}
                                                interviewNotJoined="5"
                                              ></InterviewNotJoinedList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps.interview_other_college_count ===
                                                null ||
                                              apps.interview_other_college_count ===
                                                ""
                                                ? 0
                                                : apps.interview_other_college_count}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              <InterviewOtherCollegeCountList
                                                appointment_idx={apps.id}
                                                interviewOtherCollege="5"
                                              ></InterviewOtherCollegeCountList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell> */}
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps && apps.counts
                                                ? apps.counts
                                                    .final_shortlist_count !==
                                                  undefined
                                                  ? apps.counts
                                                      .final_shortlist_count
                                                  : 0
                                                : 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <FinalShortlist
                                                appointment_idx={apps.id}
                                                finalShortlist="5"
                                              ></FinalShortlist>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps && apps.counts
                                                ? apps.counts
                                                    .final_reject_count !==
                                                  undefined
                                                  ? apps.counts
                                                      .final_reject_count
                                                  : 0
                                                : 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <FinalRejectedList
                                                appointment_idx={apps.id}
                                                finalReject="5"
                                              ></FinalRejectedList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        {/* <h4 style={{ color: "red" }}>Loading Table Data...</h4> */}
                        <h4 style={{ color: "red" }}>No Data Found...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
          <aside style={{ width: "15%" }} className="actionBlock">
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {!collegeFilter && country && country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p>NA</p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p>NA</p>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                      {expanded.length > 0
                        ? states.map((stat, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Status">
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip title="Action">
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == stat.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Status">
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Action">
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )

                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Status">
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Action">
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>
                                                        {appointments != null &&
                                                        appointments.length > 0
                                                          ? appointments.map(
                                                              (
                                                                apps,
                                                                indApps
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <TableRow
                                                                      key={
                                                                        indApps +
                                                                        1
                                                                      }
                                                                    >
                                                                      <StyledTableCell
                                                                        className={
                                                                          Styles.roundsToShortAppointment
                                                                        }
                                                                      >
                                                                        <BootstrapTooltip title="Status">
                                                                          <p>
                                                                            NA
                                                                          </p>
                                                                        </BootstrapTooltip>
                                                                      </StyledTableCell>

                                                                      <StyledTableCell
                                                                        className={
                                                                          Styles.roundsToShortAppointment
                                                                        }
                                                                      >
                                                                        <BootstrapTooltip title="Status">
                                                                          <p>
                                                                            NA
                                                                          </p>
                                                                        </BootstrapTooltip>
                                                                      </StyledTableCell>
                                                                    </TableRow>
                                                                  </>
                                                                );
                                                              }
                                                            )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : collegeFilter && colleges.length > 0 ? (
                    colleges.map((coll, indColl) => {
                      return (
                        <>
                          <TableRow key={indColl + 1}>
                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Status">
                                <p>NA</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.roundsToShortCollege}
                            >
                              <BootstrapTooltip title="Action">
                                <p>NA</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>
                          {appointments != null && appointments.length > 0
                            ? appointments.map((apps, indApps) => {
                                return (
                                  <>
                                    <TableRow key={indApps + 1}>
                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Status">
                                          <p>NA</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.roundsToShortAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Status">
                                          <p>NA</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        {/* <h4 style={{ color: "red" }}>Loading Table Data...</h4> */}
                        <h4 style={{ color: "red" }}>No Data Found...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};
export default CountRoundsToShortlist;
