import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import { FormControl } from "@material-ui/core";
import { toast } from "react-toastify";
import AxiosInstance from "../../../apis/AxiosInstance";
import moment from "moment/moment";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const removeDuplicates = (arr) => {
  const newArr = [];
  const oldArr = [...arr];
  oldArr.filter((e) => {
    if (newArr.indexOf(e) === -1) {
      newArr.push(e);
    }
  });
  return newArr;
};
const PostAddressing = () => {
  let navigate = useNavigate();
  // state values
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");

  const [is_broadcaster, setIs_broadcaster] = useState(false);
  const [send_email, setSend_email] = useState(false);

  // all appointments
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColgOptions, setSelectedColgOptions] = useState([]);
  const [colgOptions, setColgOptions] = useState([]);

  const [addFacultyOptions, setAddFacultyOptions] = useState([]);
  const [addressing_faculty, setAddressing_faculty] = useState([]);
  const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState("");

  const [selectedDegree, setselectedDegree] = useState([]);
  const [degTerm, setDegTerm] = useState("");
  const [degreeOptions, setDegreeOptions] = useState("");
  const [selectedStream, setselectedStream] = useState([]);
  const [streamTerm, setStreamTerm] = useState(null);
  const [streamOptions, setStreamOptions] = useState("");

  const [seletedPreviousVideo, setSeletedPreviousVideo] = useState(null);
  const [searchVideo, setSearchVideo] = useState("");
  const [previousVideoOption, setPreviousVideoOption] = useState([]);

  // for filtering
  const [appointmentDateFilter, setAppointmentDateFilter] = useState("");

  let handleAppointmentDateFilter = (e) => {
    setAppointmentDateFilter(e.target.value);
  };

  const [error, setError] = useState(false);
  const handleAutocompleteChange = (e, value) => {
    setAddressing_faculty(value);
    setError(value.length === 0);
  };

  // let handleSubmit = async (ex) => {
  //   ex.preventDefault();
  //   const parseLog = (a) => {
  //     const userData = a ? JSON.parse(a) : { token: "" };
  //     const { token } = userData;
  //     return token;
  //   };
  //   const token = parseLog(Cookies.get("logged"));
  //   try {
  //     const startDateTime = moment(start_date)
  //       .utc()
  //       .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

  //     const endDateTime = moment(end_date)
  //       .utc()
  //       .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

  //     if (moment(endDateTime).isSameOrAfter(moment(startDateTime))) {
  //       const obj = {};
  //       obj[seletedPreviousVideo.id] = {
  //         name: seletedPreviousVideo.title,
  //         start_date: startDateTime,
  //         end_date: endDateTime,
  //       };

  //       if (previousVideos.length > 0) {
  //         previousVideos.forEach((e, ind) => {
  //           if (moment(e.enddate).isSameOrAfter(moment(e.date))) {
  //             obj[e.id] = {
  //               name: e.video,
  //               start_date: moment(e.date)
  //                 .utc()
  //                 .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
  //               end_date: moment(e.enddate)
  //                 .utc()
  //                 .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
  //             };
  //           } else {
  //             toast.error(
  //               "End date must be greater than start date for videos"
  //             );
  //             return;
  //           }
  //         });
  //       }

  //       const payload = {
  //         appointment_id: selectedColgOptions.map((e) => e.id),
  //         addressing_faculty_id: addressing_faculty.map(
  //           (faculty) => faculty.id
  //         ),
  //         send_email: send_email,
  //         is_broadcaster: is_broadcaster,
  //         is_offline: examTypeList === "1" ? true : false,
  //         videos: obj,
  //       };

  //       await AxiosInstance.post("/post_addressing", payload, {
  //         "content-type": "multipart/form-data",
  //         Authorization: `${token}`,
  //       })
  //         .then((response) => {
  //           toast.success("Successfully Post Addressing Created");
  //           if (response.status == 200) {
  //             setTimeout(() => {
  //               navigate("/");
  //             }, 1000);
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error.response.data.error);
  //         });
  //     } else {
  //       toast.error("End date must be greater than start date");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let handleSubmit = async (ex) => {
    ex.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));
    try {
      const startDateTime = moment(start_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

      const endDateTime = moment(end_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

      const obj = {};
      obj[seletedPreviousVideo.id] = {
        name: seletedPreviousVideo.title,
        start_date: startDateTime,
        end_date: endDateTime,
      };

      if (previousVideos.length > 0) {
        previousVideos.forEach((e, ind) => {
          obj[e.id] = {
            name: e.video,
            start_date: moment(e.date)
              .utc()
              .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
            end_date: moment(e.enddate)
              .utc()
              .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
          };
        });
      }

      const payload = {
        appointment_id: selectedColgOptions.map((e) => e.id),
        addressing_faculty_id: addressing_faculty.map((faculty) => faculty.id),
        send_email: send_email,
        is_broadcaster: is_broadcaster,
        is_offline: examTypeList === "1" ? true : false,
        videos: obj,
      };

      await AxiosInstance.post("/post_addressing", payload, {
        "content-type": "multipart/form-data",
        Authorization: `${token}`,
      })
        .then((response) => {
          toast.success("Onboarding Successfully Created");
          if (response.status == 200) {
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //! ================================================API CALLS=============================================================

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchAddressingFaculty !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setAddFacultyOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setAddFacultyOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchAddressingFaculty]);

  useEffect(() => {
    let fetchData2 = async () => {
      try {
        if (appointmentDateFilter !== "") {
          let apiUrl;

          if (searchTerm !== "") {
            // get appointments based on addressing date and search keyword
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&search_keyword=${searchTerm}&limit=10`;
          } else {
            // get appointments based only on addressing date
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&limit=10`;
          }

          let allAddressingAppointments = await AxiosInstance.get(apiUrl);
          let finalAppointmentsDate = allAddressingAppointments.data.data;
          setColgOptions(finalAppointmentsDate ? finalAppointmentsDate : []);
        } else if (searchTerm !== "") {
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?search_keyword=${searchTerm}&limit=10`
          );
          let finalAppointmentsSearch = allAddressingAppointments.data.data;
          setColgOptions(
            finalAppointmentsSearch ? finalAppointmentsSearch : []
          );
        } else {
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?limit=10`
          );
          let finalAppointmentsAll = allAddressingAppointments.data.data;
          setColgOptions(finalAppointmentsAll ? finalAppointmentsAll : []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [searchTerm, appointmentDateFilter]);

  useEffect(() => {
    let appointmentID = selectedColgOptions.map((val) => val.id);
    let fetchData = async () => {
      let { data } = await AxiosInstance.get(
        `/appointment?id=${appointmentID.join("&id=")}&limit=10`
      );
      let finalData = data.data;
      let degree = [];
      let stream = [];
      finalData.forEach((e) => {
        let obj1 = e.ids.degree;
        let obj2 = e.ids.stream;
        if (e.ids) {
          if (obj1 && Object.keys(obj1).length > 0) {
            const keys = Object.keys(obj1);
            const values = Object.values(obj1);
            keys.forEach((key, ind) => {
              degree.push({ ...values[ind], id: Number(key) });
            });
          }
          if (obj2 && Object.keys(obj2).length > 0) {
            const keys = Object.keys(obj2);
            const values = Object.values(obj2);
            keys.forEach((key, ind) => {
              stream.push({ ...values[ind], id: Number(key) });
            });
          }
        }
      });
      setselectedStream(stream.length > 0 ? removeDuplicates(stream) : []);
      setselectedDegree(degree.length > 0 ? removeDuplicates(degree) : []);
    };
    if (appointmentID.length > 0) {
      fetchData();
    } else {
      setselectedStream([]);
      setselectedDegree([]);
    }
  }, [selectedColgOptions]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (degTerm !== "") {
        // college Degree api
        let degreeData = await AxiosInstance.get(
          `/degree_types?search_keyword=${degTerm}&limit=10`
        );
        let finaldegreeData = degreeData.data.data;

        setDegreeOptions(finaldegreeData);
      } else {
        // college Degree api
        let degreeData = await AxiosInstance.get(`/degree_types?limit=10`);
        let finaldegreeData = degreeData.data.data;
        setDegreeOptions(finaldegreeData);
      }
    };
    fetchData1();
  }, [degTerm]);

  const [url, setUrl] = useState("/academic_streams");

  useEffect(() => {
    let fetchData2 = async () => {
      let queryParams = selectedDegree.map((value, i) => {
        return `degree_id=${value.id}`;
      });

      const degreeQueryString =
        queryParams.length > 0 ? queryParams.join("&") : "";

      const finalUrl =
        degreeQueryString.length > 0 ? `${url}?${degreeQueryString}` : url;

      try {
        let streamData;

        if (streamTerm !== null) {
          streamData = await AxiosInstance.get(
            `${finalUrl}&search_keyword=${streamTerm}`
          );
        } else {
          streamData = await AxiosInstance.get(finalUrl);
        }

        let finalstreamData = streamData.data.data;

        if (finalstreamData) {
          setStreamOptions(finalstreamData);
        }
      } catch (error) {
        // Handle the error, e.g., log it or show a user-friendly message
        console.error("Error fetching stream data:", error);
      }
    };

    fetchData2();
  }, [streamTerm, selectedDegree]);

  useEffect(() => {
    let fetchData = async (e) => {
      const stream =
        selectedStream.length > 0
          ? `&stream_id=${selectedStream.map((e) => e.id).join("&stream_id=")}`
          : "";
      const degree =
        selectedDegree.length > 0
          ? `&degree_id=${selectedDegree.map((e) => e.id).join("&degree_id=")}`
          : "";
      if (searchVideo) {
        let colgStateData = await AxiosInstance.get(
          `/videos?search_keyword=${searchVideo}&limit=10${stream}${degree}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setPreviousVideoOption(finalcolgStateData);
      } else {
        let colgStateData = await AxiosInstance.get(
          `/videos?limit=10${stream}${degree}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setPreviousVideoOption(finalcolgStateData);
      }
    };
    if (selectedDegree.length > 0 || selectedStream.length > 0) {
      fetchData();
    }
  }, [searchVideo, selectedDegree, selectedStream]);

  const [defaultVideo, setDefaultVideo] = useState("");
  useEffect(() => {
    let fetchData = async () => {
      if (searchVideo !== "") {
        try {
          let { data } = await AxiosInstance.get(
            `/videos?search_keyword=${searchVideo}&default_video`
          );
          setDefaultVideo(data.data);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let { data } = await AxiosInstance.get(`/videos?default_video`);
          setDefaultVideo(data.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [searchVideo]);
  //! ================================================API CALLS=============================================================

  //checkbox
  const handleSendEmailChange1 = (event) => {
    setSend_email(event.target.checked);
  };
  const handleBroadCaster = (event) => {
    setIs_broadcaster(event.target.checked);
  };
  const [previousVideos, setPreviousVideos] = useState([]);
  return (
    <div>
      <section style={{ width: "100%", height: "auto" }}>
        <article
          style={{
            width: "98%",
            height: "auto",
            margin: "0 auto",
            marginTop: "4.5%",
            border:"1px solid gray"
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
             
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#081f37",
                fontSize: {
                  xs: "20px", // Smaller font size on small screens
                  sm: "25px", // Medium font size on small to medium screens
                  md: "30px", // Larger font size on medium to large screens
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "1.02px",
                justifyContent: "center",
                margin:"0 auto"
              }}
            >
              CREATE ONBOARDING
            </Box>
          </Box>
          <Paper variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={1} sx={{ padding: 1 }}>
                  <form onSubmit={handleSubmit}>
                    <Box
                      component={"section"}
                      sx={{
                        boxShadow:
                          " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                        padding: "5px",
                        marginBottom: "10px",
                        outline: "1px solid #D6E4E5",
                        borderLeft: "6px solid #9BA4B5",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          marginBottom: 1,
                          fontWeight: "bold",
                          color: "#00337C",
                        }}
                      >
                        All Appointments
                      </Typography>

                      <Grid container spacing={1}>
                        {/* <Grid
                            item
                            xs={3}
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              padding: "12px",
                            }}
                          >
                            Appointment Date :
                          </Grid> */}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            type="datetime-local"
                            fullWidth
                            id="addressingDate"
                            label="Appointment Date"
                            name="addressing_date"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            autoFocus
                            value={appointmentDateFilter}
                            onChange={handleAppointmentDateFilter}
                            // inputProps={{
                            //   min: new Date().toISOString().split('T')[0],
                            // }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid sm={9} xs={12}>
                          <Item
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              required
                              options={colgOptions ? colgOptions : []}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <li {...props}>{option.name}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  required={selectedColgOptions.length === 0}
                                  label="Select Multiple Appointments"
                                  placeholder="Select Multiple Appointments"
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                              value={selectedColgOptions}
                              onChange={(e, value, ex, ey) => {
                                if (ex === "selectOption") {
                                  if (selectedColgOptions.length === 0) {
                                    setSelectedColgOptions(value);
                                  } else if (
                                    selectedColgOptions.filter(
                                      (e) => e.id === ey.option.id
                                    ).length === 0
                                  ) {
                                    setSelectedColgOptions(value);
                                  } else {
                                    setSelectedColgOptions(selectedColgOptions);
                                  }
                                } else {
                                  setSelectedColgOptions(value);
                                }
                              }}
                            />
                          </Item>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Item
                            onChange={(e) => {
                              setDegTerm(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              value={selectedDegree}
                              onChange={(event, newValue, action) => {
                                setselectedDegree([...newValue]);
                                setselectedStream([]);
                                setSeletedPreviousVideo(null);
                                setPreviousVideos([]);
                                setStart_date("");
                                if (
                                  action !== "selectOption" &&
                                  newValue.length == 0
                                ) {
                                  setPreviousVideoOption(defaultVideo);
                                }
                              }}
                              options={degreeOptions ? degreeOptions : []}
                              getOptionLabel={(option) =>
                                option.name +
                                ` (${option.short_name?.toUpperCase() || ""})`
                              }
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Degree"
                                  color="warning"
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                  // required={selectedDegree.length===0}
                                />
                              )}
                            />
                          </Item>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Item
                            onChange={(e) => {
                              setStreamTerm(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              value={selectedStream}
                              onChange={(event, newValue) => {
                                setselectedStream([...newValue]);
                              }}
                              options={streamOptions ? streamOptions : []}
                              getOptionLabel={(option) => option.name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Streams"
                                  // required={selectedStream.length===0}
                                  color="warning"
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ fontWeight: "bold", color: "#111" }}
                          >
                            Mode Of OnBoarding
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            required
                            displayEmpty
                            className="my-select"
                            inputProps={{
                              "aria-label": "Select an option",
                            }}
                            value={examTypeList}
                            onChange={(e) => {
                              setExamTypeList(e.target.value);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 10)", 
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 10)", 
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 5)", 
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              <span className="my-select-placeholder">
                                Select Mode
                              </span>
                            </MenuItem>
                            {Object.entries(examTypeListOption).map(
                              ([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        xs={12}
                        onChange={(e) => {
                          setSearchAddressingFaculty(e.target.value);
                        }}
                        style={{ marginTop: "1.2%" }}
                      >
                        <Autocomplete
                          multiple
                          options={addFacultyOptions ? addFacultyOptions : []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.full_name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.full_name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Addressing Faculty"
                              placeholder="Select Multiple Addressing Faculty"
                              error={error}
                              required={addressing_faculty.length === 0}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                          value={addressing_faculty}
                          onChange={handleAutocompleteChange}
                        />
                        {error && (
                          <p style={{ color: "red" }}>This field is required</p>
                        )}
                      </Grid>

                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Grid item sm={4} xs={12}>
                          <h4>Select Videos</h4>
                          <FormControl fullWidth>
                            <Item
                              onChange={(e) => {
                                setSearchVideo(e.target.value);
                              }}
                            >
                              <Autocomplete
                                options={
                                  previousVideoOption.length > 0
                                    ? previousVideoOption
                                    : defaultVideo
                                }
                                value={seletedPreviousVideo}
                                name="state_name"
                                onChange={(_, newVideo, action) => {
                                  setSeletedPreviousVideo(newVideo);
                                  if (action === "selectOption") {
                                    setPreviousVideos(
                                      newVideo &&
                                        newVideo.all_vid &&
                                        Object.keys(newVideo.all_vid).length > 0
                                        ? Object.values(newVideo.all_vid)
                                            .filter((e) => {
                                              const obj = Object.entries(e)[0];
                                              return newVideo.id != obj[0];
                                            })
                                            .map((e) => {
                                              const obj = Object.entries(e)[0];
                                              return {
                                                video: obj[1].name,
                                                id: Number(obj[0]),
                                                date: "",
                                                enddate: "",
                                              };
                                            })
                                        : []
                                    );
                                  } else if (action === "clear") {
                                    setPreviousVideos([]);
                                  }
                                }}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Video"
                                    required
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Item>
                          </FormControl>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <h4> Select Start Date</h4>
                          <FormControl fullWidth>
                            <Item>
                              <TextField
                                name="start_date"
                                fullWidth
                                type="datetime-local"
                                id="start_date"
                                required
                                value={start_date}
                                onChange={(e) => setStart_date(e.target.value)}
                                inputProps={{
                                  min: new Date().toISOString().slice(0, 16),
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Item>
                          </FormControl>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <h4> Select End Date</h4>
                          <FormControl fullWidth>
                            <Item>
                              <TextField
                                name="end_date"
                                fullWidth
                                type="datetime-local"
                                id="end_date"
                                required
                                value={end_date}
                                onChange={(e) => setEnd_date(e.target.value)}
                                inputProps={{
                                  min: new Date().toISOString().slice(0, 16),
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Item>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        {previousVideos.length > 0 &&
                          previousVideos.map((ex, ind) => (
                            <Fragment key={"prev_video" + ind + 1}>
                              <Grid
                                item
                                sm={4} xs={12}
                                sx={{ marginTop: "13px", marginLeft: "15px" }}
                              >
                                <FormControl fullWidth>
                                  <Item>
                                    <TextField
                                      label="Previous Video"
                                      disabled
                                      fullWidth
                                      value={ex.video}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                          "& fieldset": {
                                            borderColor: "rgba(0, 0, 0, 5)",
                                          },
                                        },
                                      }}
                                    />
                                  </Item>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                sm={4} xs={12}
                                style={{
                                  marginTop: "0.8%",
                                  marginLeft: "-10px",
                                }}
                              >
                                <FormControl fullWidth>
                                  <Item>
                                    <TextField
                                      name="start_date"
                                      fullWidth
                                      type="datetime-local"
                                      id="start_date"
                                      required
                                      value={ex.date}
                                      onChange={(e) => {
                                        const newArr = [...previousVideos];
                                        newArr[ind] = {
                                          ...newArr[ind],
                                          date: e.target.value,
                                        };
                                        setPreviousVideos(newArr);
                                      }}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                          "& fieldset": {
                                            borderColor: "rgba(0, 0, 0, 5)",
                                          },
                                        },
                                      }}
                                    />
                                  </Item>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                sm={4} xs={12}
                                style={{
                                  marginTop: "0.8%",
                                  marginLeft: "-10px",
                                }}
                              >
                                <FormControl fullWidth>
                                  <Item>
                                    <TextField
                                      name="end_date"
                                      fullWidth
                                      type="datetime-local"
                                      id="end_date"
                                      required
                                      value={ex.enddate}
                                      onChange={(e) => {
                                        const newArr = [...previousVideos];
                                        newArr[ind] = {
                                          ...newArr[ind],
                                          enddate: e.target.value,
                                        };
                                        setPreviousVideos(newArr);
                                      }}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                          "& fieldset": {
                                            borderColor: "rgba(0, 0, 0, 5)",
                                          },
                                        },
                                      }}
                                    />
                                  </Item>
                                </FormControl>
                              </Grid>
                            </Fragment>
                          ))}
                      </Grid>
                      <br />
                      <Grid item md={12} style={{marginTop:"-0.5%"}}>
                        <Grid container spacing={4}>
                          <Grid item>
                            <Checkbox
                              name="is_broadcaster"
                              checked={is_broadcaster}
                              onChange={handleBroadCaster}
                              id="broadcaster"
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              disabled={examTypeList === "1"}
                            />
                            &nbsp;
                            <label
                              htmlFor="broadcaster"
                              style={{ fontSize: "16px" }}
                            >
                              Is Broadcaster
                            </label>
                          </Grid>
                          <Grid item>
                            <Checkbox
                              name="send_email"
                              checked={send_email}
                              onChange={handleSendEmailChange1}
                              id="send_mail"
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                            />{" "}
                            <label
                              htmlFor="sendMail"
                              style={{ fontSize: "16px" }}
                            >
                              Send Mail
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        item
                        spacing={2}
                        style={{
                          textAlign: "right",
                          padding: "5px",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            width: "10%",
                            backgroundColor: "#1976D2",
                            fontWeight: "bold",
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Paper>{" "}
        </article>
      </section>
    </div>
  );
};

export default PostAddressing;
