import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";

import { useNavigate } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const QEnquiryStudent = () => {
  const navigate = useNavigate();
  const [studentsCount, setStudentsCount] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [enquiryBranchOption, setEnquiryBranchOption] = useState([]);
  const [selectedEnquiryBranchOption, setSelectedEnquiryBranchOption] =
    useState([]);
  const [inputValue2, setInputValue2] = useState([]);
  const [defaultBranchList, setDefaultBranchList] = useState([]);

  const [enquiryUserOption, setEnquiryUserOption] = useState([]);
  const [selectedEnquiryUserOption, setSelectedEnquiryUserOption] = useState(
    []
  );
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultUserList, setDefaultUserList] = useState([]);

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(`/q_users?limit=10`);
      let finalData = response.data.data;
      setEnquiryUserOption(finalData);
      setDefaultUserList(finalData);
    };
    fetchData();
  }, []);

  const fetchOptions1 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/q_users?search_keyword=${searchTerm_1}&limit=10`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setEnquiryUserOption(data);
      } else {
        setEnquiryUserOption([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setEnquiryUserOption(defaultUserList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedEnquiryUserOption(newValue);
  };

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;
        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;
        setUnitNameOption(finalData);
      }
    };

    fetchData();
  }, [searchUnitName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const enquiryUserIds = selectedEnquiryUserOption.map((user) => user.id);
    let payload = {
      enquiry_user_id: enquiryUserIds,
      unit_id: unitNameData.ID,
      student_count: parseInt(studentsCount),
    };

    try {
      await AxiosInstance.post(`post_enquiry_user`, payload)
        .then((response) => {
          toast.success("Student Post Successfully");
          setTimeout(() => {
            navigate("/user_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    border: "1px solid gray",
    borderRadius: "10px",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#FD8D14" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#176B87",
    color: "#FD8D14",
    border: "1px solid #176B87",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  // for DropDown ScrollBar

  return (
    <Grid style={{ marginTop: "4.5%" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Student Post To Enquiry</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={12}>
                <Item>
                  {/* <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    required
                    inputValue={inputValue1}
                    value={selectedEnquiryUserOption}
                    onChange={handleOptionSelect1}
                    onInputChange={handleInputChange1}
                    options={enquiryUserOption}
                    getOptionLabel={(option) => option.details?.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enquiry User"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  /> */}
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    inputValue={inputValue1}
                    value={selectedEnquiryUserOption}
                    onChange={handleOptionSelect1}
                    onInputChange={handleInputChange1}
                    options={enquiryUserOption}
                    getOptionLabel={(option) => option.details?.name || ""} // Fetch the name from details
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.details?.name + (index + 1)}
                          label={option.details?.name || ""} // Ensure the Chip label displays the name correctly
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enquiry User"
                        variant="outlined"
                        required={selectedEnquiryUserOption.length === 0}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                sm={12}
                onChange={(e) => setSearchUnitName(e.target.value)}
              >
                <Item>
                  <Autocomplete
                    options={unitNameOption}
                    // disabled={searchDisable}
                    value={unitNameData}
                    name="unitNameOption"
                    onChange={(event, newData_xy) => {
                      setUnitNameData(newData_xy);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Unit Name"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Student Count"
                  name="studentsCount"
                  type="tel"
                  required
                  value={studentsCount}
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  onChange={(e) => {
                    setStudentsCount(e.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item container sm={12} spacing={4}>
                <Grid item sm={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default QEnquiryStudent;
