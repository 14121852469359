import React, { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Styles from "../../Navbar/navLinks/_Bdmanager.module.css";
import { toast } from "react-toastify";
import BdMenuDrawer from "../../../Bd_Module/pages/Drawer";
import Menu from "@mui/material/Menu";
import {
  MdAssignmentAdd,
  MdPersonAddAlt1,
  MdReduceCapacity,
} from "react-icons/md";
import AxiosInstance from "./../../../apis/AxiosInstance";
import {
  Button,
  Tooltip,
  ListItem,
  Avatar,
  Stack,
  MenuItem,
  Modal,
  Box,
  Typography,
  ButtonGroup,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Cookies from "js-cookie";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import Badge from "@mui/material/Badge";
import { socketContext } from "../../../socket/IoContext";
import NotificationDrawer from "../../../socket/NotificationDrawer";

const BdManagerMenu = () => {
  const { notreadNotification } = useContext(socketContext);

  const logged = Cookies.get("logged");
  const role = Cookies.get("current_role");
  const userData = logged ? JSON.parse(logged) : null;

  let [treeMenu, setTreemenu] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    fetchData();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let menuRoutes = [
    "/tree_appoint_toaddressing",
    "/tree_address_torounds",
    "/tree_roundsto_shortlist",
    "/tree_onboardingdetails",
    "/tree_branchjourney",
    "/count_tree",
    "/main_tree",
  ];
  let create_Menu_withRoutes =
    treeMenu.length > 0
      ? treeMenu.map((menu, index) => {
          return {
            menu: menu,
            route: menuRoutes[index],
          };
        })
      : [];

  let handleLogout = () => {
    Cookies.remove("logged");
    Cookies.remove("current_role");
    toast.success("Logged out successfully");
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
  };

  const handleNoClick = () => {
    setOpenModal(false);
  };

  let fetchData = async (_) => {
    let apiCall = await AxiosInstance.get("data_split_details");
    let finalApi = apiCall.data.data;
    setTreemenu(finalApi);
  };

  const handleMenuChange = (event, index) => {
    window.location.assign(menuRoutes[index]);
  };

  const [openswitch, setOpenSwitch] = useState(false);

  const handleOpenSwitch = () => setOpenSwitch(true);
  const handleCloseSwitch = () => setOpenSwitch(false);

  return (
    <div>
      <section id={Styles.NavMenuContaiiner}>
        {userData && userData.role.length > 1 && (
          <Button
            size="small"
            sx={{
              border: "#fff 1px solid",
              backgroundColor: "#088395",
              color: "#fff",
              ":hover": {
                color: "#fff",
                border: "#FCA311 1px solid",
                fontWeight: "bold",
                background: "#009FBD",
              },
            }}
            onClick={handleOpenSwitch}
          >
            Switch
          </Button>
        )}
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            sx={{
              border: "#FCA311 1px solid",
              color: "#fff",
              ":hover": {
                color: "#FCA311",
                border: "#fff 1px solid",
                fontWeight: "bold",
              },
            }}
          >
            Tree Menu{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path
                  fill="#FCA311"
                  d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
                />
              </g>
            </svg>
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {create_Menu_withRoutes.map((item, index) => {
              return (
                <MenuItem
                  onClick={(event) => handleMenuChange(event, index)}
                  key={index}
                >
                  <Link
                    to={`${item.route}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {" "}
                    {item.menu}
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <NavLink
          to="/spoc_creation"
          style={{ textDecoration: "none", color: "#FCA311" }}
        >
          <ListItem
            sx={{
              color: "white",

              fontSize: "15px",
              "&:hover": {
                backgroundColor: " rgba(255,255,255,0.1)",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color: "#FCA311",
              },
            }}
          >
            <MdPersonAddAlt1 color="#FCA311" fontSize={24} />
            &nbsp;&nbsp;Create Spoc
          </ListItem>
        </NavLink>
        <NavLink
          to="/create_collegeappointment"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "14px",

              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color: "#FCA311",
              },
            }}
          >
            <MdAssignmentAdd color="#FCA311" fontSize={24} />
            &nbsp;&nbsp;New Appointment
          </ListItem>
        </NavLink>
        <NavLink
          to="/gd_creation"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "14px",

              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color: "#FCA311",
              },
            }}
          >
            <MdReduceCapacity color="#FCA311" fontSize={24} />
            &nbsp;&nbsp;GD Creation
          </ListItem>
        </NavLink>

        <NavLink
          to="/profile"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip arrow placement="bottom" title="View Profile">
              <Avatar sx={{ bgcolor: "#fff", color: "#FCA311" }}>
                <PersonIcon size="40" />
              </Avatar>
            </Tooltip>
          </Stack>
        </NavLink>

        {/* <NavLink
          to="/notifications"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={1}>
            <Tooltip arrow placement="bottom" title="Notifications">
             
              <Badge
                badgeContent={notreadNotification}
                color="warning"
                max={99999}
              >
                <NotificationsNoneIcon style={{ fontSize: 30 }} />
              </Badge>
             
            </Tooltip>
          </Stack>
        </NavLink> */}
        <NotificationDrawer title="Menu" />

        <BdMenuDrawer title="Menu" />

        <span
          onClick={() => setOpenModal(true)}
          style={{
            textDecoration: "none",
            color: "#FCA311",
            background: "none",
            fontSize: "18px",
            cursor:"pointer"
          }}
        >
          Logout
        </span>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 370,
              bgcolor: "background.paper",
              border: "2px solid #182747",
              boxShadow: 24,
              p: 2,
              borderRadius: "5px",
            }}
          >
            <IconButton
              onClick={() => setOpenModal(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "red",
              }}
            >
              <CancelIcon />
            </IconButton>
            <Typography
              sx={{
                mt: 2,
                color: "#06113C",
                fontSize: "20px",
                fontWeight: "900",
              }}
            >
              Do you want to Logout ?
            </Typography>
            <ButtonGroup sx={{ mt: 5 }} fullWidth>
              <Button
                onClick={handleLogout}
                style={{
                  background: "#06113C",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                  padding: "10px 10px",
                }}
              >
                Yes
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                onClick={handleNoClick}
                style={{
                  background: "#C7253E",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </Box>
        </Modal>
        <Modal
          open={openswitch}
          onClose={handleCloseSwitch}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseSwitch}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CancelIcon color="error" />
            </IconButton>
            <h3 id="modal-title" style={{ color: "#003285" }}>
              Do you want to Switch the role?
            </h3>
            <div
              id="modal-description"
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                marginTop: "10%",
              }}
            >
              {userData.role
                .filter((val) => val.id != role)
                .map((r, index) => (
                  <Button
                    size="small"
                    key={index}
                    onClick={() => {
                      Cookies.set("current_role", r.id);
                      setTimeout(() => {
                        window.location.reload();
                      }, 100);
                    }}
                    style={{
                      background: "#134B70",
                      color: "#fff",
                      // border: "1px solid #F4A311",
                      gap: "10px",
                      marginLeft:"20%",
                      cursor: "pointer"
                    }}
                  >
                    {r.name}
                  </Button>
                ))}
            </div>
          </Box>
        </Modal>
      </section>
    </div>
  );
};

export default BdManagerMenu;
