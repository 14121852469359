import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { CgListTree } from "react-icons/cg";
import { PostContext } from "../../bdTree/ContextApi";
import { FaEdit } from "react-icons/fa";
import {
  Box,
  Button,
  Table,
  TableBody,
  MenuItem,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  Paper,
  Autocomplete,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import ContactModal from "../../bd/appointmentList/ContactModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FaUsers, FaUserTie, FaPlusCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import TreeAppointToAddressing from "../TreeAppointToAddressing";
import Styles from "./counts.module.css";
import AxiosInstance from "../../../apis/AxiosInstance";
import { RiFilterOffFill } from "react-icons/ri";
import { TiDelete } from "react-icons/ti";
import { Link } from "react-router-dom";
import AddressingEditModal from "../../bd/addressingForm/AddressingEditModal";
import moment from "moment";
import Cookies from "js-cookie";
import StudentRegisterList from "../../bd/appointmentList/StudentRegisterList";
import OtherCollegeRegisterList from "../../bd/appointmentList/OtherCollegeRegisterList";
import EditSpocAddRemoveModal from "../../bd/spocCreation/EditSpocAddRemoveModal";
import CollegeTree from "../CollegeTree";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:
      role === "1" ? "#EAFDFC" : role === "5" ? "#EDEDED" : "#EAFDFC",
    color: "black",
    border: "none",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

const CountAppointToAddressing = () => {
  const meetingID_Ref = useRef(null);
  const {
    expanded,
    country,
    states,
    district,
    colleges,
    setCountry,
    setStates,
    setDistrict,
    setColleges,
    appointments,
    setAppointments,
    setStateURL,
    setExpanded,
    setDistrictURL,
    setCollegeURL,
    setAppointmentDistrictFilter,
    setAppointmentCollegeFilter,
    setCountryURL,
    appointmentStateFilter,
    setAppointmentStateFilter,
    collegeFilter,
    setCollegeFilter,
    setAppointmentURL,
  } = React.useContext(PostContext);

  //=========================================Search Section==============================

  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [collegeData, setCollegeData] = useState(null);
  const [collegeOptions, setCollegeOptions] = useState([]);
  let [appointmentStatus, setAppointmentStatus] = useState("");
  let [appointmentStatusOption, setAppointmentStatusOption] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  let [appointmentYear, setAppointmentYear] = useState("");

  const [appointment_time, setAppointment_time] = useState("");
  const [error, setError] = useState(false);
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  const ShowSearchError = ($) => {
    let emptySearchTerm =
      selectedColgState !== null ||
      selectedColgDistrict !== null ||
      collegeData !== null ||
      // appointmentStatus !== "" ||
      // fromDate !== "" ||
      // toDate !== "" ||
      // appointment_time !== "" ||
      // appointmentYear !== "" ||
      collegeAid !== "" ||
      searchYOP !== "" ||
      setUnitNameData !== null
        ? false
        : true;
    if (emptySearchTerm) {
      toast.error("Please Select the Items to be filtered", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (searchSuccess) {
      toast.info("Filters applied successfully expand the tree for results", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState("");

  const [collegeAid, setCollegeAid] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [searchYOP, setSearchYOP] = useState("");

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setAppointmentStatus("");
  };

  //api calll
  // useEffect(() => {
  //   let fetchData = async () => {
  //     let data = await AxiosInstance.get(`/countrymini?name=india`);
  //     let finalData = data.data.data;
  //     setCountryMini(finalData);
  //     setCountryMiniId(finalData[0].id);
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (stateSearch !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${country[0].id}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
        setSearchSuccess(true);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${country[0].id}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
        setSearchSuccess(true);
      }
    };
    if (country && country.length > 0) {
      fetchData();
    }
  }, [stateSearch, country]);

  const [defaultClg, setDefaultClg] = useState([]);
  //! for college district
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        let colgDistrictData = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        let finalcolgDistrictData = colgDistrictData.data.data;
        setcolgDistrictOptions(finalcolgDistrictData);
        setDefaultClg(finalcolgDistrictData);
        setSearchSuccess(true);
      }
    };
    fetchData();
  }, [selectedColgState]);
  const serachClg = async (e) => {
    try {
      let colgDistrictData = await AxiosInstance.get(
        `/district_mini?search_keyword=${e}&state_id=${selectedColgState.id}&limit=10`
      );
      let finalcolgDistrictData = colgDistrictData.data.data;
      setcolgDistrictOptions(finalcolgDistrictData);
      setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };
  //! for college

  const [defaultdistClg, setDefaultdistClg] = useState([]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setCollegeOptions(finalcolgData);
        setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  const searchdistClg = async (e) => {
    try {
      let colgData = await AxiosInstance.get(
        `/college_mini?search_keyword=${e}&district_id=${selectedColgDistrict.id}&limit=10`
      );
      let finalcolgData = colgData.data.data;
      setCollegeOptions(finalcolgData);
      setSearchSuccess(true);
    } catch (error) {
      setCollegeOptions(defaultdistClg);
    }
  };

  //for appointmentStatus

  // let fetchData = async () => {
  //   let appStatus = await AxiosInstance.get(`/appointment_status`);
  //   let finalAppStatus = appStatus.data;
  //   setAppointmentStatusOption(finalAppStatus.data);
  //   setSearchSuccess(true);
  // };

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    fetchData();
  }, [searchUnitName]);

  const resetFilters = async (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setCollegeData(null);
    setFromDate("");
    setToDate("");
    setAppointmentDistrictFilter(false);
    setAppointmentCollegeFilter(false);
    window.location.href = "/tree_appoint_toaddressing";
  };

  let handleAddTime = (e) => {
    setAppointment_time(e.target.value);
  };

  const resetAppTime = async (e) => {
    e.preventDefault();
    setAppointment_time("");
  };

  // Search
  const [columns, setColumns] = React.useState([
    "Appointment Date and Time",
    "Appointment Link",
    "Appointment Status",
    "Year Of Passout",
    "Registered Students",
    "Other College Registered Students",
    "TPO Follow-up",
    "Spoc Details",
    "Addressing Date and Time",
  ]);

  const copyToClipboard = async (event) => {
    const textToCopy = await event.target.value;
    navigator.clipboard
      .writeText(`https://incubation.qspiders.com/appointment/${textToCopy}`)
      .then(() => {
        toast.info("Student Login Link Copied");
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  const copyToClipboardtext = async (event) => {
    const textToCopy = await event.target.value;
    navigator.clipboard
      .writeText(`https://incubationbd.qspiders.com/login/${textToCopy}`)
      .then(() => {
        toast.info("Addressing Faculty Link Copied");
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const [expandedState, setExpandedState] = useState([]);
  const [expandedDistrict, setExpandedDistrict] = useState([]);
  const [expandedCollege, setExpandedCollege] = useState([]);
  const [expandedAppointment, setExpandedAppointment] = useState([]);

  useEffect(() => {
    if (expanded.length > 0) {
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let stateArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          !ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (stateArr.length > 0) {
        stateArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr1.push(xid);
        });
        setExpandedState(arr1);
      } else {
        setExpandedState(arr1);
      }
      let distArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (distArr.length > 0) {
        distArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr2.push(xid);
        });
        setExpandedDistrict(arr2);
      } else {
        setExpandedDistrict(arr2);
      }
      let collegeArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (collegeArr.length > 0) {
        collegeArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr3.push(xid);
        });
        setExpandedCollege(arr3);
      } else {
        setExpandedCollege(arr3);
      }
      setExpandedAppointment(
        expanded.filter(
          (ex) =>
            ex.includes("state") &&
            ex.includes("district") &&
            ex.includes("college") &&
            ex.includes("appointment")
        ).length > 0
          ? expanded
              .filter(
                (ex) =>
                  ex.includes("state") &&
                  ex.includes("district") &&
                  ex.includes("college") &&
                  ex.includes("appointment")
              )
              .map((element) => parseInt(element.match(/\d+/)[0]))
          : []
      );
    }
  }, [expanded]);
  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      window.location.reload();
    });
  }, []);

  const searchFiltersData = () => {
    if (
      (selectedColgState && selectedColgState.id === 0) ||
      (selectedColgDistrict && selectedColgDistrict.id === 0) ||
      (collegeData && collegeData.id === 0)
    ) {
      toast("Select proper data");
      return;
    }

    const params = [];

    if (selectedColgState) {
      params.push(`state_id=${selectedColgState.id}`);
    }

    if (selectedColgDistrict) {
      params.push(`district_id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params.push(`appointment_yop=${searchYOP}`);
    }

    let queryString = params.length > 0 ? `&${params.join("&")}` : "";

    if (queryString) {
      setCountryURL(`/country?name=india${queryString}`);
    }

    if (
      (selectedColgState && selectedColgState.id === 0) ||
      (selectedColgDistrict && selectedColgDistrict.id === 0) ||
      (collegeData && collegeData.id === 0)
    ) {
      // Display toast message
      toast("Select proper data");
      return;
    }

    const params1 = [];

    if (selectedColgState) {
      params1.push(`id=${selectedColgState.id}`);
    }

    if (selectedColgDistrict) {
      params1.push(`district_id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params1.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params1.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params1.push(`appointment_yop=${searchYOP}`);
    }

    let queryString1 = params1.length > 0 ? `${params1.join("&")}` : "";

    if (queryString1) {
      setStateURL(`/state?${queryString1}`);
    }

    if (
      (selectedColgDistrict && selectedColgDistrict.id === 0) ||
      (collegeData && collegeData.id === 0)
    ) {
      // Display toast message
      toast("Select proper data");
      return;
    }

    const params2 = [];

    if (selectedColgDistrict) {
      params2.push(`id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params2.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params2.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params2.push(`appointment_yop=${searchYOP}`);
    }

    let queryString2 = params2.length > 0 ? `${params2.join("&")}` : "";

    if (queryString2) {
      setDistrictURL(`/district?${queryString2}`);
    }

    if (collegeData && collegeData.id === 0) {
      toast("Select proper data");
      return;
    }

    const params3 = [];

    if (collegeData) {
      params3.push(`id=${collegeData.id}`);
    }

    if (unitNameData) {
      params3.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params3.push(`appointment_yop=${searchYOP}`);
    }

    let queryString3 = params3.length > 0 ? `${params3.join("&")}` : "";

    if (queryString3) {
      setCollegeURL(`/college?${queryString3}`);
    }

    const params4 = [];

    if (unitNameData) {
      params4.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params4.push(`yop=${searchYOP}`);
    }

    let queryString4 = params4.length > 0 ? `${params4.join("&")}` : "";

    if (queryString4) {
      setAppointmentURL(`/appointment?${queryString4}`);
    }

    // if (
    //   appointmentStatus !== "" &&
    //   fromDate !== "" &&
    //   toDate !== "" &&
    //   appointment_time !== "" &&
    //   appointmentYear !== ""
    // ) {
    //   setAppointmentURL(
    //     `/appointment?status=${appointmentStatus}&to_date=${toDate}&from_date=${fromDate}&appointment_time=${appointment_time}&appointment_year=${appointmentYear}`
    //   );
    // } else if (
    //   fromDate !== "" &&
    //   toDate !== "" &&
    //   appointment_time !== "" &&
    //   appointmentYear !== ""
    // ) {
    //   setAppointmentURL(
    //     `/appointment?to_date=${toDate}&from_date=${fromDate}&appointment_time=${appointment_time}&appointment_year=${appointmentYear}`
    //   );
    // } else if (fromDate !== "" && toDate !== "" && appointment_time !== "") {
    //   setAppointmentURL(
    //     `/appointment?to_date=${toDate}&from_date=${fromDate}&appointment_time=${appointment_time}`
    //   );
    // } else if (fromDate !== "" && toDate !== "" && appointmentYear !== "") {
    //   setAppointmentURL(
    //     `/appointment?to_date=${toDate}&from_date=${fromDate}&appointment_year=${appointmentYear}`
    //   );
    // } else if (
    //   fromDate !== "" &&
    //   appointment_time !== "" &&
    //   appointmentYear !== ""
    // ) {
    //   setAppointmentURL(
    //     `/appointment?from_date=${fromDate}&appointment_time=${appointment_time}&appointment_year=${appointmentYear}`
    //   );
    // } else if (
    //   toDate !== "" &&
    //   appointment_time !== "" &&
    //   appointmentYear !== ""
    // ) {
    //   setAppointmentURL(
    //     `/appointment?to_date=${toDate}&appointment_time=${appointment_time}&appointment_year=${appointmentYear}`
    //   );
    // } else if (fromDate !== "" && toDate !== "") {
    //   setAppointmentURL(`/appointment?to_date=${toDate}&from_date=${fromDate}`);
    // } else if (fromDate !== "" && appointment_time !== "") {
    //   setAppointmentURL(
    //     `/appointment?from_date=${fromDate}&appointment_time=${appointment_time}`
    //   );
    // } else if (fromDate !== "" && appointmentYear !== "") {
    //   setAppointmentURL(
    //     `/appointment?from_date=${fromDate}&appointment_year=${appointmentYear}`
    //   );
    // } else if (toDate !== "" && appointment_time !== "") {
    //   setAppointmentURL(
    //     `/appointment?to_date=${toDate}&appointment_time=${appointment_time}`
    //   );
    // } else if (toDate !== "" && appointmentYear !== "") {
    //   setAppointmentURL(
    //     `/appointment?to_date=${toDate}&appointment_year=${appointmentYear}`
    //   );
    // } else if (appointment_time !== "" && appointmentYear !== "") {
    //   setAppointmentURL(
    //     `/appointment?appointment_time=${appointment_time}&appointment_year=${appointmentYear}`
    //   );
    // } else if (fromDate !== "") {
    //   setAppointmentURL(`/appointment?from_date=${fromDate}`);
    // } else if (toDate !== "") {
    //   setAppointmentURL(`/appointment?to_date=${toDate}`);
    // } else if (appointmentYear !== "") {
    //   setAppointmentURL(`/appointment?appointment_year=${appointmentYear}`);
    // } else if (appointment_time !== "") {
    //   setAppointmentURL(`/appointment?appointment_time=${appointment_time}`);
    // } else if (appointmentStatus !== "") {
    //   setAppointmentURL(`/appointment?status=${appointmentStatus}`);
    // } else {
    //   setAppointmentURL("/appointment?limit=10");
    // }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const searchCollegeData = async () => {
    try {
      const { data } = await AxiosInstance.get(`/college?aid=${collegeAid}`);
      if (data && data.length > 0) {
        setColleges(data.data);
        setAppointments([]);
        setExpanded([]);
        setCountry([]);
        setStates([]);
        setDistrict([]);
        setCollegeFilter(true);
      } else {
        toast.error("No college Data Found");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || "No college Data Found");
    }
  };

  const filterRef = useRef();
  const [margin, setMargin] = useState("");
  useEffect(() => {
    // Function to update element height
    const updateElementHeight = () => {
      if (filterRef.current) {
        const { height } = filterRef.current.getBoundingClientRect();
        setMargin(height);
      }
    };
    updateElementHeight();
    window.addEventListener("resize", updateElementHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateElementHeight);
    };
  }, []);

  return (
    <>
      <section style={{ marginTop: "3.41%" }}>
        <Grid style={{ width: "98%" }} id="searchBlock">
          <Grid
            spacing={1}
            container
            ref={filterRef}
            style={{
              // marginTop: "7px",
              width: "100%",
              alignItems: "center",
              margin: "0 auto",
              gap: "5px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              padding: "15px",
              height: "auto",
              position: "fixed",
              zIndex: "10",
              backgroundColor: "#EEEDEB",
              borderBottom: "2px solid gray",
            }}
          >
            <Grid
              item
              md={1.8}
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={colgStateOptions}
                value={selectedColgState}
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setCollegeData(null);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                   
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={selectedColgState ? colgDistrictOptions : []}
                value={selectedColgDistrict}
                name="district_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setCollegeData(null);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    variant="outlined"
                    value={distSearch}                 
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={selectedColgDistrict ? collegeOptions : []}
                value={collegeData}
                name="college_name"
                onChange={(event, newColgdis) => {
                  setCollegeData(newColgdis);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdistClg(e);
                  } else {
                    setCollegeOptions(defaultdistClg);
                  }
                  setCollSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="College"
                    variant="outlined"
                    value={collSearch}
                    error={error && !collegeData}
                    helperText={
                      error && !collegeData ? "Please select Colleges" : null
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              {selectedColgState || unitNameData ? (
                <TextField
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                  disabled
                />
              ) : (
                <TextField
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              md={1.5}
              onChange={(e) => setSearchUnitName(e.target.value)}
            >
              <Autocomplete
                options={unitNameOption}
                disabled={collegeAid}
                value={unitNameData}
                size="small"
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unit Name"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                size="small"
                fullWidth
                disabled={collegeAid}
                name="searchYOP"
                label="Search YOP"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 4, maxLength: 4 }}
                id="searchYOP"
                value={searchYOP}
                onChange={(e) => {
                  setSearchYOP(e.target.value);
                }}
                InputLabelProps={{
                  style: { fontSize: 16 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderColor: "rgba(0, 0, 0, 10)",
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 5)",
                    },
                  },
                }}
              />
            </Grid>
            {/* <Grid
              item
              md={2.0}
              id="refreshTool"
              style={{ position: "relative" }}
            >
              <FormControl fullWidth size="small" onClick={() => fetchData()}>
                <InputLabel id="demo-simple-select-label">
                  Appointment Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={appointmentStatus}
                  label="appointmentStatus"
                  onChange={(e) => {
                    setAppointmentStatus(e.target.value);
                  }}
                  disabled={collegeAid}
                >
                  {appointmentStatusOption === null ? (
                    <MenuItem>No data Available</MenuItem>
                  ) : (
                    Object.entries(appointmentStatusOption).map((e) => {
                      return (
                        <MenuItem value={e[0]} key={"status" + e[0]}>
                          {e[1]}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
                {appointmentStatus !== "" ? (
                  <Tooltip title="Clear Status">
                    <div
                      style={{
                        position: "absolute",
                        top: "9px",
                        left: "225px",
                        color: "#CC7351",
                        cursor: "pointer",
                      }}
                      className={Styles.refreshTool1}
                    >
                      <TiDelete
                        fontSize={24}
                        title="Reset Status"
                        onClick={resetAppStatus}
                      />
                    </div>
                  </Tooltip>
                ) : null}
              </FormControl>
            </Grid>
         
            <Grid item lg={1.5}>
              <TextField
                disabled={collegeAid}
                fullWidth
                size="small"
                type="search"
                label="Appointment Year"
                placeholder="Appointment Year"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={appointmentYear}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, "");
                  if (inputValue.length <= 4) {
                    setAppointmentYear(inputValue);
                  }
                }}
              />
            </Grid>
          
            <Grid item md={1.5}>
              <TextField
                disabled={collegeAid}
                type="date"
                fullWidth
                id="From Date"
                size="small"
                label="From Date "
                name="From Date"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                autoFocus
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
            </Grid>
         
            <Grid item md={1.5}>
              <TextField
                disabled={collegeAid}
                size="small"
                type="date"
                fullWidth
                id="toDate"
                label="To Date "
                name="To Date"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                autoFocus
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
              />
            </Grid>
        
            <Grid item md={1.6} sx={{ position: "relative" }}>
              <TextField
                disabled={collegeAid}
                type="time"
                size="small"
                fullWidth
                id="appointmentTime"
                label="Appointment Time"
                name="appointment time"
                onFocus={(e) => (e.target.type = "time")}
                onBlur={(e) => (e.target.type = "text")}
                autoFocus
                value={appointment_time}
                onChange={(e) => {
                  handleAddTime(e);
                }}
              />
              <Tooltip title="Clear Appointment Time">
                <div
                  style={{
                    position: "absolute",
                    top: "17px",
                    left: "155px",
                    color: "#CC7351",
                    cursor: "pointer",
                  }}
                >
                  {appointment_time !== "" ? (
                    <TiDelete fontSize={24} onClick={resetAppTime} />
                  ) : null}
                </div>
              </Tooltip>
            </Grid> */}

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                style={{
                  backgroundColor: "#003285",
                  color: "#fff",
                }}
                onClick={() => {
                  ShowSearchError();
                  if (collegeAid) searchCollegeData();
                  else searchFiltersData();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "0.7%",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={resetFilters}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section
        style={{ width: "100%", marginTop: "3.5%", height: margin }}
      ></section>
      <section
        id="treeStructureAppointment"
        style={{ width: "100%", minHeight: "100vh", overflowY: "auto" }}
      >
        <article
          style={{
            width: "100%",
            display: "flex",
            minHeight: "100vh",
            // position: "relative",
            // top: "16vh",
          }}
        >
          <aside
            style={{
              width: "40%",
              height: "auto",
            }}
            className="treeBlock"
          >
            <Box
              style={{
                backgroundColor:
                  role === "1"
                    ? "#e2eafc"
                    : role === "5"
                    ? "#F5EDDC"
                    : "#e2eafc",
                padding: "7px",
              }}
            >
              <CgListTree fontSize={35} style={{ marginLeft: "30px" }} />{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#0D4C92",
                  marginLeft: "20px",
                }}
              >
                Appointment To Addressing
              </span>
            </Box>
            {/* <div>
              <TreeAppointToAddressing />
            </div> */}
            <div>
              {collegeFilter ? <CollegeTree /> : <TreeAppointToAddressing />}
            </div>
          </aside>
          <aside
            style={{
              width: "45%",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
            }}
            className="tableBlock"
          >
            <div
              style={{ width: "100%", minHeight: "100%", whiteSpace: "nowrap" }}
            >
              <TableContainer component={Paper} style={{ minHeight: "99vh" }}>
                <Table sx={{ minWidth: 700 }} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <StyledTableCell key={index}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            id="innerChild"
                          >
                            {column}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {!collegeFilter && country && country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="Appointment Date & Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="Appointment Link">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="Appointment Status">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="Year Of Passout">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coun.registered_students}</p>&nbsp;&nbsp;
                                  <p style={{ fontSize: "1px" }}>
                                    {" "}
                                    <StudentRegisterList
                                      appointment_idx={coun.id}
                                      level="1"
                                    ></StudentRegisterList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>
                                    {coun.other_college_registered_students ===
                                    null
                                      ? 0
                                      : coun.other_college_registered_students}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p style={{ fontSize: "1px" }}>
                                    {" "}
                                    <OtherCollegeRegisterList
                                      appointment_idx={coun.id}
                                      otherCollegeReg="1"
                                    ></OtherCollegeRegisterList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="TPO Follow-up">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="Spoc Details">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <BootstrapTooltip title="Addressing Date & Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}

                      {expanded.length > 0
                        ? states.map((state, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Appointment Date & Time">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Appointment Link">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Appointment Status">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Year Of Passout">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>{state.registered_students}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <StudentRegisterList
                                            appointment_idx={state.id}
                                            level="2"
                                          ></StudentRegisterList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {state.other_college_registered_students ===
                                          null
                                            ? 0
                                            : state.other_college_registered_students}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <OtherCollegeRegisterList
                                            appointment_idx={state.id}
                                            otherCollegeReg="2"
                                          ></OtherCollegeRegisterList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="TPO Follow-up">
                                      <p>----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Spoc Details">
                                      <p>----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Addressing Date & Time">
                                      <p>----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == state.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                                style={{
                                                  padding: "18.6px",
                                                  paddingBottom:
                                                    dist.districts_counts ===
                                                    district.filter(
                                                      (e) =>
                                                        e.state_id === dist.id
                                                    ).length
                                                      ? dist.districts_counts ===
                                                        dist + 1
                                                        ? "45px"
                                                        : "18.6px"
                                                      : "18.6px",
                                                }}
                                              >
                                                <BootstrapTooltip title="Appointment Date & Time">
                                                  <p>----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Appointment Link">
                                                  <p>----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Appointment Status">
                                                  <p>----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Year Of Passout">
                                                  <p>----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.registered_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <StudentRegisterList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        level="3"
                                                      ></StudentRegisterList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.other_college_registered_students ===
                                                      null
                                                        ? 0
                                                        : dist.other_college_registered_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <OtherCollegeRegisterList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        otherCollegeReg="3"
                                                      ></OtherCollegeRegisterList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="TPO Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Spoc Details">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Addressing Date & Time">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>

                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )
                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Date & Time">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Link">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Status">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Year Of Passout">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.registered_students
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <StudentRegisterList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    level="4"
                                                                  ></StudentRegisterList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.other_college_registered_students ===
                                                                  null
                                                                    ? 0
                                                                    : coll.other_college_registered_students}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <OtherCollegeRegisterList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    otherCollegeReg="4"
                                                                  ></OtherCollegeRegisterList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="TPO Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                <EditSpocAddRemoveModal
                                                                  editSpocResult={
                                                                    coll
                                                                  }
                                                                />
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing Date & Time">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>

                                                        {appointments != null &&
                                                        expandedCollege.length >
                                                          0 &&
                                                        appointments.filter(
                                                          (aph) =>
                                                            aph.ids.college
                                                              .id == coll.id
                                                        ).length > 0
                                                          ? appointments
                                                              .filter(
                                                                (aph) =>
                                                                  aph.ids
                                                                    .college
                                                                    .id ==
                                                                  coll.id
                                                              )
                                                              .map(
                                                                (
                                                                  apps,
                                                                  indApps
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <TableRow
                                                                        key={
                                                                          indApps +
                                                                          1
                                                                        }
                                                                      >
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p>
                                                                              {apps.appointment_date ===
                                                                                null ||
                                                                              apps.appointment_date ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                moment
                                                                                  .utc(
                                                                                    apps.appointment_date
                                                                                  )
                                                                                  .local()
                                                                                  .format(
                                                                                    "DD-MM-yyyy hh:mm A"
                                                                                  )
                                                                                // apps.appointment_date.replace("+0000 UTC", "").replace("+0000", "")
                                                                              )}
                                                                              <Tooltip title="Appointment Edit">
                                                                                <Link
                                                                                  to="/edit_college_appointment"
                                                                                  state={
                                                                                    apps
                                                                                  }
                                                                                >
                                                                                  <FaEdit
                                                                                    fontSize={
                                                                                      18
                                                                                    }
                                                                                    color="#537FE7"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      width:
                                                                                        "50px",
                                                                                    }}
                                                                                  />
                                                                                </Link>
                                                                              </Tooltip>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <BootstrapTooltip
                                                                          // title="Appointment Link"
                                                                          placement="top"
                                                                        >
                                                                          <StyledTableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className={
                                                                              Styles.appToAddAppointment
                                                                            }
                                                                          >
                                                                            <p
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize:
                                                                                  "0px",
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                            >
                                                                              {
                                                                                apps.id
                                                                              }
                                                                            </p>

                                                                            <Box
                                                                              sx={{
                                                                                display:
                                                                                  "flex",
                                                                                gap: "2px",
                                                                              }}
                                                                            >
                                                                              <Tooltip
                                                                                title="Faculty Link-Click to Copy"
                                                                                placement="left-end"
                                                                              >
                                                                                <Button
                                                                                  size="small"
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "12px",
                                                                                    padding:
                                                                                      "6px,10px",
                                                                                  }}
                                                                                  value={
                                                                                    apps.id
                                                                                  }
                                                                                  sx={{
                                                                                    background:
                                                                                      "#337CCF",
                                                                                    "&:hover":
                                                                                      {
                                                                                        background:
                                                                                          "#1450A3",
                                                                                      },
                                                                                  }}
                                                                                  type="button"
                                                                                  variant="contained"
                                                                                  onClick={
                                                                                    copyToClipboardtext
                                                                                  }
                                                                                >
                                                                                  Faculty
                                                                                </Button>
                                                                              </Tooltip>

                                                                              <Tooltip
                                                                                title="Student Link-Click to Copy"
                                                                                placement="right-end"
                                                                              >
                                                                                <Button
                                                                                  size="small"
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "12px",
                                                                                    padding:
                                                                                      "6px,10px",
                                                                                  }}
                                                                                  value={
                                                                                    apps.id
                                                                                  }
                                                                                  type="button"
                                                                                  variant="contained"
                                                                                  sx={{
                                                                                    background:
                                                                                      "#76549A",
                                                                                    "&:hover":
                                                                                      {
                                                                                        background:
                                                                                          "#313866",
                                                                                      },
                                                                                  }}
                                                                                  onClick={
                                                                                    copyToClipboard
                                                                                  }
                                                                                >
                                                                                  Student
                                                                                </Button>
                                                                              </Tooltip>
                                                                            </Box>
                                                                          </StyledTableCell>
                                                                        </BootstrapTooltip>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Appointment Status">
                                                                            <p>
                                                                              {" "}
                                                                              {apps
                                                                                .status
                                                                                .id ===
                                                                              1 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#316B83",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 7px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                    fontWeight:
                                                                                      "500",
                                                                                  }}
                                                                                >
                                                                                  New
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                2 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "purple",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 6px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                    fontWeight:
                                                                                      "500",
                                                                                  }}
                                                                                >
                                                                                  Merged
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                3 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#1363DF",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 6px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Confirmed
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                4 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#379237",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Conducted
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                5 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#537188",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Date
                                                                                  Overdue
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                6 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#9D5C0D",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Yet
                                                                                  to
                                                                                  Approach
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                7 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#E86A33",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Pending
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                8 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#BE0000",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Not
                                                                                  Interested
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                9 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#898121",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Unmerge
                                                                                </p>
                                                                              ) : (
                                                                                <>

                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Year Of Passout">
                                                                            <p>
                                                                              {apps &&
                                                                              apps.year_of_passout
                                                                                ? apps.year_of_passout
                                                                                : "NA"}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.registered_students ===
                                                                                null
                                                                                  ? 0
                                                                                  : apps.registered_students}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <StudentRegisterList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  level="5"
                                                                                ></StudentRegisterList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.other_college_registered_students_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <OtherCollegeRegisterList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  otherCollegeReg="5"
                                                                                ></OtherCollegeRegisterList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="TPO Follow-up">
                                                                            <p>
                                                                              {" "}
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p>
                                                                              {" "}
                                                                              {Object.keys(
                                                                                apps.college_spocs
                                                                              )
                                                                                .length <
                                                                              1 ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  No
                                                                                  Spoc
                                                                                </Box>
                                                                              ) : (
                                                                                <>
                                                                                  <ContactModal
                                                                                    mobile={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .mobile
                                                                                    }
                                                                                    email={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .email
                                                                                    }
                                                                                    name={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .name
                                                                                    }
                                                                                  />
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appToAddAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p>
                                                                              {/* {" "}
                                                                              {apps.addressing_datetime ===
                                                                                null ||
                                                                              apps.addressing_datetime ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                apps.addressing_datetime.replace(
                                                                                  "+0000",
                                                                                  ""
                                                                                )
                                                                              )} */}

                                                                              {apps.addressing_datetime ==
                                                                              "" ? (
                                                                                <Tooltip title="Create Addressing">
                                                                                  <Link
                                                                                    to="/create_newaddressing"
                                                                                    style={{
                                                                                      textDecoration:
                                                                                        "none",
                                                                                      padding:
                                                                                        "5px 3px",
                                                                                      borderRadius:
                                                                                        "10px",
                                                                                      color:
                                                                                        "Crimson",
                                                                                    }}
                                                                                  >
                                                                                    NA
                                                                                    <span>
                                                                                      <FaPlusCircle
                                                                                        fontSize={
                                                                                          18
                                                                                        }
                                                                                        color="#537FE7"
                                                                                        style={{
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          width:
                                                                                            "40px",
                                                                                        }}
                                                                                      />
                                                                                    </span>
                                                                                  </Link>
                                                                                </Tooltip>
                                                                              ) : (
                                                                                <>
                                                                                  {/* {apps.addressing_datetime.replace(
                                                                                    "+0000 UTC",
                                                                                    ""
                                                                                  )} */}
                                                                                  {moment
                                                                                    .utc(
                                                                                      apps.addressing_datetime
                                                                                    )
                                                                                    .local()
                                                                                    .format(
                                                                                      "DD-MM-yyyy hh:mm A"
                                                                                    )}
                                                                                  <Tooltip title="Edit">
                                                                                    {/* <AddressingEditModal
                                                                                      stateAddresssing={
                                                                                        apps
                                                                                      }
                                                                                      status={
                                                                                        apps
                                                                                          .status
                                                                                          .id
                                                                                      }
                                                                                    /> */}
                                                                                    <AddressingEditModal
                                                                                      stateAddresssing={
                                                                                        apps
                                                                                      }
                                                                                      // status={
                                                                                      //   apps
                                                                                      //     .status
                                                                                      //     .id
                                                                                      // }
                                                                                      status={
                                                                                        Object.keys(
                                                                                          apps.addressing_status
                                                                                        )[0]
                                                                                      }
                                                                                    />
                                                                                  </Tooltip>
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                      </TableRow>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : collegeFilter && colleges.length > 0 ? (
                    colleges.map((coll, indColl) => {
                      return (
                        <>
                          <TableRow key={indColl + 1}>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Appointment Date & Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Appointment Link">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Appointment Status">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Year Of Passout">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>{coll.registered_students}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <StudentRegisterList
                                      appointment_idx={coll.id}
                                      level="4"
                                    ></StudentRegisterList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {coll.other_college_registered_students ===
                                    null
                                      ? 0
                                      : coll.other_college_registered_students}
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <OtherCollegeRegisterList
                                      appointment_idx={coll.id}
                                      otherCollegeReg="4"
                                    ></OtherCollegeRegisterList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="TPO Follow-up">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  <EditSpocAddRemoveModal
                                    editSpocResult={coll}
                                  />
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Addressing Date & Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>

                          {appointments != null && appointments.length > 0
                            ? appointments.map((apps, indApps) => {
                                return (
                                  <>
                                    <TableRow key={indApps + 1}>
                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip>
                                          <p>
                                            {apps.appointment_date === null ||
                                            apps.appointment_date === "" ? (
                                              <Box
                                                sx={{
                                                  color: "#EB455F",
                                                }}
                                              >
                                                NA
                                              </Box>
                                            ) : (
                                              moment
                                                .utc(apps.appointment_date)
                                                .local()
                                                .format("DD-MM-yyyy hh:mm A")
                                              // apps.appointment_date.replace("+0000 UTC", "").replace("+0000", "")
                                            )}
                                            <Tooltip title="Appointment Edit">
                                              <Link
                                                to="/edit_college_appointment"
                                                state={apps}
                                              >
                                                <FaEdit
                                                  fontSize={18}
                                                  color="#537FE7"
                                                  style={{
                                                    cursor: "pointer",
                                                    width: "50px",
                                                  }}
                                                />
                                              </Link>
                                            </Tooltip>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <BootstrapTooltip
                                        // title="Appointment Link"
                                        placement="top"
                                      >
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          className={Styles.appToAddAppointment}
                                        >
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "0px",
                                              color: "white",
                                            }}
                                          >
                                            {apps.id}
                                          </p>

                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "2px",
                                            }}
                                          >
                                            <Tooltip
                                              title="Faculty Link-Click to Copy"
                                              placement="left-end"
                                            >
                                              <Button
                                                size="small"
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "6px,10px",
                                                }}
                                                value={apps.id}
                                                sx={{
                                                  background: "#337CCF",
                                                  "&:hover": {
                                                    background: "#1450A3",
                                                  },
                                                }}
                                                type="button"
                                                variant="contained"
                                                onClick={copyToClipboardtext}
                                              >
                                                Faculty
                                              </Button>
                                            </Tooltip>

                                            <Tooltip
                                              title="Student Link-Click to Copy"
                                              placement="right-end"
                                            >
                                              <Button
                                                size="small"
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "6px,10px",
                                                }}
                                                value={apps.id}
                                                type="button"
                                                variant="contained"
                                                sx={{
                                                  background: "#76549A",
                                                  "&:hover": {
                                                    background: "#313866",
                                                  },
                                                }}
                                                onClick={copyToClipboard}
                                              >
                                                Student
                                              </Button>
                                            </Tooltip>
                                          </Box>
                                        </StyledTableCell>
                                      </BootstrapTooltip>

                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip title="Appointment Status">
                                          <p>
                                            {" "}
                                            {apps.status.id === 1 ? (
                                              <p
                                                style={{
                                                  background: "#316B83",
                                                  color: "white",
                                                  padding: "8px 7px",
                                                  borderRadius: "20px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                New
                                              </p>
                                            ) : apps.status.id === 2 ? (
                                              <p
                                                style={{
                                                  background: "purple",
                                                  color: "white",
                                                  padding: "8px 6px",
                                                  borderRadius: "20px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Merged
                                              </p>
                                            ) : apps.status.id === 3 ? (
                                              <p
                                                style={{
                                                  background: "#1363DF",
                                                  color: "white",
                                                  padding: "8px 6px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Confirmed
                                              </p>
                                            ) : apps.status.id === 4 ? (
                                              <p
                                                style={{
                                                  background: "#379237",
                                                  color: "white",
                                                  padding: "8px 5px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Conducted
                                              </p>
                                            ) : apps.status.id === 5 ? (
                                              <p
                                                style={{
                                                  background: "#537188",
                                                  color: "white",
                                                  padding: "8px 5px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Date Overdue
                                              </p>
                                            ) : apps.status.id === 6 ? (
                                              <p
                                                style={{
                                                  background: "#9D5C0D",
                                                  color: "white",
                                                  padding: "8px 5px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Yet to Approach
                                              </p>
                                            ) : apps.status.id === 7 ? (
                                              <p
                                                style={{
                                                  background: "#E86A33",
                                                  color: "white",
                                                  padding: "8px 5px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Pending
                                              </p>
                                            ) : apps.status.id === 8 ? (
                                              <p
                                                style={{
                                                  background: "#BE0000",
                                                  color: "white",
                                                  padding: "8px 5px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Not Interested
                                              </p>
                                            ) : apps.status.id === 9 ? (
                                              <p
                                                style={{
                                                  background: "#898121",
                                                  color: "white",
                                                  padding: "8px 5px",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                Unmerge
                                              </p>
                                            ) : (
                                              <></>
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip title="Year Of Passout">
                                          <p>
                                            {apps && apps.year_of_passout
                                              ? apps.year_of_passout
                                              : "NA"}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps.registered_students === null
                                                ? 0
                                                : apps.registered_students}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <StudentRegisterList
                                                appointment_idx={apps.id}
                                                level="5"
                                              ></StudentRegisterList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.other_college_registered_students_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <OtherCollegeRegisterList
                                                appointment_idx={apps.id}
                                                otherCollegeReg="5"
                                              ></OtherCollegeRegisterList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip title="TPO Follow-up">
                                          <p> Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip>
                                          <p>
                                            {" "}
                                            {Object.keys(apps.college_spocs)
                                              .length < 1 ? (
                                              <Box
                                                sx={{
                                                  color: "#EB455F",
                                                }}
                                              >
                                                No Spoc
                                              </Box>
                                            ) : (
                                              <>
                                                <ContactModal
                                                  mobile={
                                                    Object.entries(
                                                      Object.values(
                                                        apps.college_spocs
                                                      )
                                                    )[0][1].mobile
                                                  }
                                                  email={
                                                    Object.entries(
                                                      Object.values(
                                                        apps.college_spocs
                                                      )
                                                    )[0][1].email
                                                  }
                                                  name={
                                                    Object.entries(
                                                      Object.values(
                                                        apps.college_spocs
                                                      )
                                                    )[0][1].name
                                                  }
                                                />
                                              </>
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={Styles.appToAddAppointment}
                                      >
                                        <BootstrapTooltip>
                                          <p>
                                            {/* {" "}
                                                {apps.addressing_datetime ===
                                                  null ||
                                                apps.addressing_datetime ===
                                                  "" ? (
                                                  <Box
                                                    sx={{
                                                      color:
                                                        "#EB455F",
                                                    }}
                                                  >
                                                    NA
                                                  </Box>
                                                ) : (
                                                  apps.addressing_datetime.replace(
                                                    "+0000",
                                                    ""
                                                  )
                                                )} */}

                                            {apps.addressing_datetime == "" ? (
                                              <Tooltip title="Create Addressing">
                                                <Link
                                                  to="/create_newaddressing"
                                                  style={{
                                                    textDecoration: "none",
                                                    padding: "5px 3px",
                                                    borderRadius: "10px",
                                                    color: "Crimson",
                                                  }}
                                                >
                                                  NA
                                                  <span>
                                                    <FaPlusCircle
                                                      fontSize={18}
                                                      color="#537FE7"
                                                      style={{
                                                        cursor: "pointer",
                                                        width: "40px",
                                                      }}
                                                    />
                                                  </span>
                                                </Link>
                                              </Tooltip>
                                            ) : (
                                              <>
                                                {/* {apps.addressing_datetime.replace(
                                                      "+0000 UTC",
                                                      ""
                                                    )} */}
                                                {moment
                                                  .utc(apps.addressing_datetime)
                                                  .local()
                                                  .format("DD-MM-yyyy hh:mm A")}
                                                <Tooltip title="Edit">
                                                  {/* <AddressingEditModal
                                                        stateAddresssing={
                                                          apps
                                                        }
                                                        status={
                                                          apps
                                                            .status
                                                            .id
                                                        }
                                                      /> */}
                                                  <AddressingEditModal
                                                    stateAddresssing={apps}
                                                    // status={
                                                    //   apps
                                                    //     .status
                                                    //     .id
                                                    // }
                                                    status={
                                                      Object.keys(
                                                        apps.addressing_status
                                                      )[0]
                                                    }
                                                  />
                                                </Tooltip>
                                              </>
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        {/* <h4 style={{ color: "red" }}>Loading Table Data...</h4> */}
                        <h4 style={{ color: "red" }}>No Data Found...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
          <aside style={{ width: "15%" }} className="actionBlock">
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  {!collegeFilter && country && country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <p>NA</p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.appToAddCountry}>
                              <p>NA</p>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                      {expanded.length > 0
                        ? states.map((stat, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Status">
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.appToAddState}
                                  >
                                    <BootstrapTooltip title="Action">
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == stat.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Status">
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.appToAddDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Action">
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )

                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Status">
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.appToAddCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Action">
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>
                                                        {appointments != null &&
                                                        appointments.length > 0
                                                          ? appointments.map(
                                                              (
                                                                apps,
                                                                indApps
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <TableRow
                                                                      key={
                                                                        indApps +
                                                                        1
                                                                      }
                                                                    >
                                                                      <StyledTableCell
                                                                        className={
                                                                          Styles.appToAddAppointment1
                                                                        }
                                                                      >
                                                                        <BootstrapTooltip title="Status">
                                                                          <p>
                                                                            NA
                                                                          </p>
                                                                        </BootstrapTooltip>
                                                                      </StyledTableCell>

                                                                      <StyledTableCell
                                                                        className={
                                                                          Styles.appToAddAppointment1
                                                                        }
                                                                      >
                                                                        <BootstrapTooltip title="Status">
                                                                          <p>
                                                                            NA
                                                                          </p>
                                                                        </BootstrapTooltip>
                                                                      </StyledTableCell>
                                                                    </TableRow>
                                                                  </>
                                                                );
                                                              }
                                                            )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : collegeFilter && colleges.length > 0 ? (
                    colleges.map((coll, indColl) => {
                      return (
                        <>
                          <TableRow key={indColl + 1}>
                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Status">
                                <p>NA</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell className={Styles.appToAddCollege}>
                              <BootstrapTooltip title="Action">
                                <p>NA</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>
                          {appointments != null && appointments.length > 0
                            ? appointments.map((apps, indApps) => {
                                return (
                                  <>
                                    <TableRow key={indApps + 1}>
                                      <StyledTableCell
                                        className={Styles.appToAddAppointment1}
                                      >
                                        <BootstrapTooltip title="Status">
                                          <p>NA</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={Styles.appToAddAppointment1}
                                      >
                                        <BootstrapTooltip title="Status">
                                          <p>NA</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        {/* <h4 style={{ color: "red" }}>Loading Table Data...</h4> */}
                        <h4 style={{ color: "red" }}>No Data Found...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};
export default CountAppointToAddressing;
