import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Modal,
} from "@mui/material";
import { FormControl } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../../apis/AxiosInstance";
import { FaRegTrashAlt, FaPlus } from "react-icons/fa";
import moment from "moment/moment";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const CreateAddressingForm = () => {
  // state values
  const [addressing_date, setAddressing_date] = useState("");
  const [addressing_time, setAddressing_time] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [rounds, setRounds] = useState([{ id: 1, label: "Select Round 1" }]);
  const [valuesAdd, setValuesAdd] = useState([""]);
  const [searchTerm, setSearchTerm] = useState("");
  const [send_message, SetSend_message] = useState(false);
  const [send_email, setSend_email] = useState(false);
  const [assign_quiz, setAssign_quiz] = useState(false);
  const [is_broadcaster, setIsBroadCaster] = useState(false);
  const navigate = useNavigate();

  // current college Details : State values
  const [currentColgID, setCurrentColgID] = useState([]);

  // all appointments
  const [selectedCollege_nameDate, setSelectedCollege_nameDate] =
    useState(null);
  const [selectedColgOptions, setSelectedColgOptions] = useState([]);

  // State values for API calls
  const [appRoundsOptions, setAppRoundsOptions] = useState([]);

  const [currColgOptions, setCurrColgOptions] = useState([]);
  // const [currColgOptions, setCurrColgOptions] = useState(null);

  const [colgOptions, setColgOptions] = useState([]);
  const [addFacultyOptions, setAddFacultyOptions] = useState([]);
  const [addressing_faculty, setAddressing_faculty] = useState([]);
  const [collegeExecutiveOptions, setCollegeExecutiveOptions] = useState([]);
  const [college_Executive, setCollege_Executive] = useState([]);

  const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");
  const [searchCollegeExecutive, setSearchCollegeExecutive] = useState("");

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState("");

  const [videoData, setVideoData] = useState(null);
  const [videoOption, setVideoOption] = useState([]);
  const [videoSearch, setVideoSearch] = useState("");
  const [videoError, setVideoError] = useState(false);

  // ==============================================add rounds==============================================

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const addRound = () => {
    if (valuesAdd.length < 3) {
      setValuesAdd([...valuesAdd, ""]);
    }
  };

  const removeRound = (idToRemove) => {
    let list = [...valuesAdd];
    list.splice(idToRemove, 1);
    setValuesAdd(list);
  };

  const handleValueChange = (id, newValue) => {
    setValuesAdd({ ...valuesAdd, [id]: newValue });
    let newval = valuesAdd.map((item, ind) => {
      return ind === newValue ? id : item;
    });
    setValuesAdd(newval);
  };
  // ==============================================add rounds==============================================

  //!===========================================FUNCTION TO HANDLE FORM SUBMITION=========================
  let handleSubmit = async (ex) => {
    // event.preventDefault();

    let formData = new FormData();

    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    let addFac = [];
    addressing_faculty.map((value) => {
      formData.append("addressing_faculty_id", value.id);
    });
    college_Executive.map((value) => {
      formData.append("college_executive_id", value.id);
    });
    try {
      if (currentColgID.length === 0) {
        toast.error("Please select a current appointment");
        return;
      }

      const time = new Date(addressing_date + "T" + addressing_time);
      const addressDateTime = moment(time)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");
      if (currentColgID !== null) {
        currentColgID.forEach((e, i) => {
          formData.append("appointment_id", e.id);
        });
      }
      if (selectedCollege_nameDate !== null) {
        selectedCollege_nameDate.forEach((e, i) => {
          formData.append("appointment_id", e.id);
        });
      }

      if (is_broadcaster && (!videoData || !videoData.id)) {
        setVideoError(true); // Set error state for video selection
        toast.error("Video is required when broadcaster is selected");
        return;
      } else {
        setVideoError(false); // Clear error state if video is selected
      }

      if (videoData && videoData.id) {
        formData.append("video_id", videoData.id);
      }
      formData.append("date_time", addressDateTime);
      formData.append("send_email", send_email);
      formData.append("send_message", send_message);
      formData.append("is_broadcaster", is_broadcaster);
      formData.append("is_offline", examTypeList === "1" ? true : false);
      valuesAdd.forEach((e, i) => {
        formData.append(`round${i + 1}_id`, Number.parseInt(e));
      });
    
      if (
        (examTypeList === "1" &&
          college_Executive &&
          college_Executive.length > 0) ||
        (examTypeList === "2" &&
          addressing_faculty &&
          addressing_faculty.length > 0)
      ) {
        await AxiosInstance.post("/addressing", formData, config)
          .then((response) => {
            toast.success("Successfully Addressing is submitted");

            setTimeout(() => {
              if (ex === "x") {
                navigate("/assign-quiz?to=2", { state: response.data.data });
              } else if (ex === "y") {
                navigate("/");
              }
              // navigate("/");
              // navigate("/quizTree");
            }, 1600);
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.error);
          });
      } else {
        if (examTypeList === "") {
          toast.error("please select mode of addressing");
        } else if (examTypeList === "1") {
          toast.error("please select a college executive");
        } else {
          toast.error("please select a addressing faculty");
        }
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
    // if((examTypeList==='1'&&college_Executive && college_Executive.length>0 && addressing_faculty && addressing_faculty.length>0) ||(examTypeList==='2'&&addressing_faculty && addressing_faculty.length>0 &&college_Executive && college_Executive.length>0)){
    //   await AxiosInstance.post("/addressing", formData, config)
    //   .then((response) => {
    //     toast.success("Successfully Addressing is submitted");

    //     setTimeout(() => {
    //       if (ex === "x") {
    //         navigate("/assign-quiz?to=2", { state: response.data.data });
    //       } else if (ex === "y") {
    //         navigate("/");
    //       }
    //       // navigate("/");
    //       // navigate("/quizTree");
    //     }, 1600);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast.error(error.response.data.error);
    //   });
    //  }else{
    //   if(examTypeList===""){
    //     toast.error('please select mode of addressing')
    //   }else if(examTypeList==='1'){
    //     toast.error('please select a college executive and addressing faculty')
    //   }else{
    //     toast.error('please select a addressing faculty and college executive')
    //   }
    //  }
    // } catch (error) {
    //   toast.error(error.response.data.error);
    // }
  };

  //! ================================================API CALLS=============================================================

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (videoSearch !== "") {
        let data = await AxiosInstance.get(
          `/videos?video_type_id=1&search_keyword=${videoSearch}&limit=10`
        );
        let finalData = data.data.data;
        setVideoOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/videos?video_type_id=1&limit=10`);
        let finalData = data.data.data;
        setVideoOption(finalData);
      }
    };

    fetchData();
  }, [videoSearch]);

  useEffect(() => {
    let fetchData = async (e) => {
      // College appointment rounds api
      let appointmentRounds = await AxiosInstance.get(
        `/college_appointment_rounds`
      );
      let finalColgRoundsData = appointmentRounds.data.data;
      setAppRoundsOptions(finalColgRoundsData);

      // get current appointment

      let currColgID = window.sessionStorage.getItem("appointment_date");
      let appDate = currColgID.slice(0, currColgID.length - 12);
      let appTime = currColgID.slice(11, currColgID.length - 8);
      let currAppointment = await AxiosInstance.get(
        `/appointment_mini?appointment_date=${appDate}&appointment_time=${appTime}&limit=10`
      );
      let currAppointmentData = currAppointment.data.data;
      setCurrColgOptions(currAppointmentData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchAddressingFaculty !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setAddFacultyOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setAddFacultyOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchAddressingFaculty]);

  useEffect(() => {
    let fetchData = async () => {
      // College Executive api
      if (searchCollegeExecutive !== "") {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&search_keyword=${searchCollegeExecutive}&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        if (collegeExecutiveData) {
          setCollegeExecutiveOptions(collegeExecutiveData);
        }
      } else {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        setCollegeExecutiveOptions(collegeExecutiveData);
      }
    };
    fetchData();
  }, [searchCollegeExecutive]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (searchTerm && searchTerm.length > 2) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?search_keyword=${searchTerm}&limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        if (finalAppointments) {
          setColgOptions(finalAppointments);
        }
      } else if (searchTerm === "" || searchTerm === null) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      } else {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      }
    };
    fetchData1();
  }, [searchTerm, selectedColgOptions]);
  //! ================================================API CALLS=============================================================

  // to handle Date and Time
  let handleAddDate = (e) => {
    setAddressing_date(e.target.value);
  };
  let handleAddTime = (e) => {
    setAddressing_time(e.target.value);
  };

  // Reset Data
  let resetData = (e) => {
    navigate("/create_collegeappointment");
  };

  //checkbox
  const handleSendEmailChange1 = (event) => {
    setSend_email(event.target.checked);
  };
  const handleSendMsgChange1 = (event) => {
    SetSend_message(event.target.checked);
  };
  const handleSendBroadCast = (event) => {
    setIsBroadCaster(event.target.checked);
  };
  const handleAssignQuizChange1 = (event) => {
    setAssign_quiz(event.target.checked);
  };

  const checkSubmit = () => {
    if (assign_quiz) {
      handleSubmit("x");
    } else {
      setOpenModal(true);
    }
  };

  return (
    <div>
      <section style={{ width: "100%", height: "auto" }}>
        <article
          style={{
            width: "98%",
            height: "auto",
            margin: "0 auto",
            marginTop: "5%",
          }}
        >
          <Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                checkSubmit();
              }}
            >
              <Paper variant="outlined" style={{border:"1px solid gray"}}>
                <Typography
                  sx={{
                    // marginBottom: 2,
                    background: "#DDE6ED",
                    color: "#081f37",
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "center",         
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    borderBottom: "2px solid #9BA4B5",
                    fontSize:"25px"
                  }}
                >
                  CREATE ADDRESSING
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper sx={{ padding: 1 }}>
                      <section>
                        <Box
                          component={"section"}
                          sx={{
                            boxShadow:
                              " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                              padding: "2px",
                              marginBottom: "20px",
                            outline: "1px solid #D6E4E5",
                            borderLeft: "6px solid #9BA4B5",
                            borderRadius: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              marginBottom: 1,
                              fontWeight: "bold",
                              color: "#00337C",
                            }}
                          >
                            Current Appointment Block
                          </Typography>

                          <Grid
                            container
                            spacing={1}
                            item={12}
                            alignItems="flex-end"
                            style={{ marginBottom: "15px" }}
                          >
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Autocomplete
                                sx={{ width: "99%" }}
                                multiple
                                required
                                value={currentColgID}
                                onChange={(event, newValue) => {
                                  setCurrentColgID([...newValue]);
                                }}
                                options={currColgOptions ? currColgOptions : []}
                                getOptionLabel={(option) => option.name}
                                renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((option, index) => (
                                    <Chip
                                      label={option.name}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Current Appointment"
                                    placeholder="Select Current Appointment"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </section>
                      <Box
                        component={"section"}
                        sx={{
                          boxShadow:
                            " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            padding: "2px",
                            marginBottom: "20px",
                          outline: "1px solid #D6E4E5",
                          borderLeft: "6px solid #9BA4B5",
                          borderRadius: "10px",

                        }}
                      >
                        <Typography
                          sx={{
                            // marginBottom: 1,
                            fontWeight: "bold",
                            color: "#00337C",
                          }}
                        >
                          All Appointments Block
                        </Typography>

                        <Grid
                          container
                          spacing={1}
                          item={12}
                          xs={12}
                          alignItems="flex-end"
                          // style={{ marginBottom: "15px" }}
                        >
                          <Grid item md={12}>
                            <Item
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                              }}
                            >
                              <Autocomplete
                                multiple
                                required
                                options={colgOptions ? colgOptions : []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                  <li {...props} style={{ width: "150%" }}>
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Multiple Appointments"
                                    placeholder="Select Multiple Appointments"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                                value={selectedColgOptions}
                                onChange={(e, value, ex, ey) => {
                                  if (ex === "selectOption") {
                                    if (selectedColgOptions.length === 0) {
                                      setSelectedColgOptions(value);
                                      setSelectedCollege_nameDate(value);
                                    } else if (
                                      selectedColgOptions.filter(
                                        (e) => e.id === ey.option.id
                                      ).length === 0
                                    ) {
                                      setSelectedColgOptions(value);
                                      setSelectedCollege_nameDate(value);
                                    } else {
                                      setSelectedColgOptions(
                                        selectedColgOptions
                                      );
                                    }
                                  } else {
                                    setSelectedColgOptions(value);
                                    setSelectedCollege_nameDate(value);
                                  }
                                }}
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid
                        container
                        spacing={2}
                        item={12}
                        sx={{ marginTop: "5px" }}
                      >
                        <Grid item md={1.8} xs={12}>
                          {/* <Item> */}
                          <TextField
                            required
                            type="datetime-local"
                            fullWidth
                            id="addressingDate"
                            label="Addressing Date"
                            name="addressing_date"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            autoFocus
                            value={addressing_date}
                            onChange={handleAddDate}
                            inputProps={{
                              min: new Date().toISOString().split("T")[0],
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                          {/* </Item> */}
                        </Grid>
                        <Grid item md={1.8} xs={12}>
                          <TextField
                            required
                            type="datetime-local"
                            fullWidth
                            id="addressingTime"
                            label="Addressing Time"
                            name="addressing_date"
                            onFocus={(e) => (e.target.type = "time")}
                            onBlur={(e) => (e.target.type = "text")}
                            autoFocus
                            value={addressing_time}
                            onChange={handleAddTime}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={2} style={{ marginTop: "-1.2%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Mode Of Addressing
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              displayEmpty
                              className="my-select"
                              inputProps={{
                                "aria-label": "Select an option",
                              }}
                              value={examTypeList}
                              onChange={(e) => {
                                setExamTypeList(e.target.value);
                              }}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 5)", 
                                },
                              }}
                            >
                              <MenuItem value="" disabled>
                                <span className="my-select-placeholder">
                                  Select Mode
                                </span>
                              </MenuItem>
                              {Object.entries(examTypeListOption).map(
                                ([key, value]) => (
                                  <MenuItem key={key} value={key}>
                                    {value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          md={3.2}
                          xs={12}
                          onChange={(e) => {
                            setSearchCollegeExecutive(e.target.value);
                          }}
                        >
                          <Autocomplete
                            multiple
                            options={
                              collegeExecutiveOptions
                                ? collegeExecutiveOptions
                                : []
                            }
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.full_name}
                            renderOption={(props, option) => (
                              <li {...props}>{option.full_name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Multiple College Executive"
                                placeholder="Select Multiple College Executive"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            )}
                            value={college_Executive}
                            onChange={(e, value) => {
                              setCollege_Executive(value);
                            }}
                            disabled={examTypeList == 2}
                          />
                        </Grid>
                        <Grid
                          item
                          md={3.2}
                          xs={12}
                          onChange={(e) => {
                            setSearchAddressingFaculty(e.target.value);
                          }}
                        >
                          <Autocomplete
                            required
                            multiple
                            options={addFacultyOptions ? addFacultyOptions : []}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.full_name}
                            renderOption={(props, option) => (
                              <li {...props}>{option.full_name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Multiple Addressing Faculty"
                                placeholder="Select Multiple Addressing Faculty"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            )}
                            value={addressing_faculty}
                            onChange={(e, value) => {
                              setAddressing_faculty(value);
                            }}
                            disabled={examTypeList == 1}
                          />
                        </Grid>
                        {/* <Grid
                          item
                          md={3}
                          onChange={(e) => {
                            setVideoSearch(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={videoOption}
                            value={videoData}
                            name="videoOption"
                            required
                            onChange={(event, newData_xy) => {
                              setVideoData(newData_xy);
                              if (newData_xy) {
                                setVideoError(false); 
                              }
                            }}
                            getOptionLabel={(option) => option.title}
                            disabled={!is_broadcaster}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Video"
                                variant="outlined"
                                error={videoError} 
                                helperText={
                                  videoError
                                    ? "Video is required when broadcaster is selected"
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid> */}
                        <Grid item md={12} xs={12}>
                          <h3>Interview Rounds</h3>
                        </Grid>

                        <Grid container spacing={1} item>
                          {valuesAdd.map((item, ind) => (
                            <Grid
                              item
                              md={2.75}
                              xs={12}
                              style={{
                                display: "inline-flex",
                                position: "relative",
                              }}
                            >
                              <FormControl
                                style={{ width: "100%" }}
                                key={ind + 1}
                              >
                                <InputLabel
                                  id={`demo-simple-select-label-${ind}`}
                                  sx={{
                                    textAlign: "left",
                                    fontWeight: "600",
                                    color: "#FC7300",
                                  }}
                                >
                                  {`Round ${ind + 1}`}{" "}
                                </InputLabel>
                                <Select
                                  labelId={`demo-simple-select-label-${ind}`}
                                  id="demo-simple-select"
                                  required
                                  value={item}
                                  onChange={(event) =>
                                    handleValueChange(event.target.value, ind)
                                  }
                                  displayEmpty
                                  className="my-select"
                                  inputProps={{
                                    "aria-label": "Select an option",
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 10)", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 10)", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 5)", 
                                    },
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    <span className="my-select-placeholder">
                                      Select Rounds
                                    </span>
                                  </MenuItem>
                                  {appRoundsOptions.map((roundsData, i) => (
                                    <MenuItem
                                      key={roundsData.ID}
                                      value={roundsData.ID}
                                    >
                                      {roundsData.name}
                                    </MenuItem>
                                  ))}
                                </Select>

                                {valuesAdd.length !== 1 && (
                                  <Button
                                    style={{
                                      position: "absolute",
                                      bottom: "-22px",
                                      right: "40%",
                                    }}
                                    onClick={() => removeRound(ind)}
                                  >
                                    <FaRegTrashAlt style={{ color: "red" }} />
                                  </Button>
                                )}
                                {valuesAdd.length - 1 === ind && (
                                  <Grid
                                    item
                                    md={1}
                                    style={{
                                      display:
                                        valuesAdd.length > 4
                                          ? "none"
                                          : "inline-flex",
                                      position: "absolute",
                                      right: "-4px",
                                      top: "25%",
                                    }}
                                  >
                                    <Button onClick={addRound}>
                                      <FaPlus />
                                    </Button>
                                  </Grid>
                                )}
                              </FormControl>
                            </Grid>
                          ))}
                        </Grid>

                        {/* trialll */}

                        <Grid item md={12} style={{ marginTop: "0.5%" }}>
                          <Grid container spacing={4}>
                            <Grid item>
                              <Checkbox
                                name="send_message"
                                checked={send_message}
                                onChange={handleSendMsgChange1}
                                id="send_msg"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              />
                              <label
                                htmlFor="sendMsg"
                                style={{ fontSize: "16px" }}
                              >
                                Send Message
                              </label>
                            </Grid>
                            <Grid item>
                              <Checkbox
                                name="send_email"
                                checked={send_email}
                                onChange={handleSendEmailChange1}
                                id="send_mail"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              />{" "}
                              <label
                                htmlFor="sendMail"
                                style={{ fontSize: "16px" }}
                              >
                                Send Mail
                              </label>
                            </Grid>
                            <Grid item>
                              <Checkbox
                                name="is_broadcaster"
                                checked={is_broadcaster}
                                onChange={handleSendBroadCast}
                                id="is_broadcaster"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                                // disabled={examTypeList == 1}
                              />{" "}
                              <label
                                htmlFor="broadcaster"
                                style={{ fontSize: "16px" }}
                              >
                                Video BroadCasting
                              </label>
                            </Grid>
                            <Grid
                          item
                          md={3}
                          onChange={(e) => {
                            setVideoSearch(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={videoOption}
                            value={videoData}
                            name="videoOption"                           
                            onChange={(event, newData_xy) => {
                              setVideoData(newData_xy);
                              if (newData_xy) {
                                setVideoError(false); 
                              }
                            }}
                            getOptionLabel={(option) => option.title}
                            disabled={!is_broadcaster}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Video"
                                variant="outlined"
                                required={is_broadcaster}
                                error={videoError} 
                                helperText={
                                  videoError
                                    ? "Video is required when broadcaster is selected"
                                    : ""
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={12} xs={12}  style={{ marginTop: "-0.5%" }}>
                          <Grid item>
                            <Checkbox
                              name="assign_quiz"
                              checked={assign_quiz}
                              onChange={handleAssignQuizChange1}
                              id="assign_quiz"
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />{" "}
                            <label
                              htmlFor="assignQuiz"
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              Assign Quiz
                            </label>
                          </Grid>
                          <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            BackdropProps={{
                              style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "30%",
                                minHeight: "20vh",
                                maxHeight: "50%",
                                backgroundColor: "white",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                padding: 10,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-around",
                                borderRadius: "5px",
                                overflowY: "auto",
                              }}
                            >
                              <h3 style={{ textAlign: "center" }}>
                                Do you want to continue without assigning the
                                quiz?
                              </h3>
                              <div>
                                <button
                                  style={{
                                    background: "crimson",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    padding: "8px 20px",
                                    border: "none",
                                    borderRadius: "5px",
                                  }}
                                  onClick={() => setOpenModal(false)}
                                >
                                  Cancel
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  style={{
                                    background: "green",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    border: "none",
                                    padding: "8px 20px",
                                    borderRadius: "5px",
                                  }}
                                  onClick={() => handleSubmit("y")}
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </Modal>
                        </Grid>

                        <Grid
                          xs={12}
                          item
                          spacing={2}
                          style={{
                            textAlign: "right",
                            padding: "5px",
                          }}
                        >
                          <Button
                            onClick={resetData}
                            type="button"
                            variant="contained"
                            style={{
                              width: "10%",
                              marginRight: "3%",
                              backgroundColor: "#E74646",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              width: "10%",
                              backgroundColor: "#1976D2",
                              fontWeight: "bold",
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Box>
        </article>
      </section>
    </div>
  );
};

export default CreateAddressingForm;
