import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Button,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Grid,
  TextField,
  Autocomplete,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Typography
} from "@mui/material";
// import Style from "../student/list.module.css";
import AxiosInstance from "../apis/AxiosInstance";
import { toast } from "react-toastify";
import moment from "moment";
import Style from "./../superAdmin/lists.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link, useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function MissedCallList() {
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  let [userList, setUserList] = useState([]);
  let [searchFilter, setSearchFilter] = useState("");

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [roleOption, setRoleOption] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [searchRoleName, setSearcRoleName] = useState("");
  const [calledBack, setCalledBack] = useState(false);

  const [searchDisable, setSearchDisable] = useState(false);

  const [load, setLoad] = useState(false);

  const handleCalledBackChange = (event) => {
    setCalledBack(event.target.checked);
  };

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleIds = "1,5";
        let response;
        if (searchRoleName !== "") {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=${roleIds}&search_keyword=${searchRoleName}&limit=10`
          );
        } else {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=${roleIds}&limit=10`
          );
        }
        const finalStateData_X = response.data.data || [];
        setRoleOption(finalStateData_X);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setRoleOption([]);
      }
    };
    fetchData();
  }, [searchRoleName]);

  const [defaultState, setDefaultState] = useState({
    userList: [],
    totalLength: 0,
  });
  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/get_missed_call_record?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setUserList(finalData);
        setTotalLength(data.data.filtered_count);
        setDefaultState({
          userList: finalData,
          totalLength: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);
  const handleChangePage = async (event, newPage) => {
    let endpoint = `/get_missed_call_record?page=${newPage}`;
    setLoad(true);
    if (searchFilter) {
      endpoint += `&number=${searchFilter}`;
    }
    if (roleData !== null) {
      endpoint += `&author_id=${roleData.id}`;
    }
    if (unitNameData !== null) {
      endpoint += `&unit_id=${unitNameData.ID}`;
    }
    if (calledBack) {
      endpoint += `&call_back=${calledBack}`;
    }
    try {
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setUserList(finalData);
      setTotalLength(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };
  // const handleSearch = async (e) => {
  //   const term = e.target.value;
  //   setSearchFilter(term);
  //   if (term && term.length > 2) {
  //     let endpoint = `/get_missed_call_record?page=${1}`;
  //     if (term) {
  //       if (term.includes("@")) {
  //         endpoint += `&email=${term}`;
  //       } else if (!isNaN(term)) {
  //         endpoint += `&number=${term}`;
  //       }
  //     }
  //     try {
  //       const { data } = await AxiosInstance.get(endpoint);
  //       let finalData = data.data;
  //       setUserList(finalData);
  //       setTotalLength(data.filtered_count);
  //       setPage(1);
  //     } catch (error) {
  //       toast.error("something went wrong");
  //     }
  //   } else {
  //     setUserList(defaultState.userList);
  //     setTotalLength(defaultState.totalLength);
  //     setPage(1);
  //   }
  // };

  const handleSearch = async (e) => {
    let endpoint = `/get_missed_call_record?page=${1}`;
    setIsSearchDisabled(true);
    try {
      if (!searchFilter && !unitNameData && !roleData && !calledBack) {
        toast.warning("Select at least one data to filter.");
        return;
      }

      if (searchFilter) {
        endpoint += `&number=${searchFilter}`;
      }

      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (roleData !== null) {
        endpoint += `&author_id=${roleData.id}`;
      }
      if (calledBack) {
        endpoint += `&call_back=${calledBack}`;
      }
      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      setUserList(finalData);
      setTotalLength(data.filtered_count);
      setPage(1);
      setSearchDisable(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchDisable(false);
    }
  };

  let handleClearFilterData = async () => {
    setSearchFilter("");
    setUnitNameData(null);
    setRoleData(null);
    setIsSearchDisabled(false);
    setCalledBack(false);
    let endpoint = `/get_missed_call_record?page=${page}`;

    try {
      let data = await AxiosInstance.get(endpoint);
      let finalData = data.data.data;
      setUserList(finalData);
      setTotalLength(data.data.filtered_count);
      setDefaultState({
        userList: finalData,
        totalLength: data.data.filtered_count,
      });
      if (searchFilter) setPage(1);
      setSearchDisable(false);
    } catch (error) {
      console.log(error);
    }

    let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
    let finalStateData_X = stateData_x.data.data;
    setUnitNameOption(finalStateData_X);

    const roleIds = "1,5";
    let response;
    response = await AxiosInstance.get(
      `/user_mini?role_ids=${roleIds}&limit=10`
    );
    const finalStateData_X1 = response.data.data || [];
    setRoleOption(finalStateData_X1);
  };

  const totalPages = Math.ceil(totalLength / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "4.5%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>
        Missed Call List
        <span
          style={{
            color: "#06113C",
            fontSize: "20px",
            marginLeft: "25%",
            fontWeight: "bold",
          }}
        >
          Total Count: {totalLength}
        </span>
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            marginLeft: "25%",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </h1>

      <Paper
        style={{
          width: "100%",
          margin: "0 auto",
          border: "1px solid #111",
          marginTop: filterVisible ? "-1%" : "-1%",
        }}
      >
        {filterVisible && (
          <Grid container gap={1} style={{ marginTop: "1%" }}>
            <Grid md={2} style={{ paddingLeft: "5px" }}>
              <TextField
                placeholder=" Search By Number"
                label="Search By Number"
                size="small"
                fullWidth
                value={searchFilter}
                name="searchFilter"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 10, maxLength: 10 }}
                onChange={(e) => {
                  setSearchFilter(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchDisable}
              />
            </Grid>

            <Grid md={2} onChange={(e) => setSearchUnitName(e.target.value)}>
              <Autocomplete
                options={unitNameOption}
                // disabled={searchDisable}
                value={unitNameData}
                size="small"
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unit Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid md={2.0} onChange={(e) => setSearcRoleName(e.target.value)}>
              <Autocomplete
                options={roleOption}
                // disabled={searchDisable}
                value={roleData}
                size="small"
                name="roleOption"
                onChange={(event, newData_xy) => {
                  setRoleData(newData_xy);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option.full_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Author Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid md={1.2} style={{ paddingLeft: "10px" }}>               
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={calledBack}
                      onChange={handleCalledBackChange}
                      name="calledBack"
                      color="primary"
                      // disabled={searchDisable}
                    />
                  }
                  label={
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      Called back
                    </Typography>
                  }
                />
              </Grid>
            <Grid md={0.5}>
              <Button
                onClick={handleSearch}
                variant="contained"
                size="small"
                fullWidth
                // disabled={isSearchDisabled}
                // style={{
                //   backgroundColor: isSearchDisabled ? "gray" : "#003285",
                //   color: "#fff",
                // }}
                style={{
                  backgroundColor: "#003285",
                  color: "#fff",
                }}
                // disabled={searchDisable}
              >
                Search
              </Button>
            </Grid>
            <Grid md={0.5}>
              <Button
                onClick={handleClearFilterData}
                variant="contained"
                color="warning"
                fullWidth
                size="small"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}

        <TableContainer
          component={Paper}
          style={{
            maxHeight: window.innerWidth > 1800 ? 680 : 480,
            marginTop: "0.5%",
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  style={{ width: "100px", fontSize: "16px" }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ width: "200px", fontSize: "16px" }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontSize: "16px",
                    width: "200px",
                  }}
                >
                  Called Back
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontSize: "16px",
                    width: "200px",
                  }}
                >
                  Author
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ width: "200px", fontSize: "16px" }}
                >
                  Missed Call Date
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">
                    Loading...
                  </TableCell>
                </StyledTableRow>
              ) : userList && userList.length > 0 ? (
                userList.map((val, ind) => {
                  return (
                    <StyledTableRow key={ind + 1}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        {calculateSlNo(ind)}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        <p>
                          {" "}
                          {val.number === null || val.number === "" ? (
                            "N/A"
                          ) : (
                            <a
                              href={`tel:${val.number}`}
                              style={{
                                color: "#125B9A",
                                // textDecoration: "none",
                              }}
                            >
                              {val.number}
                            </a>
                          )}
                          <p>
                            {" "}
                            {val.call_handled === true ? (
                              <span
                                style={{
                                  color: "#fff",
                                  marginLeft: "2%",
                                  border: "none",
                                  background: "#ED6C02",
                                  borderRadius: "10px",
                                  padding: "4px 10px",
                                  fontSize: "10px",
                                  letterSpacing: "1px",
                                }}
                              >
                                Handled
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {Object.keys(val.missed_call_try).length === 0 ? (
                          "----"
                        ) : (
                          <p style={{ color: "green" }}>Called Back</p>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        {val?.author?.name ?? "N/A"}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        {val.missed_call_date === null ||
                        val.missed_call_date === ""
                          ? "NA"
                          : // : moment(val?.["missed_call_date"])
                            moment
                              .utc(toIsoFormat(val.missed_call_date))
                              .local()
                              .format("DD-MM-yyyy hh:mm A")}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            maxWidth: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0.5% auto",
          }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
