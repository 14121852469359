import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Chip,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import AxiosInstance from "../../../apis/AxiosInstance";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaUserCheck } from "react-icons/fa";
import moment from "moment/moment";
import Cookies from "js-cookie";
import OutlinedInput from "@mui/material/OutlinedInput";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CollegeAppointment() {
  // state values  for the autocomplete component
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedSpoc, setSelectedSpoc] = useState(null);
  const [selectedDegrees, setselectedDegrees] = useState([]);
  const [selectedStreams, setselectedStreams] = useState([]);
  // const [yop, setYop] = useState(null);
  const [expectedStudents, setExpectedStudents] = useState("");
  const [incubation_type, setincubation_type] = useState("");
  const [comment, setComment] = useState("");
  const [appointment_date, setAppointment_date] = useState("");
  const [appointment_time, setAppointment_time] = useState("");
  const [all_degree, setAll_Degree] = useState(false);
  const [all_streams, setAll_Streams] = useState(false);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [programmeData, setProgrammeData] = useState("");
  const [examTypeListOption, setExamTypeListOption] = useState([]);

  //search terms for state

  const [searchTermState, setSearchTermState] = useState("");
  const [searchTermDistrict, setSearchTermDistrict] = useState("");
  const [searchTermUniversity, setSearchTermUniversity] = useState("");
  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");
  const [degTerm, setDegTerm] = useState("");
  const [streamTerm, setStreamTerm] = useState(null);
  const [searchCollege, setSearchCollege] = useState("");

  const navigate = useNavigate();

  // for rendering the data from api
  const [universityOptions, setUniversityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [colgSpocOptions, setColgSpocOptions] = useState([]);
  const [degreeOptions, setDegreeOptions] = useState("");
  const [streamsOptions, setStreamsOptions] = useState("");
  // const [yopOptions, setYopOptions] = useState([]);
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  let [errorForProgramme, setErrorForProgramme] = React.useState(false);

  const [yop, setYop] = useState(null);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();

  // All degree and All Stream
  const [isDisabledDeg, setIsDisabledDeg] = useState(false);
  const [isDisabledStrm, setIsDisabledStrm] = useState(false);

  const handleCheckboxDegree = (event) => {
    setIsDisabledDeg(event.target.checked);
    setAll_Degree(event.target.checked);
  };
  const handleCheckboxStream = (event) => {
    setIsDisabledStrm(event.target.checked);
    setAll_Streams(event.target.checked);
  };

  //!FUNCTION TO HANDLE INPUT-CHANGE
  let handleChangeIncubation = (e) => {
    setincubation_type(e.target.value);
  };

  // to expectedStudents
  let handleStudents = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ""); // remove non-numeric characters
    if (inputValue.length <= 5) {
      setExpectedStudents(inputValue);
    }
  };

  // to handle Date and Time
  let handleDate = (e) => {
    setAppointment_date(e.target.value);
    if (!appointment_date === "") {
      setError(true);
    } else {
      setError(false);
    }
  };
  let handleTime = (e) => {
    setAppointment_time(e.target.value);
  };

  // Error Handling for Create Addressing Faculty
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isSubmitAddressing, setIsSubmitAddressing] = useState(false);

  //==================================FUNCTION TO HANDLE FORM SUBMITION=============================
  let handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      // let dateData = appointment_date + " " + appointment_time + " " + "+0000";
      const dateData = new Date(appointment_date + "T" + appointment_time);
      const appointmentDateTime = moment(dateData)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");

      if (expectedStudents.toString().startsWith("0")) {
        toast.error("Targeted Students cannot be zero");
        setIsSubmitting(false);
        return; // Stop further execution
      }

      if (incubation_type === "") {
        setError(true);
        setIsSubmitting(false);
      } else {
        setError(false);
        let payload = {
          state_id: selectedColgState.id,
          district_id: selectedColgDistrict.id,
          university_id: selectedUniversity.id,
          college_id: selectedCollege.id,
          programme_id: programmeData == 1 ? 1 : 2,
          college_spoc_id: [selectedSpoc.id],
          degree_id:
            all_degree === true ? [] : selectedDegrees.map((value) => value.id),
          stream_id:
            all_streams === true
              ? []
              : selectedStreams.map((value) => value.id),
          year_of_passout: Number.parseInt(yop),
          expected_students: Number.parseInt(expectedStudents),
          incubation_type: Number.parseInt(incubation_type),
          comment: comment,
          appointment_date: appointmentDateTime,
          all_degree: all_degree !== true ? false : true,
          all_stream: all_streams !== true ? false : true,
          unit_id: [unitNameData.ID],
        };
        await AxiosInstance.post("/appointment", payload, {
          "content-type": "multipart/form-data",
          Authorization: `${token}`,
        })
          .then((response) => {
            toast.success("Successfully college appointment data is submitted");
            if (response.status == 200) {
              setTimeout(() => {
                navigate("/");
              }, 1800);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.error);
            setIsSubmitting(false);
          });
      }
    } catch (error) {
      console.log(error.code);
      setIsSubmitting(false);
    }
  };
  //  =================================submit save==========================================
  // ===============================create addressing faculty starts=======================================

  let handleCreateFaculty = async (event) => {
    event.preventDefault();
    setIsSubmitAddressing(true);
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      const dateData = new Date(appointment_date + "T" + appointment_time);
      const appointmentDateTime = moment(dateData)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");

      if (expectedStudents.toString().startsWith("0")) {
        toast.error("Targeted Students cannot be zero");
        setIsSubmitAddressing(false);
        return; // Stop further execution
      }

      if (
        !selectedColgState ||
        !selectedColgDistrict ||
        !selectedUniversity ||
        !selectedCollege ||
        !selectedSpoc ||
        !yop ||
        !expectedStudents ||
        !incubation_type ||
        !appointment_date ||
        !appointment_time
      ) {
        setError(true);
        toast.error("Please fill out all required fields.");
        setIsSubmitAddressing(false);
        return;
      } else {
        setError(false);
      }

      let payload = {
        state_id: selectedColgState.id,
        district_id: selectedColgDistrict.id,
        university_id: selectedUniversity.id,
        college_id: selectedCollege.id,
        programme_id: programmeData == 1 ? 1 : 2,
        college_spoc_id: [selectedSpoc.id],

        degree_id:
          all_degree === true ? [] : selectedDegrees.map((value) => value.id),
        stream_id:
          all_streams === true ? [] : selectedStreams.map((value) => value.id),
        year_of_passout: Number.parseInt(yop),
        expected_students: Number.parseInt(expectedStudents),
        incubation_type: Number.parseInt(incubation_type),
        comment: comment,
        appointment_date: appointmentDateTime,
        all_degree: all_degree,
        all_stream: all_streams,
        unit_id: [unitNameData.ID],
      };

      await AxiosInstance.post("/appointment", payload, {
        "content-type": "multipart/form-data",
        Authorization: `${token}`,
      })
        .then((response) => {
          toast.success("Successfully college appointment data is submitted");
          if (response.status == 200) {
            window.sessionStorage.setItem(
              "appointment_date",
              response.data.data.appointment_date
            );
            setTimeout(() => {
              navigate("/create_addressingfaculty");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          setIsSubmitAddressing(false);
        });
    } catch (error) {
      // console.log(error.code);
      toast.error(error.response.data.error);
      setIsSubmitAddressing(false);
    }
  };

  // ===================================create address faculty ends =================================================
  //! API Calls

  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/appointment_programme`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchTermState !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${searchTermState}&limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [searchTermState, countryMiniId]);

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  // deg and stream
  // useEffect(() => {
  //   let fetchData1 = async (e) => {
  //     if (degTerm !== "") {
  //       // college Degree api
  //       let degreeData = await AxiosInstance.get(
  //         `/degree_types?search_keyword=${degTerm}&limit=10`
  //       );
  //       let finaldegreeData = degreeData.data.data;
  //       setDegreeOptions(finaldegreeData);
  //     } else {
  //       // college Degree api
  //       let degreeData = await AxiosInstance.get(`/degree_types?limit=10`);
  //       let finaldegreeData = degreeData.data.data;
  //       setDegreeOptions(finaldegreeData);
  //     }
  //   };
  //   fetchData1();
  // }, [degTerm]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (unitNameData !== null) {
        if (degTerm !== "") {
          // college Degree api
          let degreeData = await AxiosInstance.get(
            `/unit?degree_name=${degTerm}&limit=10&id=${unitNameData.ID}`
          );
          let finaldegreeData = degreeData.data.data;
          function convertToArrayOfObjects(data) {
            let arrayOfObjects = [];

            for (let key in data) {
              let id = parseInt(key);
              let name = data[key].name;
              arrayOfObjects.push({ id, name });
            }
            setDegreeOptions(arrayOfObjects);
            return arrayOfObjects;
          }
          convertToArrayOfObjects(finaldegreeData[0]?.degree?.degree);
          // setDegreeOptions(finaldegreeData);
        } else {
          // college Degree api
          let degreeData = await AxiosInstance.get(
            `/unit?limit=10&id=${unitNameData.ID}`
          );
          let finaldegreeData = degreeData.data.data;
          function convertToArrayOfObjects(data) {
            let arrayOfObjects = [];
            for (let key in data) {
              let id = parseInt(key);
              let name = data[key].name;
              arrayOfObjects.push({ id, name });
            }
            setDegreeOptions(arrayOfObjects);

            return arrayOfObjects;
          }
          convertToArrayOfObjects(finaldegreeData[0]?.degree?.degree);
          // setDegreeOptions(finaldegreeData);
        }
      }
    };
    fetchData1();
  }, [degTerm, unitNameData]);

  const [url, setUrl] = useState("/academic_streams");

  useEffect(() => {
    let fetchData2 = async (e) => {
      let QueryParams = selectedDegrees.map((value, i) => {
        return `degree_id=${value.id}`;
      });
      setStreamsOptions([]);
      const finalUrl =
        QueryParams.length > 0 ? `${url}?${QueryParams.join("&")}` : url;
      if (streamTerm !== null) {
        let streamData = await AxiosInstance.get(
          `${finalUrl}${
            finalUrl === url ? "?" : "&"
          }search_keyword=${streamTerm}`
        );
        let finalstreamData = streamData.data.data;
        if (finalstreamData) {
          setStreamsOptions(finalstreamData);
        }
      } else {
        let streamData = await AxiosInstance.get(`${finalUrl}`);
        let finalstreamData = streamData.data.data;
        setStreamsOptions(finalstreamData);
      }
    };
    fetchData2();
  }, [streamTerm, selectedDegrees]);

  // for particular spoc for its college and univrsity
  useEffect(() => {
    let fetchData = async (e) => {
      // college Spoc api
      const parseLog = (a) => {
        const userData = a ? JSON.parse(a) : { user_id: "" };
        const { user_id } = userData;
        return user_id;
      };
      let currUser_id = parseLog(Cookies.get("logged"));

      if (selectedCollege === null) {
        return null;
      } else {
        let collegeSpocData1 = await AxiosInstance.get(
          // `/college_spoc?college_id=${selectedCollege.id}&university_id=${selectedUniversity.id}&added_by_id=${currUser_id}`
          `/college_spoc?college_id=${selectedCollege.id}&university_id=${selectedUniversity.id}`
        );
        let finalColgSpocData = collegeSpocData1.data.data;
        setColgSpocOptions(finalColgSpocData);
        if (finalColgSpocData === null) {
          toast.warning("Please Create a Spoc");
          setTimeout(() => {
            navigate("/spoc_form_college_appointment", {
              state: {
                selectedUniversity,
                selectedCollege,
                selectedColgState,
                selectedColgDistrict,
              },
            });
          }, 500);
        }
      }
    };
    fetchData();
  }, [selectedCollege, selectedUniversity]);

  //! for college district filter from state
  const [defaultClg, setDefaultClg] = useState([]);
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        let colgDistrictData = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        let finalcolgDistrictData = colgDistrictData.data.data;
        setcolgDistrictOptions(finalcolgDistrictData);
        setDefaultClg(finalcolgDistrictData);
      }
    };
    fetchData();
  }, [selectedColgState]);

  const searchClg = async (e) => {
    try {
      let colgDistrictData = await AxiosInstance.get(
        `/district_mini?state_id=${selectedColgState.id}&limit=10&search_keyword=${e}`
      );
      let finalcolgDistrictData = colgDistrictData.data.data;
      setcolgDistrictOptions(finalcolgDistrictData);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  //! university Filter based on state
  const [defaultUni, setDefaultUni] = useState([]);

  useEffect(() => {
    let fetchUniData = async (e) => {
      let universityData = await AxiosInstance.get(`/university?limit=10`);
      let finalUniversityData = universityData.data.data;
      setUniversityOptions(finalUniversityData);
      setDefaultUni(finalUniversityData);
    };
    fetchUniData();
  }, []);

  const searchuni = async (e) => {
    try {
      let universityData = await AxiosInstance.get(
        `/university?limit=10&search_keyword=${e}`
      );
      let finalUniversityData = universityData.data.data;
      setUniversityOptions(finalUniversityData);
    } catch (error) {
      setUniversityOptions(defaultUni);
    }
  };

  //! for college filter based on district and university
  const [defaultdistClg, setDefaultdistClg] = useState([]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedUniversity !== null && selectedColgDistrict !== null) {
        // college api
        let collegeData = await AxiosInstance.get(
          `/college_mini?limit=10&district_id=${selectedColgDistrict.id}&university_id=${selectedUniversity.id}`
        );
        let finalCollegeData = collegeData.data.data;
        setCollegeOptions(finalCollegeData);
        setDefaultdistClg(finalCollegeData);
      }
    };
    fetchData();
  }, [selectedColgDistrict, selectedUniversity]);

  const searchdistClg = async (e) => {
    try {
      let collegeData = await AxiosInstance.get(
        `/college_mini?limit=10&district_id=${selectedColgDistrict.id}&university_id=${selectedUniversity.id}&search_keyword=${e}`
      );
      let finalCollegeData = collegeData.data.data;
      setCollegeOptions(finalCollegeData);
    } catch (error) {
      setCollegeOptions(defaultdistClg);
    }
  };

  // Reset Data
  let resetData = (e) => {
    e.preventDefault();

    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedUniversity(null);
    setSelectedCollege(null);
    setSelectedSpoc(null);
    setselectedDegrees([]);
    setselectedStreams([]);
    setYop(null);
    setExpectedStudents("");
    setincubation_type("");
    setComment("");
    setAppointment_date("");
    setAppointment_time("");
    setAll_Degree(false);
    setAll_Streams(false);
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "98%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4.3%",
          background: "#efefef",
        }}
      >
        <Box>
          <Paper
            variant="outlined"
            style={{ border: "2px solid #9BA4B5", borderRadius: "10px" }}
          >
            <Box
              sx={{
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "2px solid #9BA4B5",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#081f37",
                  fontSize: {
                    xs: "20px", // Smaller font size on small screens
                    sm: "25px", // Medium font size on small to medium screens
                    md: "30px", // Larger font size on medium to large screens
                  },
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                COLLEGE APPOINTMENT FORM
              </Box>
              <NavLink to="/spoc_creation" style={{ textDecoration: "none" }}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "5px 10px",
                    background: "#081F37",
                    color: "#8ed6ff",
                    boxShadow: "none",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    color: "white",
                    marginRight: "5px",
                  }}
                >
                  Create Spoc &nbsp;&nbsp;
                  <FaUserCheck fontSize={20} />
                </Button>
              </NavLink>
            </Box>
            <form
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "1%",
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                {/* colleg state */}
                <Grid md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchTermState(e.target.value);
                    }}
                  >
                    <Autocomplete
                      required
                      options={colgStateOptions}
                      value={selectedColgState}
                      name="state_name"
                      onChange={(event, newColgState) => {
                        setSelectedColgState(newColgState);
                        setselectedColgDistrict(null);
                        setSelectedCollege(null);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select State"
                          variant="outlined"
                          fullWidth
                          required
                          error={error && !selectedColgState}
                          helperText={
                            error && !selectedColgState
                              ? "Please select State"
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* colleg district */}
                <Grid md={3} xs={12}>
                  <Item>
                    <Autocomplete
                      required
                      options={selectedColgState ? colgDistrictOptions : []}
                      value={selectedColgDistrict}
                      name="state_name"
                      onChange={(event, newColgdis) => {
                        setselectedColgDistrict(newColgdis);
                        setSelectedCollege(null);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchClg(e);
                        } else {
                          setcolgDistrictOptions(defaultClg);
                        }
                        setSearchTermDistrict(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select District"
                          variant="outlined"
                          required
                          fullWidth
                          value={searchTermDistrict}
                          error={error && !selectedColgDistrict}
                          helperText={
                            error && !selectedColgDistrict
                              ? "Please select District"
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>

                <Grid md={3} xs={12}>
                  <Item>
                    <Autocomplete
                      required
                      options={
                        selectedUniversity
                          ? [selectedUniversity]
                          : universityOptions
                      }
                      value={selectedUniversity}
                      name="university_name"
                      onChange={(event, newuniversity) => {
                        setSelectedUniversity(newuniversity);
                        setSelectedCollege(null);
                        setSelectedSpoc(null);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchuni(e);
                        } else {
                          setUniversityOptions(defaultUni);
                        }
                        setSearchTermUniversity(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select University"
                          variant="outlined"
                          required
                          fullWidth
                          error={error && !selectedUniversity}
                          value={searchTermUniversity}
                          helperText={
                            error && !selectedUniversity
                              ? "Please select University"
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <Autocomplete
                      required
                      options={selectedColgDistrict ? collegeOptions : []}
                      value={selectedCollege}
                      name="college_name"
                      onChange={(event, newcollege, x, y, z) => {
                        if (x === "selectOption" && y.option.id === 0) {
                          toast.warn("Select proper option");
                        } else {
                          setSelectedCollege(newcollege);
                        }
                        setSelectedSpoc(null);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchdistClg(e);
                        } else {
                          setCollegeOptions(defaultdistClg);
                        }
                        setSearchCollege(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select College"
                          variant="outlined"
                          required
                          fullWidth
                          value={searchCollege}
                          error={error && !selectedCollege}
                          helperText={
                            error && !selectedCollege
                              ? "Please select College"
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* SPOC */}
                <Grid md={3} xs={12}>
                  <Item>
                    {colgSpocOptions === null ? (
                      <>Loading...</>
                    ) : (
                      <>
                        <Autocomplete
                          required
                          options={colgSpocOptions}
                          value={selectedSpoc}
                          name="collegespoc_name"
                          onChange={(event, newspoc) => {
                            setSelectedSpoc(newspoc);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select SPOC"
                              variant="outlined"
                              fullWidth
                              required
                              error={error && !selectedSpoc}
                              helperText={
                                error && !selectedSpoc
                                  ? "Please select SPOC"
                                  : null
                              }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </Item>
                </Grid>
                {/* YOP */}
                <Grid md={3} xs={12}>
                  <Item>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <Autocomplete
                        required
                        options={yopOptions}
                        value={yop}
                        name="year_of_passout"
                        onChange={(e, newyop) => {
                          setYop(newyop);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select YOP"
                            variant="outlined"
                            fullWidth
                            required
                            error={error && !yop}
                            helperText={
                              error && !yop ? "Please select YOP" : null
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchUnitName(e.target.value);
                    }}
                  >
                    <Autocomplete
                      options={unitNameOption}
                      value={unitNameData}
                      name="unitNameOption"
                      onChange={(event, newData_xy) => {
                        setUnitNameData(newData_xy);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Select Unit Name"
                          variant="outlined"
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Degree */}
                <Grid md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setDegTerm(e.target.value);
                    }}
                  >
                    <Autocomplete
                      multiple
                      // disabled={isDisabledDeg}
                      disabled={isDisabledDeg || !unitNameData}
                      required
                      // size="small"
                      value={selectedDegrees}
                      onChange={(event, newValue) => {
                        setselectedDegrees([...newValue]);
                      }}
                      options={degreeOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Degree"
                          color="warning"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Streams */}
                <Grid md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setStreamTerm(e.target.value);
                    }}
                  >
                    <Autocomplete
                      multiple
                      disabled={isDisabledStrm || !unitNameData}
                      required
                      // size="small"
                      value={selectedStreams}
                      onChange={(event, newValue) => {
                        setselectedStreams([...newValue]);
                      }}
                      options={streamsOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Streams"
                          color="warning"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Checkboxes */}
                <Grid md={3} xs={12}>
                  <Item Component={"aside"} sx={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        position: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {selectedDegrees.length !== 0 ? (
                        <>
                          <label
                            htmlFor="chkDeg"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Degrees
                          </label>
                          <Checkbox
                            disabled
                            id="chkDeg"
                            onChange={handleCheckboxDegree}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                                color: "#efefef",
                                background: "grey",
                              },
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="chkDeg"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Degrees
                          </label>
                          <Checkbox
                            id="chkDeg"
                            required
                            onChange={handleCheckboxDegree}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 26 } }}
                          />
                        </>
                      )}
                      &nbsp; &nbsp;&nbsp;
                      {selectedStreams.length !== 0 ? (
                        <>
                          <label
                            htmlFor="chkStrm"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Streams
                          </label>
                          <Checkbox
                            disabled
                            id="chkStrm"
                            onChange={handleCheckboxStream}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                                color: "#efefef",
                                background: "grey",
                              },
                            }}
                            label="Disabled"
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="chkStrm"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Streams
                          </label>
                          <Checkbox
                            id="chkStrm"
                            required
                            onChange={handleCheckboxStream}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 26 } }}
                            error={error && !all_streams}
                          />

                          {all_streams === "" || selectedStreams === "" ? (
                            <>
                              {error && (
                                <FormHelperText style={{ color: "d32f2f" }}>
                                  Please select Appointment Date
                                </FormHelperText>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </FormControl>
                  </Item>
                </Grid>
                {/* Date and Time */}
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      type="datetime-local"
                      fullWidth
                      id="appointment_date"
                      label="Appointment Date"
                      name="appointment_date"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={handleDate}
                      autoFocus
                      value={appointment_date}
                      error={error && !appointment_date}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                    {appointment_date === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select Appointment Date
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      type="datetime-local"
                      fullWidth
                      id="appointmentTime"
                      label="Appointment Time"
                      name="appointment_time"
                      onChange={handleTime}
                      onFocus={(e) => (e.target.type = "time")}
                      onBlur={(e) => (e.target.type = "text")}
                      autoFocus
                      value={appointment_time}
                      error={error && !appointment_time}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                    {appointment_time === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select Appointment Time
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      fullWidth
                      id="expected_students"
                      label="Students Targeted"
                      name="expected_students"
                      value={expectedStudents}
                      onChange={handleStudents}
                      inputProps={{ maxLength: 5 }}
                      variant="outlined"
                      type="text"
                      error={error && !expectedStudents}
                      helperText={
                        error && !expectedStudents
                          ? "Please Select Expected Students"
                          : null
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-name-label">
                        Programme
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={programmeData}
                        onChange={(event) => {
                          setProgrammeData(event.target.value);
                          setErrorForProgramme(setProgrammeData == "");
                        }}
                        input={<OutlinedInput label="Programme" />}
                        MenuProps={MenuProps}
                        error={errorForProgramme}
                        helperText={
                          errorForProgramme ? "Please select Programme" : ""
                        }
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Default border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                          },
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item Component={"aside"} sx={{ width: "100%" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="incubation_type"
                        value={incubation_type}
                        onChange={handleChangeIncubation}
                        required
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Pre-incubation"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio color="primary" />}
                          label="Post-incubation"
                        />
                      </RadioGroup>

                      {incubation_type === "" ? (
                        <>
                          {error && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              Please select an Incubation Type.
                            </FormHelperText>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  </Item>
                </Grid>

                <Grid md={12} xs={12}>
                  <Item sx={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>

                <Grid
                  xs={12}
                  item
                  md={12}
                  spacing={2}
                  style={{
                    textAlign: "right",
                    padding: "5px",
                  }}
                >
                  <Button
                    onClick={resetData}
                    type="button"
                    variant="contained"
                    style={{
                      width: "10%",
                      marginRight: "3%",
                      backgroundColor: "#DF2E38",
                      color: "#e74646",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      color: "#F5F5F5",
                      backgroundColor: "#1F8A70",
                      marginRight: "17px",
                      fontWeight: "bold",
                    }}
                    onClick={handleCreateFaculty}
                    disabled={isSubmitAddressing}
                  >
                    Create Addressing
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "10%",
                      color: "#F5F5F5",
                      backgroundColor: "#1976d2",
                      fontWeight: "bold",
                    }}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </article>
    </section>
  );
}
