import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Paper,
  Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AxiosInstance from "../apis/AxiosInstance";

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function LeadPassingForm() {
  const Navigate = useNavigate();

  const [name, setName] = useState("");
  const [mobiles, setmobiles] = useState("");
  const [comment, setComment] = useState("");

  const [leadOption, setLeadOption] = useState([]);
  const [leadData, setLeadData] = useState(null);
  const [defaultLead, setDefaultLead] = useState([]);
  const [searchLead, setSearchLead] = useState("");

  const [branchOption, setBranchOption] = useState([]);
  const [branchData, setBranchData] = useState(null);
  const [defaultBranch, setDefaultBranch] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersAndOnePeriodRegex = /^[a-zA-Z\s]*\.?[a-zA-Z\s]*$/;

    if (!lettersAndOnePeriodRegex.test(value)) {
      // If the input contains more than one period or non-letter characters, remove them
      setName(value.replace(/[^a-zA-Z\s.]/g, "").replace(/\.{2,}/g, "."));
    } else {
      setName(value);
    }
  };

  useEffect(() => {
    let fetchdata = async (e) => {
      let data = await AxiosInstance.get(`/university?limit=10`);
      let finalData = data.data.data;
      setLeadOption(finalData);
      setDefaultLead(finalData);
    };
    fetchdata();
  }, []);

  const searchlead = async (e) => {
    try {
      let data = await AxiosInstance.get(
        `/university?search_keyword=${e}&limit=10`
      );
      let finalData = data.data.data;
      setLeadOption(finalData);
    } catch (error) {
      setLeadOption(defaultLead);
    }
  };

  useEffect(() => {
    let fetchdata = async (e) => {
      let data = await AxiosInstance.get(`/university?limit=10`);
      let finalData = data.data.data;
      setBranchOption(finalData);
      setDefaultBranch(finalData);
    };
    fetchdata();
  }, []);

  const searchbranch = async (e) => {
    try {
      let data = await AxiosInstance.get(
        `/university?search_keyword=${e}&limit=10`
      );
      let finalData = data.data.data;
      setBranchOption(finalData);
    } catch (error) {
      setBranchOption(defaultBranch);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      const payload = {};

      await AxiosInstance.post("/college_appointment_rounds", payload, {
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("");
            setTimeout(() => {
              Navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <Paper
        style={{
          width: "40%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4.5%",
          border: "2px solid #9BA4B5",
          borderRadius: "10px",
        }}
      >
        {/* <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Evaluation Criteria
          </Box>
        </Box> */}
        <Box
          sx={{
            width: "100%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#081f37",
              fontSize: {
                xs: "20px", // Smaller font size on small screens
                sm: "25px", // Medium font size on small to medium screens
                md: "30px", // Larger font size on medium to large screens
              },
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            Lead Passing Form
          </Box>
        </Box>

        <Paper variant="outlined" style={{ width: "100%" }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} xs={12} style={{ marginTop: "0.6%" }}>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Name :
              </Grid>

              <Grid item md={9} xs={12}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  value={name}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Contact :
              </Grid>

              <Grid item md={9} xs={12}>
                <TextField
                  label="Mobile Number"
                  type="tel"
                  required
                  fullWidth
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  name="mobiles"
                  value={mobiles}
                  require
                  onChange={(e) => setmobiles(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Lead :
              </Grid>

              <Grid item md={9} xs={12}>
                <Autocomplete
                  options={leadData ? [leadData] : leadOption}
                  value={leadData}
                  name="leadOption"
                  onChange={(event, newData_xy) => {
                    setLeadData(newData_xy);
                  }}
                  onInputChange={(x, e) => {
                    if (e && e.length > 0) {
                      searchlead(e);
                    } else {
                      setLeadOption(defaultLead);
                    }
                    setSearchLead(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Lead"
                      variant="outlined"
                      value={searchLead}
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Branch :
              </Grid>

              <Grid item md={9} xs={12}>
                <Autocomplete
                  options={branchData ? [branchData] : branchOption}
                  value={branchData}
                  name="branchOption"
                  onChange={(event, newData_xy) => {
                    setBranchData(newData_xy);
                  }}
                  onInputChange={(x, e) => {
                    if (e && e.length > 0) {
                      searchbranch(e);
                    } else {
                      setBranchOption(defaultBranch);
                    }
                    setSearchBranch(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Branch"
                      variant="outlined"
                      value={searchLead}
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Comment :
              </Grid>

              <Grid item md={9} xs={12}>
                <TextField
                  name="comment"
                  fullWidth
                  rows={1}
                  multiline
                  id="comment"
                  label="Comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              md={4}
              item
              spacing={1}
              style={{
                textAlign: "center",
                padding: "5px",
                marginTop: "1%",
              }}
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </form>
        </Paper>
      </Paper>
    </section>
  );
}
