// import React, { Fragment, lazy } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import { Suspense } from "react";
// import Loader from "./Loader/Loader";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import NavBar from "./components/Navbar/Navbar";
// import BdRoutes from "./routes/BdRoutes";
// import { PostContextProvider } from "./Bd_Module/bdTree/ContextApi";
// import BdManagerRoutes from "./routes/BdManagerRoutes";
// import AdminRoutes from "./routes/AdminRoutes";
// import Cookies from "js-cookie";
// import DataAnalistRoutes from "./routes/DataAnalistRoutes";
// import SuperAdminRoutes from "./routes/SuperAdminRoutes";
// import TpoRoutes from "./routes/TpoRoutes";
// import SeniorVerifierRoutes from "./routes/SeniorVerifierRoutes";
// import JuniorVerifierRoutes from "./routes/JuniorVerifierRoutes";
// const PublicRoutes = lazy(() => import("./routes/PublicRoutes"));

// const App = () => {
//   const logged = Cookies.get("logged");
//   const parseLog = (a) => {
//     if (a) {
//       const userData = JSON.parse(a);
//       const { role, token } = userData;
//       const currentUser = role[0].id;
//       if (token && currentUser === 1) {
//         return 1;
//       } else if (token && currentUser === 5) {
//         return 5;
//       } else if (token && currentUser === 12) {
//         return 12;
//       } else if (token && currentUser === 13) {
//         return 13;
//       } else if (token && currentUser === 16) {
//         return 16;
//       } else if (token && currentUser === 17) {
//         return 17;
//       }  else if (token && currentUser === 18) {
//         return 18;
//       }  else if (token && currentUser === 15) {
//         return 15;
//       } else {
//         return false;
//       }
//     } else {
//       toast.error("Session Expired");
//       setTimeout(() => {
//         window.location.assign("/login");
//       }, 1000);
//     }
//   };
//   return (
//     <section id="mainPageBlock">
//       <Router>
//         <ToastContainer theme="dark" autoClose="1500" />
//         <NavBar />
//         {logged ? (
//           parseLog(logged) === 1 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <BdRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 5 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <BdManagerRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 15 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <DataAnalistRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ): parseLog(logged) === 13 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                  <TpoRoutes/>
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ): parseLog(logged) === 16 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                <SuperAdminRoutes/>
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 17 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                  <SeniorVerifierRoutes/>
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           )  : parseLog(logged) === 18 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                 <SeniorVerifierRoutes/>
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : (
//             <>
//               {parseLog(logged) === 12 ? (
//                 <Suspense fallback={<Loader />}>
//                   <PostContextProvider>
//                     <AdminRoutes />
//                   </PostContextProvider>
//                 </Suspense>
//               ) : (
//                 <Suspense fallback={<Loader />}>
//                   <PublicRoutes />
//                 </Suspense>
//               )}
//             </>
//           )
//         ) : (
//           <Suspense fallback={<Loader />}>
//             <PublicRoutes />
//           </Suspense>
//         )}
//       </Router>
//     </section>
//   );
// };

// export default App;

// import React, { Fragment, lazy, useEffect } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import { Suspense } from "react";
// import Loader from "./Loader/Loader";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import NavBar from "./components/Navbar/Navbar";
// import BdRoutes from "./routes/BdRoutes";
// import { PostContextProvider } from "./Bd_Module/bdTree/ContextApi";
// import BdManagerRoutes from "./routes/BdManagerRoutes";
// import AdminRoutes from "./routes/AdminRoutes";
// import Cookies from "js-cookie";
// import DataAnalistRoutes from "./routes/DataAnalistRoutes";
// import SuperAdminRoutes from "./routes/SuperAdminRoutes";
// import TpoRoutes from "./routes/TpoRoutes";
// import SeniorVerifierRoutes from "./routes/SeniorVerifierRoutes";
// import JuniorVerifierRoutes from "./routes/JuniorVerifierRoutes";
// import { useState } from "react";
// import { SocketContextProvider } from "./socket/IoContext";
// const PublicRoutes = lazy(() => import("./routes/PublicRoutes"));

// const App = () => {
//   const logged = Cookies.get("logged");
//   const userData = logged ? JSON.parse(logged) : null;

//   const parseLog = (a) => {
//     if (a) {
//       const userData = JSON.parse(a);
//       const { role, token } = userData;
//       // setUserData(userData);
//       const currentUser = role[0].id;
//       if (token && currentUser === 1) {
//         return 1;
//       } else if (token && currentUser === 5) {
//         return 5;
//       } else if (token && currentUser === 12) {
//         return 12;
//       } else if (token && currentUser === 13) {
//         return 13;
//       } else if (token && currentUser === 16) {
//         return 16;
//       } else if (token && currentUser === 17) {
//         return 17;
//       } else if (token && currentUser === 18) {
//         return 18;
//       } else if (token && currentUser === 15) {
//         return 15;
//       } else {
//         return false;
//       }
//     } else {
//       toast.error("Session Expired");
//       setTimeout(() => {
//         window.location.assign("/login");
//       }, 1000);
//     }
//   };

//   // console.log("socket", socket);

//   return (
//     // <SocketContextProvider userData={userData}>
//     <section id="mainPageBlock">
//       <Router>
//         <ToastContainer theme="dark" autoClose="1500" />
//         {logged ? (
//           <SocketContextProvider userData={userData}>
//             <NavBar />
//           </SocketContextProvider>
//         ) : (
//           <NavBar />
//         )}

//         {logged ? (
//           parseLog(logged) === 1 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <BdRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 5 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <SocketContextProvider userData={userData}>
//                   <PostContextProvider>
//                     <BdManagerRoutes />
//                   </PostContextProvider>
//                 </SocketContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 15 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <DataAnalistRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 13 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <TpoRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 16 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <SuperAdminRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 17 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <SeniorVerifierRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : parseLog(logged) === 18 ? (
//             <>
//               <Suspense fallback={<Loader />}>
//                 <PostContextProvider>
//                   <SeniorVerifierRoutes />
//                 </PostContextProvider>
//               </Suspense>
//             </>
//           ) : (
//             <>
//               {parseLog(logged) === 12 ? (
//                 <Suspense fallback={<Loader />}>
//                   <PostContextProvider>
//                     <AdminRoutes />
//                   </PostContextProvider>
//                 </Suspense>
//               ) : (
//                 <Suspense fallback={<Loader />}>
//                   <PublicRoutes />
//                 </Suspense>
//               )}
//             </>
//           )
//         ) : (
//           <Suspense fallback={<Loader />}>
//             <PublicRoutes />
//           </Suspense>
//         )}
//       </Router>
//     </section>
//     // </SocketContextProvider>
//   );
// };

// export default App;

import React, { Fragment, lazy, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react";
import Loader from "./Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "./components/Navbar/Navbar";
import BdRoutes from "./routes/BdRoutes";
import { PostContextProvider } from "./Bd_Module/bdTree/ContextApi";
import BdManagerRoutes from "./routes/BdManagerRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import Cookies from "js-cookie";
import DataAnalistRoutes from "./routes/DataAnalistRoutes";
import SuperAdminRoutes from "./routes/SuperAdminRoutes";
import TpoRoutes from "./routes/TpoRoutes";
import SeniorVerifierRoutes from "./routes/SeniorVerifierRoutes";
import JuniorVerifierRoutes from "./routes/JuniorVerifierRoutes";
import { useState } from "react";
import { SocketContextProvider } from "./socket/IoContext";
import CampusConnectManagerRoutes from "./routes/CampusConnectManagerRoutes";
import CampusConnectBdRoutes from "./routes/CampusConnectBdRoutes";
const PublicRoutes = lazy(() => import("./routes/PublicRoutes"));

const App = () => {
  const logged = Cookies.get("logged");
  const current_role = Cookies.get("current_role");
  const selectedRole =current_role? JSON.parse(current_role):null;
  const userData = logged ? JSON.parse(logged) : null;

  const parseLog = (a) => {
    if (a) {
      const userData = JSON.parse(a);
      const { role, token } = userData;
      // setUserData(userData);
      // const currentUser = role[0].id;
      if (token && selectedRole === 1) {
        return 1;
      } else if (token && selectedRole === 5) {
        return 5;
      } else if (token && selectedRole === 12) {
        return 12;
      } else if (token && selectedRole === 13) {
        return 13;
      } else if (token && selectedRole === 16) {
        return 16;
      } else if (token && selectedRole === 17) {
        return 17;
      } else if (token && selectedRole === 18) {
        return 18;
      } else if (token && selectedRole === 19) {
        return 19;
      } else if (token && selectedRole === 20) {
        return 20;
      } else if (token && selectedRole === 15) {
        return 15;
      } else {
        return false;
      }
    } else {
      toast.error("Session Expired");
      setTimeout(() => {
        window.location.assign("/login");
      }, 1000);
    }
  };

  // console.log("socket", socket);

  return (
    <SocketContextProvider userData={userData}>
      <section id="mainPageBlock">
        <Router>
          <ToastContainer theme="dark" autoClose="1500" />
          <NavBar />
          {logged ? (
            parseLog(logged) === 1 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <BdRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 5 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <BdManagerRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 15 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <DataAnalistRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 13 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <TpoRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 16 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <SuperAdminRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 17 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <SeniorVerifierRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 18 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <SeniorVerifierRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 19 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <CampusConnectManagerRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : parseLog(logged) === 20 ? (
              <>
                <Suspense fallback={<Loader />}>
                  <PostContextProvider>
                    <CampusConnectBdRoutes />
                  </PostContextProvider>
                </Suspense>
              </>
            ) : (
              <>
                {parseLog(logged) === 12 ? (
                  <Suspense fallback={<Loader />}>
                    <PostContextProvider>
                      <AdminRoutes />
                    </PostContextProvider>
                  </Suspense>
                ) : (
                  <Suspense fallback={<Loader />}>
                    <PublicRoutes />
                  </Suspense>
                )}
              </>
            )
          ) : (
            <Suspense fallback={<Loader />}>
              <PublicRoutes />
            </Suspense>
          )}
        </Router>
      </section>
    </SocketContextProvider>
  );
};

export default App;
