import React, { useState, useEffect, useMemo } from "react";
import {
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Chip,
  Checkbox,
} from "@mui/material";
import { FormControl } from "@material-ui/core";
import moment from "moment/moment";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../../apis/AxiosInstance";
import { FaRegTrashAlt, FaPlus } from "react-icons/fa";
import Cookies from "js-cookie";

const EditAddressingForm = () => {
  let { state } = useLocation();

  // const [videoData, setVideoData] = useState(Object.keys(state.addressing_videos));
  const [videoData, setVideoData] = useState(null);
  const [videoOption, setVideoOption] = useState([]);
  const [videoSearch, setVideoSearch] = useState("");

  // state values
  const [addressing_date, setAddressing_date] = useState(
    // moment.utc(state.addressing_datetime).local().format("DD-MM-yyyy")
    moment.utc(state.addressing_datetime).local().format("yyyy-MM-DD")
  );
  const [addressing_time, setAddressing_time] = useState(
    moment.utc(state.addressing_datetime).local().format("HH:mm")
  );

  let ids = Object.keys(state.addressing_faculty);
  let faculty_names = Object.values(state.addressing_faculty).map(
    (value) => value.name
  );

  let ids1 = Object.keys(state.college_executive);
  let Executive_names = Object.values(state.college_executive).map(
    (value) => value.name
  );

  let addressing_Faculty_Options = ids.map((value, id) => {
    return { full_name: faculty_names[id], id: value };
  });

  let collegeExecutiveOptions1 = ids1.map((value, id) => {
    return { full_name: Executive_names[id], id: value };
  });

  const [addressing_faculty, setAddressing_faculty] = useState(
    Object.keys(state.addressing_faculty).length > 0
      ? addressing_Faculty_Options
      : []
  );

  const [college_executive, setCollege_executive] = useState(
    Object.keys(state.college_executive).length > 0
      ? collegeExecutiveOptions1
      : []
  );

  const [selectedColgOptions, setSelectedColgOptions] = useState([state]);

  const [removeFaculty, setRemoveFaculty] = useState([]);
  const [removeExecutive, setRemoveExecutive] = useState([]);
  const [removeAppointment, setRemoveAppointment] = useState([]);
  const [rounds, setRounds] = useState([{ id: 1, label: "Select Round 1" }]);

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(
    state.is_addressing_offline ? 1 : 2
  );

  let multiKey = [];
  if (Object.values(state.addressing_rounds.rounds).length > 0) {
    Object.values(state.addressing_rounds.rounds).forEach((e) => {
      multiKey.push(e.id);
    });
  }

  const [valuesAdd, setValuesAdd] = useState(
    Object.keys(state.addressing_rounds.rounds).length > 0 ? multiKey : [""]
  );
  const navigate = useNavigate();

  // current college Details : State values
  //  const[currentColgID,setCurrentColgID]=useState(null)
  const [collegeData, setCollegeData] = useState([]); //old  code all appointments

  const [searchTerm, setSearchTerm] = useState("");
  const [colgOptions, setColgOptions] = useState([]);
  const [isNameSelected, setIsNameSelected] = useState(false);

  // State values for API calls
  const [appRoundsOptions, setAppRoundsOptions] = useState([]);
  const [addFacultyOptions, setAddFacultyOptions] = useState([]);
  const [collegeExecutiveOptions, setCollegeExecutiveOptions] = useState([]);
  const [is_broadcaster, setIsBroadCaster] = useState(state.is_broadcaster);

  const handleSendBroadCast = (event) => {
    setIsBroadCaster(event.target.checked);
  };

  //!========Date validations =================================
  const disablePreviousDates = (e) => {
    const inputDate = new Date(e.target.value);
    const currentDate = new Date();

    if (inputDate < currentDate) {
      e.target.valueAsDate = currentDate; // Set the input value to the current date
    }
  };

  // ==============================================add rounds==============================================
  const addRound = () => {
    setValuesAdd([...valuesAdd, ""]);
  };

  let [OldRounds, setOldRounds] = useState([]);
  const removeRound = (idToRemove, idx) => {
    let list = [...valuesAdd];
    setOldRounds([...OldRounds, idx]);

    list.splice(idToRemove, 1);
    setValuesAdd(list);
  };
  const [faculty_copy, setFaculty_copy] = useState([...addressing_faculty]);
  useMemo(() => {
    const filtered_faculty = faculty_copy.filter(
      (item_2) => !addressing_faculty.some((item_1) => item_1.id === item_2.id)
    );
    setRemoveFaculty(filtered_faculty.map((id) => id.id));
  }, [addressing_faculty]);

  const [executive_copy, setExecutive_copy] = useState([...college_executive]);
  useMemo(() => {
    const filtered_Executive = executive_copy.filter(
      (item_2) => !college_executive.some((item_1) => item_1.id === item_2.id)
    );
    setRemoveExecutive(filtered_Executive.map((id) => id.id));
  }, [college_executive]);

  const [appointment_copy, setAppointment_copy] = useState([
    ...selectedColgOptions,
  ]);
  useMemo(() => {
    const filtered_Appointment = appointment_copy.filter(
      (item_2) => !selectedColgOptions.some((item_1) => item_1.id === item_2.id)
    );
    setRemoveAppointment(filtered_Appointment.map((id) => id.id));
  }, [selectedColgOptions]);

  // useEffect(() => {
  //   console.log(removeFaculty, "remove_faculty");
  // }, [removeFaculty]);

  const handleValueChange = (id, newValue) => {
    setValuesAdd({ ...valuesAdd, [id]: newValue });
    let newval = valuesAdd.map((item, ind) => {
      return ind === newValue ? id : item;
    });
    setValuesAdd(newval);
  };

  let [url, setUrl] = useState("/addressing");
  //!===========================================FUNCTION TO HANDLE FORM SUBMITION=========================
  let handleSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();

    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    // let addressDateTime = addressing_date + " " + addressing_time + " +0000";
    let addFac = [];

    try {
      const time = new Date(`${addressing_date}T${addressing_time}`);
      // const datePart = moment(time).format("YYYY-MM-DD");
      const timePart = moment(time).utc().format("HH:mm +ssss [UTC]");

      const addressDateTime = `${timePart}`;
      formData.append("id", state.addressing_id);

      addressing_faculty.map((value) => {
        formData.append("add_addressing_faculty", value.id);
      });

      college_executive.map((value) => {
        formData.append("add_college_executive", value.id);
      });

      if (isNameSelected) {
        selectedColgOptions.forEach((value) => {
          formData.append("appointment_ids", value.id);
        });
      } else {
      }

      if (addressing_date !== "") {
        if (
          moment.utc(state.addressing_datetime).local().format("yyyy-MM-DD") !==
          addressing_date
        ) {
          formData.append("addressing_date", addressing_date);
          formData.append("addressing_time", addressDateTime);
        }
      }
      if (addressing_time !== "") {
        if (
          moment.utc(state.addressing_datetime).local().format("hh:mm") !==
          addressing_time
        ) {
          formData.append("addressing_time", addressDateTime);
          formData.append("addressing_date", addressing_date);
        }
      }

      let addressingStatus = state.is_addressing_offline ? 1 : 2;
      if (examTypeList != addressingStatus) {
        formData.append("is_offline", examTypeList == 1 ? true : false);
      }

      if (is_broadcaster !== state.is_broadcaster) {
        formData.append("is_broadcaster", is_broadcaster);
      }

      if (videoData) {
        formData.append("video_id", videoData.id);
      }

      if (removeFaculty.length > 0) {
        removeFaculty.forEach((value, index) => {
          formData.append(`remove_addressing_faculty`, Number.parseInt(value));
        });
      }
      if (removeExecutive.length > 0) {
        removeExecutive.forEach((value, index) => {
          formData.append(`remove_college_executive`, Number.parseInt(value));
        });
      }
      if (removeAppointment.length > 0) {
        removeAppointment.forEach((value, index) => {
          formData.append(`remove_appointment`, Number.parseInt(value));
        });
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      valuesAdd.forEach((e, i) => {
        formData.append(`round${i + 1}_id`, Number.parseInt(e));
      });
      OldRounds.forEach((e, i) => {
        formData.append(`remove_round${i + 1}`, Number.parseInt(e));
      });

      await AxiosInstance.patch(url, formData, config)
        .then((response) => {
          toast.success("Successfully Addressing is submitted");
          setTimeout(() => {
            navigate("/");
          }, 1600);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  //! ================================================API CALLS=============================================================
  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const keys = Object.keys(state.addressing_videos);
      try {
        if (keys.length > 0) {
          const addFacultyData = await AxiosInstance.get(
            `/videos?video_type_id=1&id=${keys.join("&id=")}`
          );
          const finalAddFaculty = addFacultyData.data.data || [];
          setVideoData(finalAddFaculty[0]);
        }
      } catch (error) {
        console.log(error);
        if (keys.length > 0) {
          setVideoData(null);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (videoSearch !== "") {
        let data = await AxiosInstance.get(
          `/videos?video_type_id=1&search_keyword=${videoSearch}&limit=10`
        );
        let finalData = data.data.data;
        setVideoOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/videos?video_type_id=1&limit=10`);
        let finalData = data.data.data;
        setVideoOption(finalData);
      }
    };

    fetchData();
  }, [videoSearch]);

  useEffect(() => {
    let fetchData = async (e) => {
      // College appointment rounds api
      let appointmentRounds = await AxiosInstance.get(
        `/college_appointment_rounds`
      );
      let finalColgRoundsData = appointmentRounds.data.data;
      setAppRoundsOptions(finalColgRoundsData);
    };
    fetchData();
  }, []);
  //! Checkbox

  const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");
  const [searchCollegeExecutive, setSearchCollegeExecutive] = useState("");

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchAddressingFaculty !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setAddFacultyOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setAddFacultyOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchAddressingFaculty]);

  //get Executives

  useEffect(() => {
    let fetchData = async () => {
      // College Executive api
      if (searchCollegeExecutive !== "") {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&search_keyword=${searchCollegeExecutive}&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        if (collegeExecutiveData) {
          setCollegeExecutiveOptions(collegeExecutiveData);
        }
      } else {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        setCollegeExecutiveOptions(collegeExecutiveData);
      }
    };
    fetchData();
  }, [searchCollegeExecutive]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (searchTerm && searchTerm.length > 2) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?search_keyword=${searchTerm}&limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        if (finalAppointments) {
          setColgOptions(finalAppointments);
        }
      } else if (searchTerm === "" || searchTerm === null) {
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );

        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      } else {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      }
    };
    fetchData1();
  }, [searchTerm, selectedColgOptions]);

  useEffect(() => {
    let fetchData = async () => {
      let fecthID =
        selectedColgOptions.length > 0
          ? `id=${selectedColgOptions[selectedColgOptions.length - 1].id}`
          : "";
      let allAppointments = await AxiosInstance.get(
        `/appointment_mini?${fecthID}`
      );
    };
    if (selectedColgOptions.length > 0) {
      fetchData();
    }
  }, [selectedColgOptions]);

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //! ================================================API CALLS=============================================================

  // to handle Date and Time
  let handleAddDate = (e) => {
    setAddressing_date(e.target.value);
  };
  let handleAddTime = (e) => {
    setAddressing_time(e.target.value);
  };

  // all appointments : //old  code all appointments
  const handleAddCollege = () => {
    setCollegeData([...collegeData, null]);
  };

  const handleRemoveCollege = (index) => {
    const newData = [...collegeData];
    newData.splice(index, 1);
    setCollegeData(newData);
  };

  // Reset Data
  let resetData = (e) => {
    e.preventDefault();
    setAddressing_date("");
    setAddressing_time("");
    setAddressing_faculty(null);
    setValuesAdd([""]);
    setSelectedColgOptions([]);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCancelClick = () => {
    window.history.back();
  };
  return (
    <div>
      <section style={{ width: "100%", height: "auto" }}>
        <article
          style={{
            width: "98%",
            height: "auto",
            margin: "0 auto",
            marginTop: "4%",
          }}
        >
          <Box>
            <Paper variant="outlined" style={{ border: "2px solid gray" }}>
              <Typography
                variant="h4"
                sx={{
                  background: "#DDE6ED",
                  color: "#081f37",
                  margin: "0 auto",
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  borderBottom: "2px solid #9BA4B5",
                }}
                // className={Styles.backgrounds}
              >
                EDIT ADDRESSING FORM
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={1} sx={{ padding: 2 }}>
                    {/* forms */}
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={2}
                        item={12}
                        sx={{ marginTop: "5px" }}
                      >
                        {/* Newwwwwww */}
                        {/* <Grid item md={4}>
                          <TextField
                            required
                            type="text"
                            fullWidth
                            id="Appointment Name"
                            label="Appointment Name"
                            name="addressing_date"
                            // onFocus={e => (e.target.type = "date")}
                            // onBlur={e => (e.target.type = "text")}
                            autoFocus
                            value={state.name}
                            disabled
                            onChange={handleAddDate}
                          />
                        </Grid> */}
                        <Grid item md={3.7} xs={12}>
                          <Grid
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                              setIsNameSelected(false);
                            }}
                          >
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              multiple
                              fullWidth
                              required
                              value={selectedColgOptions}
                              onChange={(event, newValue) => {
                                setSelectedColgOptions([...newValue]);
                                setIsNameSelected(true);
                              }}
                              options={colgOptions ? colgOptions : []}
                              getOptionLabel={(option) => option.name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Appointments"
                                  color="warning"
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid item md={1.5} xs={12}>
                          <TextField
                            // required
                            // type="datetime-local"
                            // fullWidth
                            // id="addressingDate"
                            // label=" Addressing Date"
                            // name="addressing_date"
                            // onFocus={(e) => (e.target.type = "date")}
                            // onBlur={(e) => (e.target.type = "text")}
                            // autoFocus
                            // value={addressing_date}
                            // onChange={handleAddDate}
                            // onInput={disablePreviousDates}
                            // inputProps={{
                            //   min: new Date().toISOString().split('T')[0],
                            // }}
                            required
                            type="date"
                            fullWidth
                            id="addressingDate"
                            label="Addressing Date"
                            name="addressing_date"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            onChange={handleAddDate}
                            autoFocus
                            value={addressing_date}
                            inputProps={{
                              min: new Date().toISOString().split("T")[0],
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item md={1.5} xs={12}>
                          <TextField
                            // type="datetime-local"
                            // fullWidth
                            // id="addressingTime"
                            // label="Addressing Time"
                            // name="addressing_date"
                            // onFocus={(e) => (e.target.type = "time")}
                            // onBlur={(e) => (e.target.type = "text")}
                            // autoFocus
                            // value={addressing_time}
                            // onChange={handleAddTime}
                            required
                            type="time"
                            fullWidth
                            id="addressingTime"
                            label="Addressing Time"
                            name="addressingTime"
                            onChange={handleAddTime}
                            value={addressing_time}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={1.7} style={{ marginTop: "-1.2%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Mode Of Addressing
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              displayEmpty
                              className="my-select"
                              inputProps={{
                                "aria-label": "Select an option",
                              }}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", // Default border color
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                                  },
                              }}
                              value={examTypeList}
                              // name="examTypeList"
                              onChange={(e) => {
                                setExamTypeList(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                <span className="my-select-placeholder">
                                  Select Mode
                                </span>
                              </MenuItem>
                              {Object.entries(examTypeListOption).map(
                                ([key, value]) => (
                                  <MenuItem key={key} value={key}>
                                    {value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item md={3.5} xs={12}>
                          <Grid
                            onChange={(e) => {
                              setSearchAddressingFaculty(e.target.value);
                            }}
                          >
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              multiple
                              fullWidth
                              required
                              // size="small"
                              value={addressing_faculty}
                              onChange={(event, newValue) => {
                                setAddressing_faculty([...newValue]);
                              }}
                              options={
                                addFacultyOptions ? addFacultyOptions : []
                              }
                              getOptionLabel={(option) => option.full_name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.full_name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Addressing Faculty"
                                  color="warning"
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                              disabled={examTypeList == 1}
                            />
                          </Grid>
                        </Grid>
                        <Grid item md={3.7} xs={12}>
                          <Grid
                            onChange={(e) => {
                              setSearchCollegeExecutive(e.target.value);
                            }}
                          >
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              multiple
                              fullWidth
                              required
                              // size="small"
                              value={college_executive}
                              onChange={(event, newValue) => {
                                setCollege_executive([...newValue]);
                              }}
                              options={
                                collegeExecutiveOptions
                                  ? collegeExecutiveOptions
                                  : []
                              }
                              getOptionLabel={(option) => option.full_name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.full_name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select College Executive"
                                  color="warning"
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                              disabled={examTypeList == 2}
                            />
                          </Grid>
                        </Grid>

                        <Grid item md={12} style={{ marginTop: "15px" }}>
                          <h3>Interview Rounds</h3>
                        </Grid>

                        <Grid container spacing={1} item>
                          {valuesAdd.map((item, ind) => (
                            <Grid
                              item
                              md={2.75}
                              style={{
                                display: "inline-flex",
                                position: "relative",
                                marginRight: "10px",
                              }}
                            >
                              <FormControl
                                style={{ width: "100%" }}
                                key={ind + 1}
                              >
                                <InputLabel
                                  id={`demo-simple-select-label-${ind}`} // Set a unique ID for each label
                                  sx={{
                                    textAlign: "left",
                                    fontWeight: "600",
                                    color: "#FC7300",
                                  }}
                                >
                                  {`Round ${ind + 1}`}{" "}
                                  {/* Update the label text here */}
                                </InputLabel>

                                <Select
                                  labelId={`demo-simple-select-label-${ind}`} // Reference the label ID
                                  id="demo-simple-select"
                                  value={item}
                                  required
                                  onChange={(event) =>
                                    handleValueChange(event.target.value, ind)
                                  }
                                  displayEmpty
                                  className="my-select"
                                  sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 10)", // Default border color
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                                      },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                                      },
                                  }}
                                  inputProps={{
                                    "aria-label": "Select an option",
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    <span className="my-select-placeholder">
                                      Select Rounds
                                    </span>
                                  </MenuItem>
                                  {appRoundsOptions.map((roundsData, i) => (
                                    <MenuItem
                                      key={roundsData.ID}
                                      value={roundsData.ID}
                                    >
                                      {roundsData.name}
                                    </MenuItem>
                                  ))}
                                </Select>

                                {valuesAdd.length !== 1 && (
                                  <Button
                                    style={{
                                      position: "absolute",
                                      bottom: "-22px",
                                      right: "40%",
                                    }}
                                    onClick={() => removeRound(ind, item)}
                                  >
                                    <FaRegTrashAlt style={{ color: "red" }} />
                                  </Button>
                                )}
                                {valuesAdd.length - 1 === ind && (
                                  <Grid
                                    item
                                    md={1}
                                    style={{
                                      display:
                                        valuesAdd.length > 4
                                          ? "none"
                                          : "inline-flex",
                                      position: "absolute",
                                      right: "-4px",
                                      top: "25%",
                                    }}
                                  >
                                    <Button onClick={addRound}>
                                      <FaPlus />
                                    </Button>
                                  </Grid>
                                )}
                              </FormControl>
                            </Grid>
                          ))}
                        </Grid>

                        {/* trialll */}

                        <Grid
                          item
                          md={12}
                          style={{ marginTop: "20px", display: "flex" }}
                        >
                          <Grid md={2}>
                            <Checkbox
                              name="is_broadcaster"
                              checked={is_broadcaster}
                              onChange={handleSendBroadCast}
                              id="is_broadcaster"
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                            />{" "}
                            <label
                              htmlFor="broadcaster"
                              style={{ fontSize: "16px" }}
                            >
                              Video BroadCasting
                            </label>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            onChange={(e) => {
                              setVideoSearch(e.target.value);
                            }}
                          >
                            <Autocomplete
                              options={videoOption}
                              value={videoData}
                              name="videoOption"
                              onChange={(event, newData) => {
                                setVideoData(newData);
                              }}
                              getOptionLabel={(option) => option.title}
                              disabled={!is_broadcaster}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search Video"
                                  variant="outlined"
                                  required={is_broadcaster}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          xs={12}
                          item
                          spacing={2}
                          sx={{ mt: 5, mb: 2 }}
                          style={{
                            textAlign: "right",
                            padding: "5px",
                          }}
                        >
                          <Button
                            onClick={handleCancelClick}
                            variant="contained"
                            style={{
                              width: "10%",
                              marginRight: "3%",
                              backgroundColor: "#00337c",
                              color: "#e74646",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            Back
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              width: "10%",
                              backgroundColor: "#1976D2",
                              fontWeight: "bold",
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </article>
      </section>
    </div>
  );
};

export default EditAddressingForm;
