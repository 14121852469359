import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import moment from "moment";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";

import Style from "../superAdmin/lists.module.css";
import { toast } from "react-toastify";
import AxiosInstance from "../apis/AxiosInstance";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#081F37",
    color: "#fff",
    padding: "7px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function CallRecordVerificationReport() {
  const [callRecordCount, setCallRecordCount] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCallRecordCount, setTotalCallRecordCount] = useState(0);
  let [searchFilter, setSearchFilter] = useState("");
  let [emailFilter, setEmailFilter] = useState("");
  const [callDate, setCallDate] = useState("");
  const [missedCallDate, setMissedCallDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchDisable, setSearchDisable] = useState(false);
  const [defaultState, setDefaultState] = useState({
    callRecordCount: [],
    totalCallRecordCount: 0,
  });

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/call_record_report2?page=${page}`;
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setCallRecordCount(finalData);
        setTotalCallRecordCount(data.data.total_length);
        setDefaultState({
          callRecordCount: finalData,
          totalCallRecordCount: data.data.total_length,
        });
        if (searchFilter) setPage(1);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/call_record_report2?page=${newPage}`;
    try {
      if (searchFilter) {
        endpoint += `&mobile=${searchFilter}`;
      }
      if (emailFilter) {
        endpoint += `&email=${emailFilter}`;
      }
      if (callDate) {
        endpoint += `&call_date=${callDate}`;
      }
      if (missedCallDate) {
        endpoint += `&missed_call_date=${missedCallDate}`;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setCallRecordCount(finalData);
      setTotalCallRecordCount(data.total_length);
      setPage(newPage);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/call_record_report2?page=${1}`;
    try {
      if (!searchFilter && !emailFilter && !callDate && !missedCallDate && !fromDate && !toDate) {
        toast.warning("Select data to filter.");
        return;
      }
      if (searchFilter) {
        endpoint += `&mobile=${searchFilter}`;
      }
      if (emailFilter) {
        endpoint += `&email=${emailFilter}`;
      }
      if (callDate) {
        endpoint += `&call_date=${callDate}`;
      }
      if (missedCallDate) {
        endpoint += `&missed_call_date=${missedCallDate}`;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      setCallRecordCount(finalData);
      setTotalCallRecordCount(data.total_length);
      setPage(1);
      setSearchDisable(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchDisable(false);
    }
  };

  let handleClearFilterData = async () => {
    setSearchFilter("");
    setEmailFilter("");
    setMissedCallDate("");
    setCallDate("");
    setFromDate("");
    setToDate("");
    let endpoint = `/call_record_report2?page=${page}`;
    try {
      let data = await AxiosInstance.get(endpoint);
      let finalData = data.data.data;
      setCallRecordCount(finalData);
      setTotalCallRecordCount(data.data.total_length);
      setDefaultState({
        callRecordCount: finalData,
        totalCallRecordCount: data.data.total_length,
      });
      if (searchFilter) setPage(1);
      setSearchDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const totalPages = Math.ceil(totalCallRecordCount / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "4.5%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>
        Call Record Verification Report
        <span
          style={{
            color: "#06113C",
            fontSize: "20px",
            marginLeft: "25%",
            fontWeight: "bold",
          }}
        >
          Total Count: {totalCallRecordCount}
        </span>
      </h1>
      <Paper
        style={{
          width: "98%",
          margin: "0 auto",
          border: "1px solid #111",
          marginTop: "-1%",
        }}
      >
        <aside className={Style.actionBlock} style={{ marginTop: "-1%" }}>
          <div
            className={Style.inputSearchContainer}
            style={{ padding: "10px" }}
          >
            <Grid container gap={1}>
              <Grid item md={2}>
                <TextField
                  placeholder=" Search By Number"
                  label="Search By Number"
                  size="small"
                  value={searchFilter}
                  name="searchFilter"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                  disabled={searchDisable}
                  // style={{ width: "250px", height: "35px" }}
                />
              </Grid>
              <Grid item md={1.7} >
                <TextField
                  size="small"
                  fullWidth
                  label="Search Email"
                  name="emails"
                  type="email"
                  autoComplete="user-email"
                  value={emailFilter}
                  onChange={(e) => setEmailFilter(e.target.value)}
                  disabled={searchDisable}
                />
              </Grid>
              <Grid item md={1.5}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  id="Call Date"
                  size="small"
                  label="Call Date "
                  name="Call Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  value={callDate}
                  onChange={(e) => {
                    setCallDate(e.target.value);
                  }}
                  disabled={searchDisable}
                />
              </Grid>
              {/* <Grid item md={1.5}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  id="missedCallDate"
                  size="small"
                  label="Missed Call Date "
                  name="missedCallDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  value={missedCallDate}
                  onChange={(e) => {
                    setMissedCallDate(e.target.value);
                  }}
                  disabled={searchDisable}
                />
              </Grid> */}
              <Grid item md={1.5}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  id="fromDate"
                  size="small"
                  label="From Date "
                  name="fromDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                  disabled={searchDisable}
                />
              </Grid>
              <Grid item md={1.5}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  id="toDate"
                  size="small"
                  label="To Date "
                  name="toDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  disabled={searchDisable}
                />
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button onClick={handleSearch} variant="contained" size="small">
                  Search
                </Button>
              </Grid>
              <Grid
                item
                // md={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleClearFilterData}
                  variant="contained"
                  color="warning"
                  size="small"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>

          {/* <p
            style={{
              fontSize: "18px",
              width: "200px",

              fontWeight: "bold",
            }}
          >
            Total Count: {totalEscalation}
          </p> */}
        </aside>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "100px",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "250px",
                  }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "300px",
                  }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "170px",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Incoming Calls
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Outgoing Calls
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "250px",
                  }}
                >
                  Total Rated
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "250px",
                  }}
                >
                  Rated Duration
                </StyledTableCell>        
              </TableRow>
            </TableHead>
            <TableBody>
              {callRecordCount && callRecordCount.length > 0 ? (
                callRecordCount.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {val.full_name === null || val.full_name === ""
                          ? "NA"
                          : val.full_name}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.emails === null || val.emails === ""
                        ? "NA"
                        : val.emails}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.mobiles === null || val.mobiles === ""
                        ? "NA"
                        : val.mobiles}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.incoming_calls === null || val.incoming_calls === ""
                        ? 0
                        : val.incoming_calls}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.outgoing_calls === null || val.outgoing_calls === ""
                        ? 0
                        : val.outgoing_calls}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.total_rated === null ||
                      val.total_rated === ""
                        ? 0
                        : val.total_rated}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.rated_duration === null || val.rated_duration === ""
                        ? 0
                        : val.rated_duration}
                    </StyledTableCell>                   
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "1%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1B4242", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
