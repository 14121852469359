import React, { useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Profile from "../components/profile/Profile";
import CampusSpocCreation from "../campusconnect/campusspoccreation/CampusSpocCreation";
import CampusCollegeAppointment from "../campusconnect/campuscollegeappointment/CampusCollegeAppointment";
import CampusSpocFromCollegeAppointment from "../campusconnect/campusspoccreation/CampusSpocFromCollegeAppointment";
import CollegeAppointmentFromCampusSpoc from "../campusconnect/campuscollegeappointment/CollegeAppointmentFromCampusSpoc";
import CampusConnectAppointmentList from "../campusconnect/campuscollegeappointment/CampusConnectAppointmentList";
import EditCCAppointment from "../campusconnect/campuscollegeappointment/EditCCAppointment";
import CcCollegeList from "../campusconnect/campuscollegeappointment/CcCollegeList";
import CCspocCreationFromCollegeList from "../campusconnect/campusspoccreation/CCspocCreationFromCollegeList";

const CampusConnectBdRoutes = () => {
  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { data } = await AxiosInstance.get("/user");
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status == 401) {
          let handleLogout = () => {
            Cookies.remove("logged");
            toast.warn("Access blocked login again");
            setTimeout(() => {
              window.location.assign("/login");
            }, 500);
          };
          handleLogout();
        }
      }
    };
    verifyUser();
  }, []);
  let routes = useRoutes([
    { path: "/", element: <CampusConnectAppointmentList /> },
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/" /> },
    { path: "/campus_spoc_creation", element: <CampusSpocCreation /> },
    {
      path: "/campus_create_collegeappointment",
      element: <CampusCollegeAppointment />,
    },
    {
      path: "/college_appointment_from_campus_spoc",
      element: <CollegeAppointmentFromCampusSpoc />,
    },

    {
      path: "/campus_spoc_form_college_appointment",
      element: <CampusSpocFromCollegeAppointment />,
    },
    { path: "/cc_appointment_list", element: <CampusConnectAppointmentList /> },
    { path: "/edit_cc_appointment", element: <EditCCAppointment /> },
    { path: "/cc_college_list_data", element: <CcCollegeList /> },
    { path: "/cc_spoc_creation_from_collegelist", element: <CCspocCreationFromCollegeList /> },
  ]);
  return routes;
};

export default CampusConnectBdRoutes;
