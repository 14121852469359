import React, { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Styles from "../../Navbar/navLinks/_CampusManagerMenu.module.css";
import { toast } from "react-toastify";
import {
  MdAssignmentAdd,
  MdPersonAddAlt1,
  MdReduceCapacity,
} from "react-icons/md";
import AxiosInstance from "./../../../apis/AxiosInstance";
import {
  Button,
  Tooltip,
  ListItem,
  Avatar,
  Stack,
  Modal,
  Box,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";
import { useState } from "react";
import { socketContext } from "../../../socket/IoContext";
import CCDrawerManager from "../../../Bd_Module/pages/CCDrawerManager";

const CampusManagerMenu = () => {
  const { notreadNotification } = useContext(socketContext);

  const logged = Cookies.get("logged");
  const role = Cookies.get("current_role");
  const userData = logged ? JSON.parse(logged) : null;

  let handleLogout = () => {
    Cookies.remove("logged");
    Cookies.remove("current_role");
    toast.success("Logged out successfully");
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
  };

  const [openswitch, setOpenSwitch] = useState(false);

  const handleOpenSwitch = () => setOpenSwitch(true);
  const handleCloseSwitch = () => setOpenSwitch(false);

  return (
    <div>
      <section id={Styles.NavMenuContaiiner}>
        {userData && userData.role.length > 1 && (
          <Button
            size="small"
            sx={{
              border: "#fff 1px solid",
              backgroundColor: "#088395",
              color: "#fff",
              ":hover": {
                color: "#fff",
                border: "#FCA311 1px solid",
                fontWeight: "bold",
                background: "#009FBD",
              },
            }}
            onClick={handleOpenSwitch}
          >
            Switch
          </Button>
        )}
        <NavLink
          to="/campus_spoc_creation"
          style={{ textDecoration: "none", color: "#FCA311" }}
        >
          <ListItem
            sx={{
              color: "white",

              fontSize: "15px",
              "&:hover": {
                backgroundColor: " rgba(255,255,255,0.1)",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color: "#FCA311",
              },
            }}
          >
            <MdPersonAddAlt1 color="#FCA311" fontSize={24} />
            &nbsp;&nbsp;Create Spoc
          </ListItem>
        </NavLink>
        <NavLink
          to="/campus_create_collegeappointment"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "14px",

              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color: "#FCA311",
              },
            }}
          >
            <MdAssignmentAdd color="#FCA311" fontSize={24} />
            &nbsp;&nbsp;New Appointment
          </ListItem>
        </NavLink>
        <NavLink
          to="/profile"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip arrow placement="bottom" title="View Profile">
              <Avatar sx={{ bgcolor: "#fff", color: "#FCA311" }}>
                <PersonIcon size="40" />
              </Avatar>
            </Tooltip>
          </Stack>
        </NavLink>

        <CCDrawerManager title="Menu" />

        <NavLink
          onClick={handleLogout}
          style={{
            textDecoration: "none",
            color: "#FD8D14",
            background: "none",
            fontSize: "18px",
          }}
        >
          Logout
        </NavLink>
      </section>
      <Modal
        open={openswitch}
        onClose={handleCloseSwitch}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseSwitch}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon color="error" />
          </IconButton>
          <h3 id="modal-title" style={{ color: "#003285" }}>
            Do you want to Switch the role?
          </h3>
          <div
            id="modal-description"
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              marginTop: "10%",
            }}
          >
            {userData.role
              .filter((val) => val.id != role)
              .map((r, index) => (
                <Button
                  size="small"
                  key={index}
                  onClick={() => {
                    Cookies.set("current_role", r.id);
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);
                  }}
                  style={{
                    background: "#102C57",
                    color: "#fff",
                    // border: "1px solid #F4A311",
                    gap: "10px",
                    marginLeft:"20%",
                    cursor: "pointer"
                  }}
                >
                  {r.name}
                </Button>
              ))}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CampusManagerMenu;
