import React, { useEffect,useContext, useState} from "react";
import { NavLink } from "react-router-dom";
import Styles from "../../Navbar/navLinks/_SeniorVerifierMenu.module.css";
import { toast } from "react-toastify";
import { Tooltip, Avatar, Stack,  Modal,
  Box,
  Typography,
  ButtonGroup,
  IconButton,
  Button, } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Cookies from "js-cookie";
import CancelIcon from "@mui/icons-material/Cancel";
import DrawerSeniorVerifier from "../../../Bd_Module/pages/DrawerSeniorVerifier";
import { socketContext } from "../../../socket/IoContext";
import NotificationDrawer from "../../../socket/NotificationDrawer";

const SeniorVerifierMenu = () => {
  const [openModal, setOpenModal] = useState(false);
  const { notreadNotification } = useContext(socketContext);
  let handleLogout = () => {
    Cookies.remove("logged");
    Cookies.remove("current_role");
    toast.success("Logged out successfully");
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
  };

  const handleNoClick = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <section id={Styles.NavMenuContaiiner}>
        <NavLink
          to="/profile"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip arrow placement="bottom" title="View Profile">
              <Avatar sx={{ bgcolor: "#fff", color: "#111" }}>
                <PersonIcon size="40" />
              </Avatar>
            </Tooltip>
          </Stack>
        </NavLink>
        <NotificationDrawer title="Menu" />
        <DrawerSeniorVerifier title="Menu" />
          <span
          onClick={() => setOpenModal(true)}
          style={{
            textDecoration: "none",
            color: "#fff",
            background: "none",
            fontSize: "18px",
            cursor: "pointer",
          }}
        >
          Logout
        </span>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 370,
              bgcolor: "background.paper",
              border: "2px solid #182747",
              boxShadow: 24,
              p: 2,
              borderRadius: "5px",
            }}
          >
            <IconButton
              onClick={() => setOpenModal(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "red",
              }}
            >
              <CancelIcon />
            </IconButton>
            <Typography
              sx={{
                mt: 2,
                color: "#06113C",
                fontSize: "20px",
                fontWeight: "900",
              }}
            >
              Do you want to Logout ?
            </Typography>
            <ButtonGroup sx={{ mt: 5 }} fullWidth>
              <Button
                onClick={handleLogout}
                style={{
                  background: "#06113C",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                  padding: "10px 10px",
                }}
              >
                Yes
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                onClick={handleNoClick}
                style={{
                  background: "#C7253E",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </Box>
        </Modal>
      </section>
    </div>
  );
};

export default SeniorVerifierMenu;
