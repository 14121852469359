import React, { useEffect,useState } from "react";
import { NavLink,Link } from "react-router-dom";
import Styles from "../../Navbar/navLinks/_DataAnalistMenu.module.css";
import { toast } from "react-toastify";
import { Avatar, Stack, Tooltip,Button,MenuItem} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Cookies from "js-cookie";
import DrawerDataAnalist from "../../../Bd_Module/pages/DrawerDataAnalist";
import AxiosInstance from "../../../apis/AxiosInstance";
import Menu from "@mui/material/Menu";

const DataAnalistMenu = () => {
  let [treeMenu, setTreemenu] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    fetchData();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let menuRoutes = [
    "/tree_appoint_toaddressing",
    "/tree_address_torounds",
    "/tree_roundsto_shortlist",
    "/tree_onboardingdetails",
    "/tree_branchjourney",
    "/count_tree",
    "/main_tree",
  ];
  let create_Menu_withRoutes =
    treeMenu.length > 0
      ? treeMenu.map((menu, index) => {
          return {
            menu: menu,
            route: menuRoutes[index],
          };
        })
      : [];
  let handleLogout = () => {
    Cookies.remove("logged");
    Cookies.remove("current_role");
    toast.success("Logged out successfully");
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
  };

  
  const handleNoClick = () => {
    setOpenModal(false);
  };

  let fetchData = async (_) => {
    let apiCall = await AxiosInstance.get("data_split_details");
    let finalApi = apiCall.data.data;
    setTreemenu(finalApi);
  };

  const handleMenuChange = (event, index) => {
    window.location.assign(menuRoutes[index]);
  };

  return (
    <div>
      <section id={Styles.NavMenuContaiiner}>
      <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            sx={{
              border: "#39A7FF 1px solid",
              color: "#fff",
              ":hover": {
                color: "#39A7FF",
                border: "#fff 1px solid",
                fontWeight: "bold",
              },
            }}
          >
            Tree Menu{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path
                  fill="#39A7FF"
                  d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
                />
              </g>
            </svg>
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {create_Menu_withRoutes.map((item, index) => {
              return (
                <MenuItem
                  onClick={(event) => handleMenuChange(event, index)}
                  key={index}
                >
                  <Link
                    to={`${item.route}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {" "}
                    {item.menu}
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <NavLink
          to="/profile"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip arrow placement="bottom" title="View Profile">
              <Avatar sx={{ bgcolor: "#fff", color: "#39A7FF" }}>
                <PersonIcon size="40" />
              </Avatar>
            </Tooltip>
          </Stack>
        </NavLink>
        <DrawerDataAnalist title="Menu" />
        <NavLink
          onClick={handleLogout}
          style={{
            textDecoration: "none",
            color: "#39A7FF",
            background: "none",
            fontSize: "18px",
          }}
        >
          Logout
        </NavLink>
      </section>
    </div>
  );
};

export default DataAnalistMenu;
